var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// ../../node_modules/lodash/_listCacheClear.js
var require_listCacheClear = __commonJS({
  "../../node_modules/lodash/_listCacheClear.js"(exports, module) {
    function listCacheClear() {
      this.__data__ = [];
      this.size = 0;
    }
    module.exports = listCacheClear;
  }
});

// ../../node_modules/lodash/eq.js
var require_eq = __commonJS({
  "../../node_modules/lodash/eq.js"(exports, module) {
    function eq(value, other) {
      return value === other || value !== value && other !== other;
    }
    module.exports = eq;
  }
});

// ../../node_modules/lodash/_assocIndexOf.js
var require_assocIndexOf = __commonJS({
  "../../node_modules/lodash/_assocIndexOf.js"(exports, module) {
    var eq = require_eq();
    function assocIndexOf(array, key) {
      var length = array.length;
      while (length--) {
        if (eq(array[length][0], key)) {
          return length;
        }
      }
      return -1;
    }
    module.exports = assocIndexOf;
  }
});

// ../../node_modules/lodash/_listCacheDelete.js
var require_listCacheDelete = __commonJS({
  "../../node_modules/lodash/_listCacheDelete.js"(exports, module) {
    var assocIndexOf = require_assocIndexOf();
    var arrayProto = Array.prototype;
    var splice = arrayProto.splice;
    function listCacheDelete(key) {
      var data = this.__data__, index = assocIndexOf(data, key);
      if (index < 0) {
        return false;
      }
      var lastIndex = data.length - 1;
      if (index == lastIndex) {
        data.pop();
      } else {
        splice.call(data, index, 1);
      }
      --this.size;
      return true;
    }
    module.exports = listCacheDelete;
  }
});

// ../../node_modules/lodash/_listCacheGet.js
var require_listCacheGet = __commonJS({
  "../../node_modules/lodash/_listCacheGet.js"(exports, module) {
    var assocIndexOf = require_assocIndexOf();
    function listCacheGet(key) {
      var data = this.__data__, index = assocIndexOf(data, key);
      return index < 0 ? void 0 : data[index][1];
    }
    module.exports = listCacheGet;
  }
});

// ../../node_modules/lodash/_listCacheHas.js
var require_listCacheHas = __commonJS({
  "../../node_modules/lodash/_listCacheHas.js"(exports, module) {
    var assocIndexOf = require_assocIndexOf();
    function listCacheHas(key) {
      return assocIndexOf(this.__data__, key) > -1;
    }
    module.exports = listCacheHas;
  }
});

// ../../node_modules/lodash/_listCacheSet.js
var require_listCacheSet = __commonJS({
  "../../node_modules/lodash/_listCacheSet.js"(exports, module) {
    var assocIndexOf = require_assocIndexOf();
    function listCacheSet(key, value) {
      var data = this.__data__, index = assocIndexOf(data, key);
      if (index < 0) {
        ++this.size;
        data.push([key, value]);
      } else {
        data[index][1] = value;
      }
      return this;
    }
    module.exports = listCacheSet;
  }
});

// ../../node_modules/lodash/_ListCache.js
var require_ListCache = __commonJS({
  "../../node_modules/lodash/_ListCache.js"(exports, module) {
    var listCacheClear = require_listCacheClear();
    var listCacheDelete = require_listCacheDelete();
    var listCacheGet = require_listCacheGet();
    var listCacheHas = require_listCacheHas();
    var listCacheSet = require_listCacheSet();
    function ListCache(entries) {
      var index = -1, length = entries == null ? 0 : entries.length;
      this.clear();
      while (++index < length) {
        var entry = entries[index];
        this.set(entry[0], entry[1]);
      }
    }
    ListCache.prototype.clear = listCacheClear;
    ListCache.prototype["delete"] = listCacheDelete;
    ListCache.prototype.get = listCacheGet;
    ListCache.prototype.has = listCacheHas;
    ListCache.prototype.set = listCacheSet;
    module.exports = ListCache;
  }
});

// ../../node_modules/lodash/_stackClear.js
var require_stackClear = __commonJS({
  "../../node_modules/lodash/_stackClear.js"(exports, module) {
    var ListCache = require_ListCache();
    function stackClear() {
      this.__data__ = new ListCache();
      this.size = 0;
    }
    module.exports = stackClear;
  }
});

// ../../node_modules/lodash/_stackDelete.js
var require_stackDelete = __commonJS({
  "../../node_modules/lodash/_stackDelete.js"(exports, module) {
    function stackDelete(key) {
      var data = this.__data__, result = data["delete"](key);
      this.size = data.size;
      return result;
    }
    module.exports = stackDelete;
  }
});

// ../../node_modules/lodash/_stackGet.js
var require_stackGet = __commonJS({
  "../../node_modules/lodash/_stackGet.js"(exports, module) {
    function stackGet(key) {
      return this.__data__.get(key);
    }
    module.exports = stackGet;
  }
});

// ../../node_modules/lodash/_stackHas.js
var require_stackHas = __commonJS({
  "../../node_modules/lodash/_stackHas.js"(exports, module) {
    function stackHas(key) {
      return this.__data__.has(key);
    }
    module.exports = stackHas;
  }
});

// ../../node_modules/lodash/_freeGlobal.js
var require_freeGlobal = __commonJS({
  "../../node_modules/lodash/_freeGlobal.js"(exports, module) {
    var freeGlobal = typeof global == "object" && global && global.Object === Object && global;
    module.exports = freeGlobal;
  }
});

// ../../node_modules/lodash/_root.js
var require_root = __commonJS({
  "../../node_modules/lodash/_root.js"(exports, module) {
    var freeGlobal = require_freeGlobal();
    var freeSelf = typeof self == "object" && self && self.Object === Object && self;
    var root = freeGlobal || freeSelf || Function("return this")();
    module.exports = root;
  }
});

// ../../node_modules/lodash/_Symbol.js
var require_Symbol = __commonJS({
  "../../node_modules/lodash/_Symbol.js"(exports, module) {
    var root = require_root();
    var Symbol2 = root.Symbol;
    module.exports = Symbol2;
  }
});

// ../../node_modules/lodash/_getRawTag.js
var require_getRawTag = __commonJS({
  "../../node_modules/lodash/_getRawTag.js"(exports, module) {
    var Symbol2 = require_Symbol();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    var nativeObjectToString = objectProto.toString;
    var symToStringTag = Symbol2 ? Symbol2.toStringTag : void 0;
    function getRawTag(value) {
      var isOwn = hasOwnProperty.call(value, symToStringTag), tag = value[symToStringTag];
      try {
        value[symToStringTag] = void 0;
        var unmasked = true;
      } catch (e) {
      }
      var result = nativeObjectToString.call(value);
      if (unmasked) {
        if (isOwn) {
          value[symToStringTag] = tag;
        } else {
          delete value[symToStringTag];
        }
      }
      return result;
    }
    module.exports = getRawTag;
  }
});

// ../../node_modules/lodash/_objectToString.js
var require_objectToString = __commonJS({
  "../../node_modules/lodash/_objectToString.js"(exports, module) {
    var objectProto = Object.prototype;
    var nativeObjectToString = objectProto.toString;
    function objectToString(value) {
      return nativeObjectToString.call(value);
    }
    module.exports = objectToString;
  }
});

// ../../node_modules/lodash/_baseGetTag.js
var require_baseGetTag = __commonJS({
  "../../node_modules/lodash/_baseGetTag.js"(exports, module) {
    var Symbol2 = require_Symbol();
    var getRawTag = require_getRawTag();
    var objectToString = require_objectToString();
    var nullTag = "[object Null]";
    var undefinedTag = "[object Undefined]";
    var symToStringTag = Symbol2 ? Symbol2.toStringTag : void 0;
    function baseGetTag(value) {
      if (value == null) {
        return value === void 0 ? undefinedTag : nullTag;
      }
      return symToStringTag && symToStringTag in Object(value) ? getRawTag(value) : objectToString(value);
    }
    module.exports = baseGetTag;
  }
});

// ../../node_modules/lodash/isObject.js
var require_isObject = __commonJS({
  "../../node_modules/lodash/isObject.js"(exports, module) {
    function isObject(value) {
      var type = typeof value;
      return value != null && (type == "object" || type == "function");
    }
    module.exports = isObject;
  }
});

// ../../node_modules/lodash/isFunction.js
var require_isFunction = __commonJS({
  "../../node_modules/lodash/isFunction.js"(exports, module) {
    var baseGetTag = require_baseGetTag();
    var isObject = require_isObject();
    var asyncTag = "[object AsyncFunction]";
    var funcTag = "[object Function]";
    var genTag = "[object GeneratorFunction]";
    var proxyTag = "[object Proxy]";
    function isFunction(value) {
      if (!isObject(value)) {
        return false;
      }
      var tag = baseGetTag(value);
      return tag == funcTag || tag == genTag || tag == asyncTag || tag == proxyTag;
    }
    module.exports = isFunction;
  }
});

// ../../node_modules/lodash/_coreJsData.js
var require_coreJsData = __commonJS({
  "../../node_modules/lodash/_coreJsData.js"(exports, module) {
    var root = require_root();
    var coreJsData = root["__core-js_shared__"];
    module.exports = coreJsData;
  }
});

// ../../node_modules/lodash/_isMasked.js
var require_isMasked = __commonJS({
  "../../node_modules/lodash/_isMasked.js"(exports, module) {
    var coreJsData = require_coreJsData();
    var maskSrcKey = function() {
      var uid = /[^.]+$/.exec(coreJsData && coreJsData.keys && coreJsData.keys.IE_PROTO || "");
      return uid ? "Symbol(src)_1." + uid : "";
    }();
    function isMasked(func) {
      return !!maskSrcKey && maskSrcKey in func;
    }
    module.exports = isMasked;
  }
});

// ../../node_modules/lodash/_toSource.js
var require_toSource = __commonJS({
  "../../node_modules/lodash/_toSource.js"(exports, module) {
    var funcProto = Function.prototype;
    var funcToString = funcProto.toString;
    function toSource(func) {
      if (func != null) {
        try {
          return funcToString.call(func);
        } catch (e) {
        }
        try {
          return func + "";
        } catch (e) {
        }
      }
      return "";
    }
    module.exports = toSource;
  }
});

// ../../node_modules/lodash/_baseIsNative.js
var require_baseIsNative = __commonJS({
  "../../node_modules/lodash/_baseIsNative.js"(exports, module) {
    var isFunction = require_isFunction();
    var isMasked = require_isMasked();
    var isObject = require_isObject();
    var toSource = require_toSource();
    var reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
    var reIsHostCtor = /^\[object .+?Constructor\]$/;
    var funcProto = Function.prototype;
    var objectProto = Object.prototype;
    var funcToString = funcProto.toString;
    var hasOwnProperty = objectProto.hasOwnProperty;
    var reIsNative = RegExp(
      "^" + funcToString.call(hasOwnProperty).replace(reRegExpChar, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
    );
    function baseIsNative(value) {
      if (!isObject(value) || isMasked(value)) {
        return false;
      }
      var pattern = isFunction(value) ? reIsNative : reIsHostCtor;
      return pattern.test(toSource(value));
    }
    module.exports = baseIsNative;
  }
});

// ../../node_modules/lodash/_getValue.js
var require_getValue = __commonJS({
  "../../node_modules/lodash/_getValue.js"(exports, module) {
    function getValue(object, key) {
      return object == null ? void 0 : object[key];
    }
    module.exports = getValue;
  }
});

// ../../node_modules/lodash/_getNative.js
var require_getNative = __commonJS({
  "../../node_modules/lodash/_getNative.js"(exports, module) {
    var baseIsNative = require_baseIsNative();
    var getValue = require_getValue();
    function getNative(object, key) {
      var value = getValue(object, key);
      return baseIsNative(value) ? value : void 0;
    }
    module.exports = getNative;
  }
});

// ../../node_modules/lodash/_Map.js
var require_Map = __commonJS({
  "../../node_modules/lodash/_Map.js"(exports, module) {
    var getNative = require_getNative();
    var root = require_root();
    var Map2 = getNative(root, "Map");
    module.exports = Map2;
  }
});

// ../../node_modules/lodash/_nativeCreate.js
var require_nativeCreate = __commonJS({
  "../../node_modules/lodash/_nativeCreate.js"(exports, module) {
    var getNative = require_getNative();
    var nativeCreate = getNative(Object, "create");
    module.exports = nativeCreate;
  }
});

// ../../node_modules/lodash/_hashClear.js
var require_hashClear = __commonJS({
  "../../node_modules/lodash/_hashClear.js"(exports, module) {
    var nativeCreate = require_nativeCreate();
    function hashClear() {
      this.__data__ = nativeCreate ? nativeCreate(null) : {};
      this.size = 0;
    }
    module.exports = hashClear;
  }
});

// ../../node_modules/lodash/_hashDelete.js
var require_hashDelete = __commonJS({
  "../../node_modules/lodash/_hashDelete.js"(exports, module) {
    function hashDelete(key) {
      var result = this.has(key) && delete this.__data__[key];
      this.size -= result ? 1 : 0;
      return result;
    }
    module.exports = hashDelete;
  }
});

// ../../node_modules/lodash/_hashGet.js
var require_hashGet = __commonJS({
  "../../node_modules/lodash/_hashGet.js"(exports, module) {
    var nativeCreate = require_nativeCreate();
    var HASH_UNDEFINED = "__lodash_hash_undefined__";
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function hashGet(key) {
      var data = this.__data__;
      if (nativeCreate) {
        var result = data[key];
        return result === HASH_UNDEFINED ? void 0 : result;
      }
      return hasOwnProperty.call(data, key) ? data[key] : void 0;
    }
    module.exports = hashGet;
  }
});

// ../../node_modules/lodash/_hashHas.js
var require_hashHas = __commonJS({
  "../../node_modules/lodash/_hashHas.js"(exports, module) {
    var nativeCreate = require_nativeCreate();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function hashHas(key) {
      var data = this.__data__;
      return nativeCreate ? data[key] !== void 0 : hasOwnProperty.call(data, key);
    }
    module.exports = hashHas;
  }
});

// ../../node_modules/lodash/_hashSet.js
var require_hashSet = __commonJS({
  "../../node_modules/lodash/_hashSet.js"(exports, module) {
    var nativeCreate = require_nativeCreate();
    var HASH_UNDEFINED = "__lodash_hash_undefined__";
    function hashSet(key, value) {
      var data = this.__data__;
      this.size += this.has(key) ? 0 : 1;
      data[key] = nativeCreate && value === void 0 ? HASH_UNDEFINED : value;
      return this;
    }
    module.exports = hashSet;
  }
});

// ../../node_modules/lodash/_Hash.js
var require_Hash = __commonJS({
  "../../node_modules/lodash/_Hash.js"(exports, module) {
    var hashClear = require_hashClear();
    var hashDelete = require_hashDelete();
    var hashGet = require_hashGet();
    var hashHas = require_hashHas();
    var hashSet = require_hashSet();
    function Hash(entries) {
      var index = -1, length = entries == null ? 0 : entries.length;
      this.clear();
      while (++index < length) {
        var entry = entries[index];
        this.set(entry[0], entry[1]);
      }
    }
    Hash.prototype.clear = hashClear;
    Hash.prototype["delete"] = hashDelete;
    Hash.prototype.get = hashGet;
    Hash.prototype.has = hashHas;
    Hash.prototype.set = hashSet;
    module.exports = Hash;
  }
});

// ../../node_modules/lodash/_mapCacheClear.js
var require_mapCacheClear = __commonJS({
  "../../node_modules/lodash/_mapCacheClear.js"(exports, module) {
    var Hash = require_Hash();
    var ListCache = require_ListCache();
    var Map2 = require_Map();
    function mapCacheClear() {
      this.size = 0;
      this.__data__ = {
        "hash": new Hash(),
        "map": new (Map2 || ListCache)(),
        "string": new Hash()
      };
    }
    module.exports = mapCacheClear;
  }
});

// ../../node_modules/lodash/_isKeyable.js
var require_isKeyable = __commonJS({
  "../../node_modules/lodash/_isKeyable.js"(exports, module) {
    function isKeyable(value) {
      var type = typeof value;
      return type == "string" || type == "number" || type == "symbol" || type == "boolean" ? value !== "__proto__" : value === null;
    }
    module.exports = isKeyable;
  }
});

// ../../node_modules/lodash/_getMapData.js
var require_getMapData = __commonJS({
  "../../node_modules/lodash/_getMapData.js"(exports, module) {
    var isKeyable = require_isKeyable();
    function getMapData(map, key) {
      var data = map.__data__;
      return isKeyable(key) ? data[typeof key == "string" ? "string" : "hash"] : data.map;
    }
    module.exports = getMapData;
  }
});

// ../../node_modules/lodash/_mapCacheDelete.js
var require_mapCacheDelete = __commonJS({
  "../../node_modules/lodash/_mapCacheDelete.js"(exports, module) {
    var getMapData = require_getMapData();
    function mapCacheDelete(key) {
      var result = getMapData(this, key)["delete"](key);
      this.size -= result ? 1 : 0;
      return result;
    }
    module.exports = mapCacheDelete;
  }
});

// ../../node_modules/lodash/_mapCacheGet.js
var require_mapCacheGet = __commonJS({
  "../../node_modules/lodash/_mapCacheGet.js"(exports, module) {
    var getMapData = require_getMapData();
    function mapCacheGet(key) {
      return getMapData(this, key).get(key);
    }
    module.exports = mapCacheGet;
  }
});

// ../../node_modules/lodash/_mapCacheHas.js
var require_mapCacheHas = __commonJS({
  "../../node_modules/lodash/_mapCacheHas.js"(exports, module) {
    var getMapData = require_getMapData();
    function mapCacheHas(key) {
      return getMapData(this, key).has(key);
    }
    module.exports = mapCacheHas;
  }
});

// ../../node_modules/lodash/_mapCacheSet.js
var require_mapCacheSet = __commonJS({
  "../../node_modules/lodash/_mapCacheSet.js"(exports, module) {
    var getMapData = require_getMapData();
    function mapCacheSet(key, value) {
      var data = getMapData(this, key), size = data.size;
      data.set(key, value);
      this.size += data.size == size ? 0 : 1;
      return this;
    }
    module.exports = mapCacheSet;
  }
});

// ../../node_modules/lodash/_MapCache.js
var require_MapCache = __commonJS({
  "../../node_modules/lodash/_MapCache.js"(exports, module) {
    var mapCacheClear = require_mapCacheClear();
    var mapCacheDelete = require_mapCacheDelete();
    var mapCacheGet = require_mapCacheGet();
    var mapCacheHas = require_mapCacheHas();
    var mapCacheSet = require_mapCacheSet();
    function MapCache(entries) {
      var index = -1, length = entries == null ? 0 : entries.length;
      this.clear();
      while (++index < length) {
        var entry = entries[index];
        this.set(entry[0], entry[1]);
      }
    }
    MapCache.prototype.clear = mapCacheClear;
    MapCache.prototype["delete"] = mapCacheDelete;
    MapCache.prototype.get = mapCacheGet;
    MapCache.prototype.has = mapCacheHas;
    MapCache.prototype.set = mapCacheSet;
    module.exports = MapCache;
  }
});

// ../../node_modules/lodash/_stackSet.js
var require_stackSet = __commonJS({
  "../../node_modules/lodash/_stackSet.js"(exports, module) {
    var ListCache = require_ListCache();
    var Map2 = require_Map();
    var MapCache = require_MapCache();
    var LARGE_ARRAY_SIZE = 200;
    function stackSet(key, value) {
      var data = this.__data__;
      if (data instanceof ListCache) {
        var pairs = data.__data__;
        if (!Map2 || pairs.length < LARGE_ARRAY_SIZE - 1) {
          pairs.push([key, value]);
          this.size = ++data.size;
          return this;
        }
        data = this.__data__ = new MapCache(pairs);
      }
      data.set(key, value);
      this.size = data.size;
      return this;
    }
    module.exports = stackSet;
  }
});

// ../../node_modules/lodash/_Stack.js
var require_Stack = __commonJS({
  "../../node_modules/lodash/_Stack.js"(exports, module) {
    var ListCache = require_ListCache();
    var stackClear = require_stackClear();
    var stackDelete = require_stackDelete();
    var stackGet = require_stackGet();
    var stackHas = require_stackHas();
    var stackSet = require_stackSet();
    function Stack(entries) {
      var data = this.__data__ = new ListCache(entries);
      this.size = data.size;
    }
    Stack.prototype.clear = stackClear;
    Stack.prototype["delete"] = stackDelete;
    Stack.prototype.get = stackGet;
    Stack.prototype.has = stackHas;
    Stack.prototype.set = stackSet;
    module.exports = Stack;
  }
});

// ../../node_modules/lodash/_defineProperty.js
var require_defineProperty = __commonJS({
  "../../node_modules/lodash/_defineProperty.js"(exports, module) {
    var getNative = require_getNative();
    var defineProperty = function() {
      try {
        var func = getNative(Object, "defineProperty");
        func({}, "", {});
        return func;
      } catch (e) {
      }
    }();
    module.exports = defineProperty;
  }
});

// ../../node_modules/lodash/_baseAssignValue.js
var require_baseAssignValue = __commonJS({
  "../../node_modules/lodash/_baseAssignValue.js"(exports, module) {
    var defineProperty = require_defineProperty();
    function baseAssignValue(object, key, value) {
      if (key == "__proto__" && defineProperty) {
        defineProperty(object, key, {
          "configurable": true,
          "enumerable": true,
          "value": value,
          "writable": true
        });
      } else {
        object[key] = value;
      }
    }
    module.exports = baseAssignValue;
  }
});

// ../../node_modules/lodash/_assignMergeValue.js
var require_assignMergeValue = __commonJS({
  "../../node_modules/lodash/_assignMergeValue.js"(exports, module) {
    var baseAssignValue = require_baseAssignValue();
    var eq = require_eq();
    function assignMergeValue(object, key, value) {
      if (value !== void 0 && !eq(object[key], value) || value === void 0 && !(key in object)) {
        baseAssignValue(object, key, value);
      }
    }
    module.exports = assignMergeValue;
  }
});

// ../../node_modules/lodash/_createBaseFor.js
var require_createBaseFor = __commonJS({
  "../../node_modules/lodash/_createBaseFor.js"(exports, module) {
    function createBaseFor(fromRight) {
      return function(object, iteratee, keysFunc) {
        var index = -1, iterable = Object(object), props = keysFunc(object), length = props.length;
        while (length--) {
          var key = props[fromRight ? length : ++index];
          if (iteratee(iterable[key], key, iterable) === false) {
            break;
          }
        }
        return object;
      };
    }
    module.exports = createBaseFor;
  }
});

// ../../node_modules/lodash/_baseFor.js
var require_baseFor = __commonJS({
  "../../node_modules/lodash/_baseFor.js"(exports, module) {
    var createBaseFor = require_createBaseFor();
    var baseFor = createBaseFor();
    module.exports = baseFor;
  }
});

// ../../node_modules/lodash/_cloneBuffer.js
var require_cloneBuffer = __commonJS({
  "../../node_modules/lodash/_cloneBuffer.js"(exports, module) {
    var root = require_root();
    var freeExports = typeof exports == "object" && exports && !exports.nodeType && exports;
    var freeModule = freeExports && typeof module == "object" && module && !module.nodeType && module;
    var moduleExports = freeModule && freeModule.exports === freeExports;
    var Buffer2 = moduleExports ? root.Buffer : void 0;
    var allocUnsafe = Buffer2 ? Buffer2.allocUnsafe : void 0;
    function cloneBuffer(buffer, isDeep) {
      if (isDeep) {
        return buffer.slice();
      }
      var length = buffer.length, result = allocUnsafe ? allocUnsafe(length) : new buffer.constructor(length);
      buffer.copy(result);
      return result;
    }
    module.exports = cloneBuffer;
  }
});

// ../../node_modules/lodash/_Uint8Array.js
var require_Uint8Array = __commonJS({
  "../../node_modules/lodash/_Uint8Array.js"(exports, module) {
    var root = require_root();
    var Uint8Array2 = root.Uint8Array;
    module.exports = Uint8Array2;
  }
});

// ../../node_modules/lodash/_cloneArrayBuffer.js
var require_cloneArrayBuffer = __commonJS({
  "../../node_modules/lodash/_cloneArrayBuffer.js"(exports, module) {
    var Uint8Array2 = require_Uint8Array();
    function cloneArrayBuffer(arrayBuffer) {
      var result = new arrayBuffer.constructor(arrayBuffer.byteLength);
      new Uint8Array2(result).set(new Uint8Array2(arrayBuffer));
      return result;
    }
    module.exports = cloneArrayBuffer;
  }
});

// ../../node_modules/lodash/_cloneTypedArray.js
var require_cloneTypedArray = __commonJS({
  "../../node_modules/lodash/_cloneTypedArray.js"(exports, module) {
    var cloneArrayBuffer = require_cloneArrayBuffer();
    function cloneTypedArray(typedArray, isDeep) {
      var buffer = isDeep ? cloneArrayBuffer(typedArray.buffer) : typedArray.buffer;
      return new typedArray.constructor(buffer, typedArray.byteOffset, typedArray.length);
    }
    module.exports = cloneTypedArray;
  }
});

// ../../node_modules/lodash/_copyArray.js
var require_copyArray = __commonJS({
  "../../node_modules/lodash/_copyArray.js"(exports, module) {
    function copyArray(source, array) {
      var index = -1, length = source.length;
      array || (array = Array(length));
      while (++index < length) {
        array[index] = source[index];
      }
      return array;
    }
    module.exports = copyArray;
  }
});

// ../../node_modules/lodash/_baseCreate.js
var require_baseCreate = __commonJS({
  "../../node_modules/lodash/_baseCreate.js"(exports, module) {
    var isObject = require_isObject();
    var objectCreate = Object.create;
    var baseCreate = function() {
      function object() {
      }
      return function(proto) {
        if (!isObject(proto)) {
          return {};
        }
        if (objectCreate) {
          return objectCreate(proto);
        }
        object.prototype = proto;
        var result = new object();
        object.prototype = void 0;
        return result;
      };
    }();
    module.exports = baseCreate;
  }
});

// ../../node_modules/lodash/_overArg.js
var require_overArg = __commonJS({
  "../../node_modules/lodash/_overArg.js"(exports, module) {
    function overArg(func, transform) {
      return function(arg) {
        return func(transform(arg));
      };
    }
    module.exports = overArg;
  }
});

// ../../node_modules/lodash/_getPrototype.js
var require_getPrototype = __commonJS({
  "../../node_modules/lodash/_getPrototype.js"(exports, module) {
    var overArg = require_overArg();
    var getPrototype = overArg(Object.getPrototypeOf, Object);
    module.exports = getPrototype;
  }
});

// ../../node_modules/lodash/_isPrototype.js
var require_isPrototype = __commonJS({
  "../../node_modules/lodash/_isPrototype.js"(exports, module) {
    var objectProto = Object.prototype;
    function isPrototype(value) {
      var Ctor = value && value.constructor, proto = typeof Ctor == "function" && Ctor.prototype || objectProto;
      return value === proto;
    }
    module.exports = isPrototype;
  }
});

// ../../node_modules/lodash/_initCloneObject.js
var require_initCloneObject = __commonJS({
  "../../node_modules/lodash/_initCloneObject.js"(exports, module) {
    var baseCreate = require_baseCreate();
    var getPrototype = require_getPrototype();
    var isPrototype = require_isPrototype();
    function initCloneObject(object) {
      return typeof object.constructor == "function" && !isPrototype(object) ? baseCreate(getPrototype(object)) : {};
    }
    module.exports = initCloneObject;
  }
});

// ../../node_modules/lodash/isObjectLike.js
var require_isObjectLike = __commonJS({
  "../../node_modules/lodash/isObjectLike.js"(exports, module) {
    function isObjectLike(value) {
      return value != null && typeof value == "object";
    }
    module.exports = isObjectLike;
  }
});

// ../../node_modules/lodash/_baseIsArguments.js
var require_baseIsArguments = __commonJS({
  "../../node_modules/lodash/_baseIsArguments.js"(exports, module) {
    var baseGetTag = require_baseGetTag();
    var isObjectLike = require_isObjectLike();
    var argsTag = "[object Arguments]";
    function baseIsArguments(value) {
      return isObjectLike(value) && baseGetTag(value) == argsTag;
    }
    module.exports = baseIsArguments;
  }
});

// ../../node_modules/lodash/isArguments.js
var require_isArguments = __commonJS({
  "../../node_modules/lodash/isArguments.js"(exports, module) {
    var baseIsArguments = require_baseIsArguments();
    var isObjectLike = require_isObjectLike();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    var propertyIsEnumerable = objectProto.propertyIsEnumerable;
    var isArguments = baseIsArguments(function() {
      return arguments;
    }()) ? baseIsArguments : function(value) {
      return isObjectLike(value) && hasOwnProperty.call(value, "callee") && !propertyIsEnumerable.call(value, "callee");
    };
    module.exports = isArguments;
  }
});

// ../../node_modules/lodash/isArray.js
var require_isArray = __commonJS({
  "../../node_modules/lodash/isArray.js"(exports, module) {
    var isArray = Array.isArray;
    module.exports = isArray;
  }
});

// ../../node_modules/lodash/isLength.js
var require_isLength = __commonJS({
  "../../node_modules/lodash/isLength.js"(exports, module) {
    var MAX_SAFE_INTEGER = 9007199254740991;
    function isLength(value) {
      return typeof value == "number" && value > -1 && value % 1 == 0 && value <= MAX_SAFE_INTEGER;
    }
    module.exports = isLength;
  }
});

// ../../node_modules/lodash/isArrayLike.js
var require_isArrayLike = __commonJS({
  "../../node_modules/lodash/isArrayLike.js"(exports, module) {
    var isFunction = require_isFunction();
    var isLength = require_isLength();
    function isArrayLike(value) {
      return value != null && isLength(value.length) && !isFunction(value);
    }
    module.exports = isArrayLike;
  }
});

// ../../node_modules/lodash/isArrayLikeObject.js
var require_isArrayLikeObject = __commonJS({
  "../../node_modules/lodash/isArrayLikeObject.js"(exports, module) {
    var isArrayLike = require_isArrayLike();
    var isObjectLike = require_isObjectLike();
    function isArrayLikeObject(value) {
      return isObjectLike(value) && isArrayLike(value);
    }
    module.exports = isArrayLikeObject;
  }
});

// ../../node_modules/lodash/stubFalse.js
var require_stubFalse = __commonJS({
  "../../node_modules/lodash/stubFalse.js"(exports, module) {
    function stubFalse() {
      return false;
    }
    module.exports = stubFalse;
  }
});

// ../../node_modules/lodash/isBuffer.js
var require_isBuffer = __commonJS({
  "../../node_modules/lodash/isBuffer.js"(exports, module) {
    var root = require_root();
    var stubFalse = require_stubFalse();
    var freeExports = typeof exports == "object" && exports && !exports.nodeType && exports;
    var freeModule = freeExports && typeof module == "object" && module && !module.nodeType && module;
    var moduleExports = freeModule && freeModule.exports === freeExports;
    var Buffer2 = moduleExports ? root.Buffer : void 0;
    var nativeIsBuffer = Buffer2 ? Buffer2.isBuffer : void 0;
    var isBuffer = nativeIsBuffer || stubFalse;
    module.exports = isBuffer;
  }
});

// ../../node_modules/lodash/isPlainObject.js
var require_isPlainObject = __commonJS({
  "../../node_modules/lodash/isPlainObject.js"(exports, module) {
    var baseGetTag = require_baseGetTag();
    var getPrototype = require_getPrototype();
    var isObjectLike = require_isObjectLike();
    var objectTag = "[object Object]";
    var funcProto = Function.prototype;
    var objectProto = Object.prototype;
    var funcToString = funcProto.toString;
    var hasOwnProperty = objectProto.hasOwnProperty;
    var objectCtorString = funcToString.call(Object);
    function isPlainObject(value) {
      if (!isObjectLike(value) || baseGetTag(value) != objectTag) {
        return false;
      }
      var proto = getPrototype(value);
      if (proto === null) {
        return true;
      }
      var Ctor = hasOwnProperty.call(proto, "constructor") && proto.constructor;
      return typeof Ctor == "function" && Ctor instanceof Ctor && funcToString.call(Ctor) == objectCtorString;
    }
    module.exports = isPlainObject;
  }
});

// ../../node_modules/lodash/_baseIsTypedArray.js
var require_baseIsTypedArray = __commonJS({
  "../../node_modules/lodash/_baseIsTypedArray.js"(exports, module) {
    var baseGetTag = require_baseGetTag();
    var isLength = require_isLength();
    var isObjectLike = require_isObjectLike();
    var argsTag = "[object Arguments]";
    var arrayTag = "[object Array]";
    var boolTag = "[object Boolean]";
    var dateTag = "[object Date]";
    var errorTag = "[object Error]";
    var funcTag = "[object Function]";
    var mapTag = "[object Map]";
    var numberTag = "[object Number]";
    var objectTag = "[object Object]";
    var regexpTag = "[object RegExp]";
    var setTag = "[object Set]";
    var stringTag = "[object String]";
    var weakMapTag = "[object WeakMap]";
    var arrayBufferTag = "[object ArrayBuffer]";
    var dataViewTag = "[object DataView]";
    var float32Tag = "[object Float32Array]";
    var float64Tag = "[object Float64Array]";
    var int8Tag = "[object Int8Array]";
    var int16Tag = "[object Int16Array]";
    var int32Tag = "[object Int32Array]";
    var uint8Tag = "[object Uint8Array]";
    var uint8ClampedTag = "[object Uint8ClampedArray]";
    var uint16Tag = "[object Uint16Array]";
    var uint32Tag = "[object Uint32Array]";
    var typedArrayTags = {};
    typedArrayTags[float32Tag] = typedArrayTags[float64Tag] = typedArrayTags[int8Tag] = typedArrayTags[int16Tag] = typedArrayTags[int32Tag] = typedArrayTags[uint8Tag] = typedArrayTags[uint8ClampedTag] = typedArrayTags[uint16Tag] = typedArrayTags[uint32Tag] = true;
    typedArrayTags[argsTag] = typedArrayTags[arrayTag] = typedArrayTags[arrayBufferTag] = typedArrayTags[boolTag] = typedArrayTags[dataViewTag] = typedArrayTags[dateTag] = typedArrayTags[errorTag] = typedArrayTags[funcTag] = typedArrayTags[mapTag] = typedArrayTags[numberTag] = typedArrayTags[objectTag] = typedArrayTags[regexpTag] = typedArrayTags[setTag] = typedArrayTags[stringTag] = typedArrayTags[weakMapTag] = false;
    function baseIsTypedArray(value) {
      return isObjectLike(value) && isLength(value.length) && !!typedArrayTags[baseGetTag(value)];
    }
    module.exports = baseIsTypedArray;
  }
});

// ../../node_modules/lodash/_baseUnary.js
var require_baseUnary = __commonJS({
  "../../node_modules/lodash/_baseUnary.js"(exports, module) {
    function baseUnary(func) {
      return function(value) {
        return func(value);
      };
    }
    module.exports = baseUnary;
  }
});

// ../../node_modules/lodash/_nodeUtil.js
var require_nodeUtil = __commonJS({
  "../../node_modules/lodash/_nodeUtil.js"(exports, module) {
    var freeGlobal = require_freeGlobal();
    var freeExports = typeof exports == "object" && exports && !exports.nodeType && exports;
    var freeModule = freeExports && typeof module == "object" && module && !module.nodeType && module;
    var moduleExports = freeModule && freeModule.exports === freeExports;
    var freeProcess = moduleExports && freeGlobal.process;
    var nodeUtil = function() {
      try {
        var types = freeModule && freeModule.require && freeModule.require("util").types;
        if (types) {
          return types;
        }
        return freeProcess && freeProcess.binding && freeProcess.binding("util");
      } catch (e) {
      }
    }();
    module.exports = nodeUtil;
  }
});

// ../../node_modules/lodash/isTypedArray.js
var require_isTypedArray = __commonJS({
  "../../node_modules/lodash/isTypedArray.js"(exports, module) {
    var baseIsTypedArray = require_baseIsTypedArray();
    var baseUnary = require_baseUnary();
    var nodeUtil = require_nodeUtil();
    var nodeIsTypedArray = nodeUtil && nodeUtil.isTypedArray;
    var isTypedArray = nodeIsTypedArray ? baseUnary(nodeIsTypedArray) : baseIsTypedArray;
    module.exports = isTypedArray;
  }
});

// ../../node_modules/lodash/_safeGet.js
var require_safeGet = __commonJS({
  "../../node_modules/lodash/_safeGet.js"(exports, module) {
    function safeGet(object, key) {
      if (key === "constructor" && typeof object[key] === "function") {
        return;
      }
      if (key == "__proto__") {
        return;
      }
      return object[key];
    }
    module.exports = safeGet;
  }
});

// ../../node_modules/lodash/_assignValue.js
var require_assignValue = __commonJS({
  "../../node_modules/lodash/_assignValue.js"(exports, module) {
    var baseAssignValue = require_baseAssignValue();
    var eq = require_eq();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function assignValue(object, key, value) {
      var objValue = object[key];
      if (!(hasOwnProperty.call(object, key) && eq(objValue, value)) || value === void 0 && !(key in object)) {
        baseAssignValue(object, key, value);
      }
    }
    module.exports = assignValue;
  }
});

// ../../node_modules/lodash/_copyObject.js
var require_copyObject = __commonJS({
  "../../node_modules/lodash/_copyObject.js"(exports, module) {
    var assignValue = require_assignValue();
    var baseAssignValue = require_baseAssignValue();
    function copyObject(source, props, object, customizer) {
      var isNew = !object;
      object || (object = {});
      var index = -1, length = props.length;
      while (++index < length) {
        var key = props[index];
        var newValue = customizer ? customizer(object[key], source[key], key, object, source) : void 0;
        if (newValue === void 0) {
          newValue = source[key];
        }
        if (isNew) {
          baseAssignValue(object, key, newValue);
        } else {
          assignValue(object, key, newValue);
        }
      }
      return object;
    }
    module.exports = copyObject;
  }
});

// ../../node_modules/lodash/_baseTimes.js
var require_baseTimes = __commonJS({
  "../../node_modules/lodash/_baseTimes.js"(exports, module) {
    function baseTimes(n, iteratee) {
      var index = -1, result = Array(n);
      while (++index < n) {
        result[index] = iteratee(index);
      }
      return result;
    }
    module.exports = baseTimes;
  }
});

// ../../node_modules/lodash/_isIndex.js
var require_isIndex = __commonJS({
  "../../node_modules/lodash/_isIndex.js"(exports, module) {
    var MAX_SAFE_INTEGER = 9007199254740991;
    var reIsUint = /^(?:0|[1-9]\d*)$/;
    function isIndex(value, length) {
      var type = typeof value;
      length = length == null ? MAX_SAFE_INTEGER : length;
      return !!length && (type == "number" || type != "symbol" && reIsUint.test(value)) && (value > -1 && value % 1 == 0 && value < length);
    }
    module.exports = isIndex;
  }
});

// ../../node_modules/lodash/_arrayLikeKeys.js
var require_arrayLikeKeys = __commonJS({
  "../../node_modules/lodash/_arrayLikeKeys.js"(exports, module) {
    var baseTimes = require_baseTimes();
    var isArguments = require_isArguments();
    var isArray = require_isArray();
    var isBuffer = require_isBuffer();
    var isIndex = require_isIndex();
    var isTypedArray = require_isTypedArray();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function arrayLikeKeys(value, inherited) {
      var isArr = isArray(value), isArg = !isArr && isArguments(value), isBuff = !isArr && !isArg && isBuffer(value), isType = !isArr && !isArg && !isBuff && isTypedArray(value), skipIndexes = isArr || isArg || isBuff || isType, result = skipIndexes ? baseTimes(value.length, String) : [], length = result.length;
      for (var key in value) {
        if ((inherited || hasOwnProperty.call(value, key)) && !(skipIndexes && // Safari 9 has enumerable `arguments.length` in strict mode.
        (key == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
        isBuff && (key == "offset" || key == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
        isType && (key == "buffer" || key == "byteLength" || key == "byteOffset") || // Skip index properties.
        isIndex(key, length)))) {
          result.push(key);
        }
      }
      return result;
    }
    module.exports = arrayLikeKeys;
  }
});

// ../../node_modules/lodash/_nativeKeysIn.js
var require_nativeKeysIn = __commonJS({
  "../../node_modules/lodash/_nativeKeysIn.js"(exports, module) {
    function nativeKeysIn(object) {
      var result = [];
      if (object != null) {
        for (var key in Object(object)) {
          result.push(key);
        }
      }
      return result;
    }
    module.exports = nativeKeysIn;
  }
});

// ../../node_modules/lodash/_baseKeysIn.js
var require_baseKeysIn = __commonJS({
  "../../node_modules/lodash/_baseKeysIn.js"(exports, module) {
    var isObject = require_isObject();
    var isPrototype = require_isPrototype();
    var nativeKeysIn = require_nativeKeysIn();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function baseKeysIn(object) {
      if (!isObject(object)) {
        return nativeKeysIn(object);
      }
      var isProto = isPrototype(object), result = [];
      for (var key in object) {
        if (!(key == "constructor" && (isProto || !hasOwnProperty.call(object, key)))) {
          result.push(key);
        }
      }
      return result;
    }
    module.exports = baseKeysIn;
  }
});

// ../../node_modules/lodash/keysIn.js
var require_keysIn = __commonJS({
  "../../node_modules/lodash/keysIn.js"(exports, module) {
    var arrayLikeKeys = require_arrayLikeKeys();
    var baseKeysIn = require_baseKeysIn();
    var isArrayLike = require_isArrayLike();
    function keysIn(object) {
      return isArrayLike(object) ? arrayLikeKeys(object, true) : baseKeysIn(object);
    }
    module.exports = keysIn;
  }
});

// ../../node_modules/lodash/toPlainObject.js
var require_toPlainObject = __commonJS({
  "../../node_modules/lodash/toPlainObject.js"(exports, module) {
    var copyObject = require_copyObject();
    var keysIn = require_keysIn();
    function toPlainObject(value) {
      return copyObject(value, keysIn(value));
    }
    module.exports = toPlainObject;
  }
});

// ../../node_modules/lodash/_baseMergeDeep.js
var require_baseMergeDeep = __commonJS({
  "../../node_modules/lodash/_baseMergeDeep.js"(exports, module) {
    var assignMergeValue = require_assignMergeValue();
    var cloneBuffer = require_cloneBuffer();
    var cloneTypedArray = require_cloneTypedArray();
    var copyArray = require_copyArray();
    var initCloneObject = require_initCloneObject();
    var isArguments = require_isArguments();
    var isArray = require_isArray();
    var isArrayLikeObject = require_isArrayLikeObject();
    var isBuffer = require_isBuffer();
    var isFunction = require_isFunction();
    var isObject = require_isObject();
    var isPlainObject = require_isPlainObject();
    var isTypedArray = require_isTypedArray();
    var safeGet = require_safeGet();
    var toPlainObject = require_toPlainObject();
    function baseMergeDeep(object, source, key, srcIndex, mergeFunc, customizer, stack) {
      var objValue = safeGet(object, key), srcValue = safeGet(source, key), stacked = stack.get(srcValue);
      if (stacked) {
        assignMergeValue(object, key, stacked);
        return;
      }
      var newValue = customizer ? customizer(objValue, srcValue, key + "", object, source, stack) : void 0;
      var isCommon = newValue === void 0;
      if (isCommon) {
        var isArr = isArray(srcValue), isBuff = !isArr && isBuffer(srcValue), isTyped = !isArr && !isBuff && isTypedArray(srcValue);
        newValue = srcValue;
        if (isArr || isBuff || isTyped) {
          if (isArray(objValue)) {
            newValue = objValue;
          } else if (isArrayLikeObject(objValue)) {
            newValue = copyArray(objValue);
          } else if (isBuff) {
            isCommon = false;
            newValue = cloneBuffer(srcValue, true);
          } else if (isTyped) {
            isCommon = false;
            newValue = cloneTypedArray(srcValue, true);
          } else {
            newValue = [];
          }
        } else if (isPlainObject(srcValue) || isArguments(srcValue)) {
          newValue = objValue;
          if (isArguments(objValue)) {
            newValue = toPlainObject(objValue);
          } else if (!isObject(objValue) || isFunction(objValue)) {
            newValue = initCloneObject(srcValue);
          }
        } else {
          isCommon = false;
        }
      }
      if (isCommon) {
        stack.set(srcValue, newValue);
        mergeFunc(newValue, srcValue, srcIndex, customizer, stack);
        stack["delete"](srcValue);
      }
      assignMergeValue(object, key, newValue);
    }
    module.exports = baseMergeDeep;
  }
});

// ../../node_modules/lodash/_baseMerge.js
var require_baseMerge = __commonJS({
  "../../node_modules/lodash/_baseMerge.js"(exports, module) {
    var Stack = require_Stack();
    var assignMergeValue = require_assignMergeValue();
    var baseFor = require_baseFor();
    var baseMergeDeep = require_baseMergeDeep();
    var isObject = require_isObject();
    var keysIn = require_keysIn();
    var safeGet = require_safeGet();
    function baseMerge(object, source, srcIndex, customizer, stack) {
      if (object === source) {
        return;
      }
      baseFor(source, function(srcValue, key) {
        stack || (stack = new Stack());
        if (isObject(srcValue)) {
          baseMergeDeep(object, source, key, srcIndex, baseMerge, customizer, stack);
        } else {
          var newValue = customizer ? customizer(safeGet(object, key), srcValue, key + "", object, source, stack) : void 0;
          if (newValue === void 0) {
            newValue = srcValue;
          }
          assignMergeValue(object, key, newValue);
        }
      }, keysIn);
    }
    module.exports = baseMerge;
  }
});

// ../../node_modules/lodash/identity.js
var require_identity = __commonJS({
  "../../node_modules/lodash/identity.js"(exports, module) {
    function identity(value) {
      return value;
    }
    module.exports = identity;
  }
});

// ../../node_modules/lodash/_apply.js
var require_apply = __commonJS({
  "../../node_modules/lodash/_apply.js"(exports, module) {
    function apply(func, thisArg, args) {
      switch (args.length) {
        case 0:
          return func.call(thisArg);
        case 1:
          return func.call(thisArg, args[0]);
        case 2:
          return func.call(thisArg, args[0], args[1]);
        case 3:
          return func.call(thisArg, args[0], args[1], args[2]);
      }
      return func.apply(thisArg, args);
    }
    module.exports = apply;
  }
});

// ../../node_modules/lodash/_overRest.js
var require_overRest = __commonJS({
  "../../node_modules/lodash/_overRest.js"(exports, module) {
    var apply = require_apply();
    var nativeMax = Math.max;
    function overRest(func, start, transform) {
      start = nativeMax(start === void 0 ? func.length - 1 : start, 0);
      return function() {
        var args = arguments, index = -1, length = nativeMax(args.length - start, 0), array = Array(length);
        while (++index < length) {
          array[index] = args[start + index];
        }
        index = -1;
        var otherArgs = Array(start + 1);
        while (++index < start) {
          otherArgs[index] = args[index];
        }
        otherArgs[start] = transform(array);
        return apply(func, this, otherArgs);
      };
    }
    module.exports = overRest;
  }
});

// ../../node_modules/lodash/constant.js
var require_constant = __commonJS({
  "../../node_modules/lodash/constant.js"(exports, module) {
    function constant(value) {
      return function() {
        return value;
      };
    }
    module.exports = constant;
  }
});

// ../../node_modules/lodash/_baseSetToString.js
var require_baseSetToString = __commonJS({
  "../../node_modules/lodash/_baseSetToString.js"(exports, module) {
    var constant = require_constant();
    var defineProperty = require_defineProperty();
    var identity = require_identity();
    var baseSetToString = !defineProperty ? identity : function(func, string) {
      return defineProperty(func, "toString", {
        "configurable": true,
        "enumerable": false,
        "value": constant(string),
        "writable": true
      });
    };
    module.exports = baseSetToString;
  }
});

// ../../node_modules/lodash/_shortOut.js
var require_shortOut = __commonJS({
  "../../node_modules/lodash/_shortOut.js"(exports, module) {
    var HOT_COUNT = 800;
    var HOT_SPAN = 16;
    var nativeNow = Date.now;
    function shortOut(func) {
      var count = 0, lastCalled = 0;
      return function() {
        var stamp = nativeNow(), remaining = HOT_SPAN - (stamp - lastCalled);
        lastCalled = stamp;
        if (remaining > 0) {
          if (++count >= HOT_COUNT) {
            return arguments[0];
          }
        } else {
          count = 0;
        }
        return func.apply(void 0, arguments);
      };
    }
    module.exports = shortOut;
  }
});

// ../../node_modules/lodash/_setToString.js
var require_setToString = __commonJS({
  "../../node_modules/lodash/_setToString.js"(exports, module) {
    var baseSetToString = require_baseSetToString();
    var shortOut = require_shortOut();
    var setToString = shortOut(baseSetToString);
    module.exports = setToString;
  }
});

// ../../node_modules/lodash/_baseRest.js
var require_baseRest = __commonJS({
  "../../node_modules/lodash/_baseRest.js"(exports, module) {
    var identity = require_identity();
    var overRest = require_overRest();
    var setToString = require_setToString();
    function baseRest(func, start) {
      return setToString(overRest(func, start, identity), func + "");
    }
    module.exports = baseRest;
  }
});

// ../../node_modules/lodash/_isIterateeCall.js
var require_isIterateeCall = __commonJS({
  "../../node_modules/lodash/_isIterateeCall.js"(exports, module) {
    var eq = require_eq();
    var isArrayLike = require_isArrayLike();
    var isIndex = require_isIndex();
    var isObject = require_isObject();
    function isIterateeCall(value, index, object) {
      if (!isObject(object)) {
        return false;
      }
      var type = typeof index;
      if (type == "number" ? isArrayLike(object) && isIndex(index, object.length) : type == "string" && index in object) {
        return eq(object[index], value);
      }
      return false;
    }
    module.exports = isIterateeCall;
  }
});

// ../../node_modules/lodash/_createAssigner.js
var require_createAssigner = __commonJS({
  "../../node_modules/lodash/_createAssigner.js"(exports, module) {
    var baseRest = require_baseRest();
    var isIterateeCall = require_isIterateeCall();
    function createAssigner(assigner) {
      return baseRest(function(object, sources) {
        var index = -1, length = sources.length, customizer = length > 1 ? sources[length - 1] : void 0, guard = length > 2 ? sources[2] : void 0;
        customizer = assigner.length > 3 && typeof customizer == "function" ? (length--, customizer) : void 0;
        if (guard && isIterateeCall(sources[0], sources[1], guard)) {
          customizer = length < 3 ? void 0 : customizer;
          length = 1;
        }
        object = Object(object);
        while (++index < length) {
          var source = sources[index];
          if (source) {
            assigner(object, source, index, customizer);
          }
        }
        return object;
      });
    }
    module.exports = createAssigner;
  }
});

// ../../node_modules/lodash/merge.js
var require_merge = __commonJS({
  "../../node_modules/lodash/merge.js"(exports, module) {
    var baseMerge = require_baseMerge();
    var createAssigner = require_createAssigner();
    var merge2 = createAssigner(function(object, source, srcIndex) {
      baseMerge(object, source, srcIndex);
    });
    module.exports = merge2;
  }
});

// ../../node_modules/zod/lib/index.mjs
var util;
(function(util2) {
  util2.assertEqual = (val) => val;
  function assertIs(_arg) {
  }
  util2.assertIs = assertIs;
  function assertNever(_x) {
    throw new Error();
  }
  util2.assertNever = assertNever;
  util2.arrayToEnum = (items) => {
    const obj = {};
    for (const item of items) {
      obj[item] = item;
    }
    return obj;
  };
  util2.getValidEnumValues = (obj) => {
    const validKeys = util2.objectKeys(obj).filter((k) => typeof obj[obj[k]] !== "number");
    const filtered = {};
    for (const k of validKeys) {
      filtered[k] = obj[k];
    }
    return util2.objectValues(filtered);
  };
  util2.objectValues = (obj) => {
    return util2.objectKeys(obj).map(function(e) {
      return obj[e];
    });
  };
  util2.objectKeys = typeof Object.keys === "function" ? (obj) => Object.keys(obj) : (object) => {
    const keys = [];
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        keys.push(key);
      }
    }
    return keys;
  };
  util2.find = (arr, checker) => {
    for (const item of arr) {
      if (checker(item))
        return item;
    }
    return void 0;
  };
  util2.isInteger = typeof Number.isInteger === "function" ? (val) => Number.isInteger(val) : (val) => typeof val === "number" && isFinite(val) && Math.floor(val) === val;
  function joinValues(array, separator = " | ") {
    return array.map((val) => typeof val === "string" ? `'${val}'` : val).join(separator);
  }
  util2.joinValues = joinValues;
  util2.jsonStringifyReplacer = (_, value) => {
    if (typeof value === "bigint") {
      return value.toString();
    }
    return value;
  };
})(util || (util = {}));
var objectUtil;
(function(objectUtil2) {
  objectUtil2.mergeShapes = (first, second) => {
    return __spreadValues(__spreadValues({}, first), second);
  };
})(objectUtil || (objectUtil = {}));
var ZodParsedType = util.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]);
var getParsedType = (data) => {
  const t = typeof data;
  switch (t) {
    case "undefined":
      return ZodParsedType.undefined;
    case "string":
      return ZodParsedType.string;
    case "number":
      return isNaN(data) ? ZodParsedType.nan : ZodParsedType.number;
    case "boolean":
      return ZodParsedType.boolean;
    case "function":
      return ZodParsedType.function;
    case "bigint":
      return ZodParsedType.bigint;
    case "symbol":
      return ZodParsedType.symbol;
    case "object":
      if (Array.isArray(data)) {
        return ZodParsedType.array;
      }
      if (data === null) {
        return ZodParsedType.null;
      }
      if (data.then && typeof data.then === "function" && data.catch && typeof data.catch === "function") {
        return ZodParsedType.promise;
      }
      if (typeof Map !== "undefined" && data instanceof Map) {
        return ZodParsedType.map;
      }
      if (typeof Set !== "undefined" && data instanceof Set) {
        return ZodParsedType.set;
      }
      if (typeof Date !== "undefined" && data instanceof Date) {
        return ZodParsedType.date;
      }
      return ZodParsedType.object;
    default:
      return ZodParsedType.unknown;
  }
};
var ZodIssueCode = util.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]);
var quotelessJson = (obj) => {
  const json = JSON.stringify(obj, null, 2);
  return json.replace(/"([^"]+)":/g, "$1:");
};
var ZodError = class extends Error {
  constructor(issues) {
    super();
    this.issues = [];
    this.addIssue = (sub) => {
      this.issues = [...this.issues, sub];
    };
    this.addIssues = (subs = []) => {
      this.issues = [...this.issues, ...subs];
    };
    const actualProto = new.target.prototype;
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      this.__proto__ = actualProto;
    }
    this.name = "ZodError";
    this.issues = issues;
  }
  get errors() {
    return this.issues;
  }
  format(_mapper) {
    const mapper = _mapper || function(issue) {
      return issue.message;
    };
    const fieldErrors = { _errors: [] };
    const processError = (error) => {
      for (const issue of error.issues) {
        if (issue.code === "invalid_union") {
          issue.unionErrors.map(processError);
        } else if (issue.code === "invalid_return_type") {
          processError(issue.returnTypeError);
        } else if (issue.code === "invalid_arguments") {
          processError(issue.argumentsError);
        } else if (issue.path.length === 0) {
          fieldErrors._errors.push(mapper(issue));
        } else {
          let curr = fieldErrors;
          let i = 0;
          while (i < issue.path.length) {
            const el = issue.path[i];
            const terminal = i === issue.path.length - 1;
            if (!terminal) {
              curr[el] = curr[el] || { _errors: [] };
            } else {
              curr[el] = curr[el] || { _errors: [] };
              curr[el]._errors.push(mapper(issue));
            }
            curr = curr[el];
            i++;
          }
        }
      }
    };
    processError(this);
    return fieldErrors;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, util.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(mapper = (issue) => issue.message) {
    const fieldErrors = {};
    const formErrors = [];
    for (const sub of this.issues) {
      if (sub.path.length > 0) {
        fieldErrors[sub.path[0]] = fieldErrors[sub.path[0]] || [];
        fieldErrors[sub.path[0]].push(mapper(sub));
      } else {
        formErrors.push(mapper(sub));
      }
    }
    return { formErrors, fieldErrors };
  }
  get formErrors() {
    return this.flatten();
  }
};
ZodError.create = (issues) => {
  const error = new ZodError(issues);
  return error;
};
var errorMap = (issue, _ctx) => {
  let message;
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = "Required";
      } else {
        message = `Expected ${issue.expected}, received ${issue.received}`;
      }
      break;
    case ZodIssueCode.invalid_literal:
      message = `Invalid literal value, expected ${JSON.stringify(issue.expected, util.jsonStringifyReplacer)}`;
      break;
    case ZodIssueCode.unrecognized_keys:
      message = `Unrecognized key(s) in object: ${util.joinValues(issue.keys, ", ")}`;
      break;
    case ZodIssueCode.invalid_union:
      message = `Invalid input`;
      break;
    case ZodIssueCode.invalid_union_discriminator:
      message = `Invalid discriminator value. Expected ${util.joinValues(issue.options)}`;
      break;
    case ZodIssueCode.invalid_enum_value:
      message = `Invalid enum value. Expected ${util.joinValues(issue.options)}, received '${issue.received}'`;
      break;
    case ZodIssueCode.invalid_arguments:
      message = `Invalid function arguments`;
      break;
    case ZodIssueCode.invalid_return_type:
      message = `Invalid function return type`;
      break;
    case ZodIssueCode.invalid_date:
      message = `Invalid date`;
      break;
    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === "object") {
        if ("includes" in issue.validation) {
          message = `Invalid input: must include "${issue.validation.includes}"`;
          if (typeof issue.validation.position === "number") {
            message = `${message} at one or more positions greater than or equal to ${issue.validation.position}`;
          }
        } else if ("startsWith" in issue.validation) {
          message = `Invalid input: must start with "${issue.validation.startsWith}"`;
        } else if ("endsWith" in issue.validation) {
          message = `Invalid input: must end with "${issue.validation.endsWith}"`;
        } else {
          util.assertNever(issue.validation);
        }
      } else if (issue.validation !== "regex") {
        message = `Invalid ${issue.validation}`;
      } else {
        message = "Invalid";
      }
      break;
    case ZodIssueCode.too_small:
      if (issue.type === "array")
        message = `Array must contain ${issue.exact ? "exactly" : issue.inclusive ? `at least` : `more than`} ${issue.minimum} element(s)`;
      else if (issue.type === "string")
        message = `String must contain ${issue.exact ? "exactly" : issue.inclusive ? `at least` : `over`} ${issue.minimum} character(s)`;
      else if (issue.type === "number")
        message = `Number must be ${issue.exact ? `exactly equal to ` : issue.inclusive ? `greater than or equal to ` : `greater than `}${issue.minimum}`;
      else if (issue.type === "date")
        message = `Date must be ${issue.exact ? `exactly equal to ` : issue.inclusive ? `greater than or equal to ` : `greater than `}${new Date(Number(issue.minimum))}`;
      else
        message = "Invalid input";
      break;
    case ZodIssueCode.too_big:
      if (issue.type === "array")
        message = `Array must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `less than`} ${issue.maximum} element(s)`;
      else if (issue.type === "string")
        message = `String must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `under`} ${issue.maximum} character(s)`;
      else if (issue.type === "number")
        message = `Number must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
      else if (issue.type === "bigint")
        message = `BigInt must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
      else if (issue.type === "date")
        message = `Date must be ${issue.exact ? `exactly` : issue.inclusive ? `smaller than or equal to` : `smaller than`} ${new Date(Number(issue.maximum))}`;
      else
        message = "Invalid input";
      break;
    case ZodIssueCode.custom:
      message = `Invalid input`;
      break;
    case ZodIssueCode.invalid_intersection_types:
      message = `Intersection results could not be merged`;
      break;
    case ZodIssueCode.not_multiple_of:
      message = `Number must be a multiple of ${issue.multipleOf}`;
      break;
    case ZodIssueCode.not_finite:
      message = "Number must be finite";
      break;
    default:
      message = _ctx.defaultError;
      util.assertNever(issue);
  }
  return { message };
};
var overrideErrorMap = errorMap;
function setErrorMap(map) {
  overrideErrorMap = map;
}
function getErrorMap() {
  return overrideErrorMap;
}
var makeIssue = (params) => {
  const { data, path, errorMaps, issueData } = params;
  const fullPath = [...path, ...issueData.path || []];
  const fullIssue = __spreadProps(__spreadValues({}, issueData), {
    path: fullPath
  });
  let errorMessage = "";
  const maps = errorMaps.filter((m) => !!m).slice().reverse();
  for (const map of maps) {
    errorMessage = map(fullIssue, { data, defaultError: errorMessage }).message;
  }
  return __spreadProps(__spreadValues({}, issueData), {
    path: fullPath,
    message: issueData.message || errorMessage
  });
};
var EMPTY_PATH = [];
function addIssueToContext(ctx, issueData) {
  const issue = makeIssue({
    issueData,
    data: ctx.data,
    path: ctx.path,
    errorMaps: [
      ctx.common.contextualErrorMap,
      ctx.schemaErrorMap,
      getErrorMap(),
      errorMap
      // then global default map
    ].filter((x) => !!x)
  });
  ctx.common.issues.push(issue);
}
var ParseStatus = class {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    if (this.value === "valid")
      this.value = "dirty";
  }
  abort() {
    if (this.value !== "aborted")
      this.value = "aborted";
  }
  static mergeArray(status, results) {
    const arrayValue = [];
    for (const s of results) {
      if (s.status === "aborted")
        return INVALID;
      if (s.status === "dirty")
        status.dirty();
      arrayValue.push(s.value);
    }
    return { status: status.value, value: arrayValue };
  }
  static mergeObjectAsync(status, pairs) {
    return __async(this, null, function* () {
      const syncPairs = [];
      for (const pair of pairs) {
        syncPairs.push({
          key: yield pair.key,
          value: yield pair.value
        });
      }
      return ParseStatus.mergeObjectSync(status, syncPairs);
    });
  }
  static mergeObjectSync(status, pairs) {
    const finalObject = {};
    for (const pair of pairs) {
      const { key, value } = pair;
      if (key.status === "aborted")
        return INVALID;
      if (value.status === "aborted")
        return INVALID;
      if (key.status === "dirty")
        status.dirty();
      if (value.status === "dirty")
        status.dirty();
      if (key.value !== "__proto__" && (typeof value.value !== "undefined" || pair.alwaysSet)) {
        finalObject[key.value] = value.value;
      }
    }
    return { status: status.value, value: finalObject };
  }
};
var INVALID = Object.freeze({
  status: "aborted"
});
var DIRTY = (value) => ({ status: "dirty", value });
var OK = (value) => ({ status: "valid", value });
var isAborted = (x) => x.status === "aborted";
var isDirty = (x) => x.status === "dirty";
var isValid = (x) => x.status === "valid";
var isAsync = (x) => typeof Promise !== "undefined" && x instanceof Promise;
var errorUtil;
(function(errorUtil2) {
  errorUtil2.errToObj = (message) => typeof message === "string" ? { message } : message || {};
  errorUtil2.toString = (message) => typeof message === "string" ? message : message === null || message === void 0 ? void 0 : message.message;
})(errorUtil || (errorUtil = {}));
var ParseInputLazyPath = class {
  constructor(parent, value, path, key) {
    this._cachedPath = [];
    this.parent = parent;
    this.data = value;
    this._path = path;
    this._key = key;
  }
  get path() {
    if (!this._cachedPath.length) {
      if (this._key instanceof Array) {
        this._cachedPath.push(...this._path, ...this._key);
      } else {
        this._cachedPath.push(...this._path, this._key);
      }
    }
    return this._cachedPath;
  }
};
var handleResult = (ctx, result) => {
  if (isValid(result)) {
    return { success: true, data: result.value };
  } else {
    if (!ctx.common.issues.length) {
      throw new Error("Validation failed but no issues detected.");
    }
    return {
      success: false,
      get error() {
        if (this._error)
          return this._error;
        const error = new ZodError(ctx.common.issues);
        this._error = error;
        return this._error;
      }
    };
  }
};
function processCreateParams(params) {
  if (!params)
    return {};
  const { errorMap: errorMap2, invalid_type_error, required_error, description } = params;
  if (errorMap2 && (invalid_type_error || required_error)) {
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  }
  if (errorMap2)
    return { errorMap: errorMap2, description };
  const customMap = (iss, ctx) => {
    if (iss.code !== "invalid_type")
      return { message: ctx.defaultError };
    if (typeof ctx.data === "undefined") {
      return { message: required_error !== null && required_error !== void 0 ? required_error : ctx.defaultError };
    }
    return { message: invalid_type_error !== null && invalid_type_error !== void 0 ? invalid_type_error : ctx.defaultError };
  };
  return { errorMap: customMap, description };
}
var ZodType = class {
  constructor(def) {
    this.spa = this.safeParseAsync;
    this._def = def;
    this.parse = this.parse.bind(this);
    this.safeParse = this.safeParse.bind(this);
    this.parseAsync = this.parseAsync.bind(this);
    this.safeParseAsync = this.safeParseAsync.bind(this);
    this.spa = this.spa.bind(this);
    this.refine = this.refine.bind(this);
    this.refinement = this.refinement.bind(this);
    this.superRefine = this.superRefine.bind(this);
    this.optional = this.optional.bind(this);
    this.nullable = this.nullable.bind(this);
    this.nullish = this.nullish.bind(this);
    this.array = this.array.bind(this);
    this.promise = this.promise.bind(this);
    this.or = this.or.bind(this);
    this.and = this.and.bind(this);
    this.transform = this.transform.bind(this);
    this.brand = this.brand.bind(this);
    this.default = this.default.bind(this);
    this.catch = this.catch.bind(this);
    this.describe = this.describe.bind(this);
    this.pipe = this.pipe.bind(this);
    this.readonly = this.readonly.bind(this);
    this.isNullable = this.isNullable.bind(this);
    this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(input) {
    return getParsedType(input.data);
  }
  _getOrReturnCtx(input, ctx) {
    return ctx || {
      common: input.parent.common,
      data: input.data,
      parsedType: getParsedType(input.data),
      schemaErrorMap: this._def.errorMap,
      path: input.path,
      parent: input.parent
    };
  }
  _processInputParams(input) {
    return {
      status: new ParseStatus(),
      ctx: {
        common: input.parent.common,
        data: input.data,
        parsedType: getParsedType(input.data),
        schemaErrorMap: this._def.errorMap,
        path: input.path,
        parent: input.parent
      }
    };
  }
  _parseSync(input) {
    const result = this._parse(input);
    if (isAsync(result)) {
      throw new Error("Synchronous parse encountered promise.");
    }
    return result;
  }
  _parseAsync(input) {
    const result = this._parse(input);
    return Promise.resolve(result);
  }
  parse(data, params) {
    const result = this.safeParse(data, params);
    if (result.success)
      return result.data;
    throw result.error;
  }
  safeParse(data, params) {
    var _a;
    const ctx = {
      common: {
        issues: [],
        async: (_a = params === null || params === void 0 ? void 0 : params.async) !== null && _a !== void 0 ? _a : false,
        contextualErrorMap: params === null || params === void 0 ? void 0 : params.errorMap
      },
      path: (params === null || params === void 0 ? void 0 : params.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data,
      parsedType: getParsedType(data)
    };
    const result = this._parseSync({ data, path: ctx.path, parent: ctx });
    return handleResult(ctx, result);
  }
  parseAsync(data, params) {
    return __async(this, null, function* () {
      const result = yield this.safeParseAsync(data, params);
      if (result.success)
        return result.data;
      throw result.error;
    });
  }
  safeParseAsync(data, params) {
    return __async(this, null, function* () {
      const ctx = {
        common: {
          issues: [],
          contextualErrorMap: params === null || params === void 0 ? void 0 : params.errorMap,
          async: true
        },
        path: (params === null || params === void 0 ? void 0 : params.path) || [],
        schemaErrorMap: this._def.errorMap,
        parent: null,
        data,
        parsedType: getParsedType(data)
      };
      const maybeAsyncResult = this._parse({ data, path: ctx.path, parent: ctx });
      const result = yield isAsync(maybeAsyncResult) ? maybeAsyncResult : Promise.resolve(maybeAsyncResult);
      return handleResult(ctx, result);
    });
  }
  refine(check, message) {
    const getIssueProperties = (val) => {
      if (typeof message === "string" || typeof message === "undefined") {
        return { message };
      } else if (typeof message === "function") {
        return message(val);
      } else {
        return message;
      }
    };
    return this._refinement((val, ctx) => {
      const result = check(val);
      const setError = () => ctx.addIssue(__spreadValues({
        code: ZodIssueCode.custom
      }, getIssueProperties(val)));
      if (typeof Promise !== "undefined" && result instanceof Promise) {
        return result.then((data) => {
          if (!data) {
            setError();
            return false;
          } else {
            return true;
          }
        });
      }
      if (!result) {
        setError();
        return false;
      } else {
        return true;
      }
    });
  }
  refinement(check, refinementData) {
    return this._refinement((val, ctx) => {
      if (!check(val)) {
        ctx.addIssue(typeof refinementData === "function" ? refinementData(val, ctx) : refinementData);
        return false;
      } else {
        return true;
      }
    });
  }
  _refinement(refinement) {
    return new ZodEffects({
      schema: this,
      typeName: ZodFirstPartyTypeKind.ZodEffects,
      effect: { type: "refinement", refinement }
    });
  }
  superRefine(refinement) {
    return this._refinement(refinement);
  }
  optional() {
    return ZodOptional.create(this, this._def);
  }
  nullable() {
    return ZodNullable.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return ZodArray.create(this, this._def);
  }
  promise() {
    return ZodPromise.create(this, this._def);
  }
  or(option) {
    return ZodUnion.create([this, option], this._def);
  }
  and(incoming) {
    return ZodIntersection.create(this, incoming, this._def);
  }
  transform(transform) {
    return new ZodEffects(__spreadProps(__spreadValues({}, processCreateParams(this._def)), {
      schema: this,
      typeName: ZodFirstPartyTypeKind.ZodEffects,
      effect: { type: "transform", transform }
    }));
  }
  default(def) {
    const defaultValueFunc = typeof def === "function" ? def : () => def;
    return new ZodDefault(__spreadProps(__spreadValues({}, processCreateParams(this._def)), {
      innerType: this,
      defaultValue: defaultValueFunc,
      typeName: ZodFirstPartyTypeKind.ZodDefault
    }));
  }
  brand() {
    return new ZodBranded(__spreadValues({
      typeName: ZodFirstPartyTypeKind.ZodBranded,
      type: this
    }, processCreateParams(this._def)));
  }
  catch(def) {
    const catchValueFunc = typeof def === "function" ? def : () => def;
    return new ZodCatch(__spreadProps(__spreadValues({}, processCreateParams(this._def)), {
      innerType: this,
      catchValue: catchValueFunc,
      typeName: ZodFirstPartyTypeKind.ZodCatch
    }));
  }
  describe(description) {
    const This = this.constructor;
    return new This(__spreadProps(__spreadValues({}, this._def), {
      description
    }));
  }
  pipe(target) {
    return ZodPipeline.create(this, target);
  }
  readonly() {
    return ZodReadonly.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
};
var cuidRegex = /^c[^\s-]{8,}$/i;
var cuid2Regex = /^[a-z][a-z0-9]*$/;
var ulidRegex = /^[0-9A-HJKMNP-TV-Z]{26}$/;
var uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
var emailRegex = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i;
var _emojiRegex = `^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$`;
var emojiRegex;
var ipv4Regex = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/;
var ipv6Regex = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/;
var datetimeRegex = (args) => {
  if (args.precision) {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${args.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${args.precision}}Z$`);
    }
  } else if (args.precision === 0) {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$`);
    }
  } else {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$`);
    }
  }
};
function isValidIP(ip, version) {
  if ((version === "v4" || !version) && ipv4Regex.test(ip)) {
    return true;
  }
  if ((version === "v6" || !version) && ipv6Regex.test(ip)) {
    return true;
  }
  return false;
}
var ZodString = class extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = String(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.string) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(
        ctx2,
        {
          code: ZodIssueCode.invalid_type,
          expected: ZodParsedType.string,
          received: ctx2.parsedType
        }
        //
      );
      return INVALID;
    }
    const status = new ParseStatus();
    let ctx = void 0;
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        if (input.data.length < check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            minimum: check.value,
            type: "string",
            inclusive: true,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        if (input.data.length > check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            maximum: check.value,
            type: "string",
            inclusive: true,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "length") {
        const tooBig = input.data.length > check.value;
        const tooSmall = input.data.length < check.value;
        if (tooBig || tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          if (tooBig) {
            addIssueToContext(ctx, {
              code: ZodIssueCode.too_big,
              maximum: check.value,
              type: "string",
              inclusive: true,
              exact: true,
              message: check.message
            });
          } else if (tooSmall) {
            addIssueToContext(ctx, {
              code: ZodIssueCode.too_small,
              minimum: check.value,
              type: "string",
              inclusive: true,
              exact: true,
              message: check.message
            });
          }
          status.dirty();
        }
      } else if (check.kind === "email") {
        if (!emailRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "email",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "emoji") {
        if (!emojiRegex) {
          emojiRegex = new RegExp(_emojiRegex, "u");
        }
        if (!emojiRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "emoji",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "uuid") {
        if (!uuidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "uuid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "cuid") {
        if (!cuidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "cuid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "cuid2") {
        if (!cuid2Regex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "cuid2",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "ulid") {
        if (!ulidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "ulid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "url") {
        try {
          new URL(input.data);
        } catch (_a) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "url",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "regex") {
        check.regex.lastIndex = 0;
        const testResult = check.regex.test(input.data);
        if (!testResult) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "regex",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "trim") {
        input.data = input.data.trim();
      } else if (check.kind === "includes") {
        if (!input.data.includes(check.value, check.position)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { includes: check.value, position: check.position },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "toLowerCase") {
        input.data = input.data.toLowerCase();
      } else if (check.kind === "toUpperCase") {
        input.data = input.data.toUpperCase();
      } else if (check.kind === "startsWith") {
        if (!input.data.startsWith(check.value)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { startsWith: check.value },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "endsWith") {
        if (!input.data.endsWith(check.value)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { endsWith: check.value },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "datetime") {
        const regex = datetimeRegex(check);
        if (!regex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: "datetime",
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "ip") {
        if (!isValidIP(input.data, check.version)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "ip",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  _regex(regex, validation, message) {
    return this.refinement((data) => regex.test(data), __spreadValues({
      validation,
      code: ZodIssueCode.invalid_string
    }, errorUtil.errToObj(message)));
  }
  _addCheck(check) {
    return new ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  email(message) {
    return this._addCheck(__spreadValues({ kind: "email" }, errorUtil.errToObj(message)));
  }
  url(message) {
    return this._addCheck(__spreadValues({ kind: "url" }, errorUtil.errToObj(message)));
  }
  emoji(message) {
    return this._addCheck(__spreadValues({ kind: "emoji" }, errorUtil.errToObj(message)));
  }
  uuid(message) {
    return this._addCheck(__spreadValues({ kind: "uuid" }, errorUtil.errToObj(message)));
  }
  cuid(message) {
    return this._addCheck(__spreadValues({ kind: "cuid" }, errorUtil.errToObj(message)));
  }
  cuid2(message) {
    return this._addCheck(__spreadValues({ kind: "cuid2" }, errorUtil.errToObj(message)));
  }
  ulid(message) {
    return this._addCheck(__spreadValues({ kind: "ulid" }, errorUtil.errToObj(message)));
  }
  ip(options) {
    return this._addCheck(__spreadValues({ kind: "ip" }, errorUtil.errToObj(options)));
  }
  datetime(options) {
    var _a;
    if (typeof options === "string") {
      return this._addCheck({
        kind: "datetime",
        precision: null,
        offset: false,
        message: options
      });
    }
    return this._addCheck(__spreadValues({
      kind: "datetime",
      precision: typeof (options === null || options === void 0 ? void 0 : options.precision) === "undefined" ? null : options === null || options === void 0 ? void 0 : options.precision,
      offset: (_a = options === null || options === void 0 ? void 0 : options.offset) !== null && _a !== void 0 ? _a : false
    }, errorUtil.errToObj(options === null || options === void 0 ? void 0 : options.message)));
  }
  regex(regex, message) {
    return this._addCheck(__spreadValues({
      kind: "regex",
      regex
    }, errorUtil.errToObj(message)));
  }
  includes(value, options) {
    return this._addCheck(__spreadValues({
      kind: "includes",
      value,
      position: options === null || options === void 0 ? void 0 : options.position
    }, errorUtil.errToObj(options === null || options === void 0 ? void 0 : options.message)));
  }
  startsWith(value, message) {
    return this._addCheck(__spreadValues({
      kind: "startsWith",
      value
    }, errorUtil.errToObj(message)));
  }
  endsWith(value, message) {
    return this._addCheck(__spreadValues({
      kind: "endsWith",
      value
    }, errorUtil.errToObj(message)));
  }
  min(minLength, message) {
    return this._addCheck(__spreadValues({
      kind: "min",
      value: minLength
    }, errorUtil.errToObj(message)));
  }
  max(maxLength, message) {
    return this._addCheck(__spreadValues({
      kind: "max",
      value: maxLength
    }, errorUtil.errToObj(message)));
  }
  length(len, message) {
    return this._addCheck(__spreadValues({
      kind: "length",
      value: len
    }, errorUtil.errToObj(message)));
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(message) {
    return this.min(1, errorUtil.errToObj(message));
  }
  trim() {
    return new ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, { kind: "trim" }]
    }));
  }
  toLowerCase() {
    return new ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    }));
  }
  toUpperCase() {
    return new ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    }));
  }
  get isDatetime() {
    return !!this._def.checks.find((ch) => ch.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((ch) => ch.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((ch) => ch.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((ch) => ch.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((ch) => ch.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((ch) => ch.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((ch) => ch.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((ch) => ch.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((ch) => ch.kind === "ip");
  }
  get minLength() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxLength() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
};
ZodString.create = (params) => {
  var _a;
  return new ZodString(__spreadValues({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodString,
    coerce: (_a = params === null || params === void 0 ? void 0 : params.coerce) !== null && _a !== void 0 ? _a : false
  }, processCreateParams(params)));
};
function floatSafeRemainder(val, step) {
  const valDecCount = (val.toString().split(".")[1] || "").length;
  const stepDecCount = (step.toString().split(".")[1] || "").length;
  const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  const valInt = parseInt(val.toFixed(decCount).replace(".", ""));
  const stepInt = parseInt(step.toFixed(decCount).replace(".", ""));
  return valInt % stepInt / Math.pow(10, decCount);
}
var ZodNumber = class extends ZodType {
  constructor() {
    super(...arguments);
    this.min = this.gte;
    this.max = this.lte;
    this.step = this.multipleOf;
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = Number(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.number) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.number,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    let ctx = void 0;
    const status = new ParseStatus();
    for (const check of this._def.checks) {
      if (check.kind === "int") {
        if (!util.isInteger(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_type,
            expected: "integer",
            received: "float",
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "min") {
        const tooSmall = check.inclusive ? input.data < check.value : input.data <= check.value;
        if (tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            minimum: check.value,
            type: "number",
            inclusive: check.inclusive,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        const tooBig = check.inclusive ? input.data > check.value : input.data >= check.value;
        if (tooBig) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            maximum: check.value,
            type: "number",
            inclusive: check.inclusive,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "multipleOf") {
        if (floatSafeRemainder(input.data, check.value) !== 0) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_multiple_of,
            multipleOf: check.value,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "finite") {
        if (!Number.isFinite(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_finite,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  gte(value, message) {
    return this.setLimit("min", value, true, errorUtil.toString(message));
  }
  gt(value, message) {
    return this.setLimit("min", value, false, errorUtil.toString(message));
  }
  lte(value, message) {
    return this.setLimit("max", value, true, errorUtil.toString(message));
  }
  lt(value, message) {
    return this.setLimit("max", value, false, errorUtil.toString(message));
  }
  setLimit(kind, value, inclusive, message) {
    return new ZodNumber(__spreadProps(__spreadValues({}, this._def), {
      checks: [
        ...this._def.checks,
        {
          kind,
          value,
          inclusive,
          message: errorUtil.toString(message)
        }
      ]
    }));
  }
  _addCheck(check) {
    return new ZodNumber(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  int(message) {
    return this._addCheck({
      kind: "int",
      message: errorUtil.toString(message)
    });
  }
  positive(message) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  negative(message) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  nonpositive(message) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  nonnegative(message) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  multipleOf(value, message) {
    return this._addCheck({
      kind: "multipleOf",
      value,
      message: errorUtil.toString(message)
    });
  }
  finite(message) {
    return this._addCheck({
      kind: "finite",
      message: errorUtil.toString(message)
    });
  }
  safe(message) {
    return this._addCheck({
      kind: "min",
      inclusive: true,
      value: Number.MIN_SAFE_INTEGER,
      message: errorUtil.toString(message)
    })._addCheck({
      kind: "max",
      inclusive: true,
      value: Number.MAX_SAFE_INTEGER,
      message: errorUtil.toString(message)
    });
  }
  get minValue() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxValue() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
  get isInt() {
    return !!this._def.checks.find((ch) => ch.kind === "int" || ch.kind === "multipleOf" && util.isInteger(ch.value));
  }
  get isFinite() {
    let max = null, min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "finite" || ch.kind === "int" || ch.kind === "multipleOf") {
        return true;
      } else if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      } else if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return Number.isFinite(min) && Number.isFinite(max);
  }
};
ZodNumber.create = (params) => {
  return new ZodNumber(__spreadValues({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodNumber,
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false
  }, processCreateParams(params)));
};
var ZodBigInt = class extends ZodType {
  constructor() {
    super(...arguments);
    this.min = this.gte;
    this.max = this.lte;
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = BigInt(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.bigint) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.bigint,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    let ctx = void 0;
    const status = new ParseStatus();
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        const tooSmall = check.inclusive ? input.data < check.value : input.data <= check.value;
        if (tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            type: "bigint",
            minimum: check.value,
            inclusive: check.inclusive,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        const tooBig = check.inclusive ? input.data > check.value : input.data >= check.value;
        if (tooBig) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            type: "bigint",
            maximum: check.value,
            inclusive: check.inclusive,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "multipleOf") {
        if (input.data % check.value !== BigInt(0)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_multiple_of,
            multipleOf: check.value,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  gte(value, message) {
    return this.setLimit("min", value, true, errorUtil.toString(message));
  }
  gt(value, message) {
    return this.setLimit("min", value, false, errorUtil.toString(message));
  }
  lte(value, message) {
    return this.setLimit("max", value, true, errorUtil.toString(message));
  }
  lt(value, message) {
    return this.setLimit("max", value, false, errorUtil.toString(message));
  }
  setLimit(kind, value, inclusive, message) {
    return new ZodBigInt(__spreadProps(__spreadValues({}, this._def), {
      checks: [
        ...this._def.checks,
        {
          kind,
          value,
          inclusive,
          message: errorUtil.toString(message)
        }
      ]
    }));
  }
  _addCheck(check) {
    return new ZodBigInt(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  positive(message) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  negative(message) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  nonpositive(message) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  nonnegative(message) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  multipleOf(value, message) {
    return this._addCheck({
      kind: "multipleOf",
      value,
      message: errorUtil.toString(message)
    });
  }
  get minValue() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min;
  }
  get maxValue() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max;
  }
};
ZodBigInt.create = (params) => {
  var _a;
  return new ZodBigInt(__spreadValues({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodBigInt,
    coerce: (_a = params === null || params === void 0 ? void 0 : params.coerce) !== null && _a !== void 0 ? _a : false
  }, processCreateParams(params)));
};
var ZodBoolean = class extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = Boolean(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.boolean) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.boolean,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodBoolean.create = (params) => {
  return new ZodBoolean(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodBoolean,
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false
  }, processCreateParams(params)));
};
var ZodDate = class extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = new Date(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.date) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.date,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    if (isNaN(input.data.getTime())) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_date
      });
      return INVALID;
    }
    const status = new ParseStatus();
    let ctx = void 0;
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        if (input.data.getTime() < check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            message: check.message,
            inclusive: true,
            exact: false,
            minimum: check.value,
            type: "date"
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        if (input.data.getTime() > check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            message: check.message,
            inclusive: true,
            exact: false,
            maximum: check.value,
            type: "date"
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return {
      status: status.value,
      value: new Date(input.data.getTime())
    };
  }
  _addCheck(check) {
    return new ZodDate(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  min(minDate, message) {
    return this._addCheck({
      kind: "min",
      value: minDate.getTime(),
      message: errorUtil.toString(message)
    });
  }
  max(maxDate, message) {
    return this._addCheck({
      kind: "max",
      value: maxDate.getTime(),
      message: errorUtil.toString(message)
    });
  }
  get minDate() {
    let min = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min === null || ch.value > min)
          min = ch.value;
      }
    }
    return min != null ? new Date(min) : null;
  }
  get maxDate() {
    let max = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max === null || ch.value < max)
          max = ch.value;
      }
    }
    return max != null ? new Date(max) : null;
  }
};
ZodDate.create = (params) => {
  return new ZodDate(__spreadValues({
    checks: [],
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false,
    typeName: ZodFirstPartyTypeKind.ZodDate
  }, processCreateParams(params)));
};
var ZodSymbol = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.symbol) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.symbol,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodSymbol.create = (params) => {
  return new ZodSymbol(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodSymbol
  }, processCreateParams(params)));
};
var ZodUndefined = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.undefined) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.undefined,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodUndefined.create = (params) => {
  return new ZodUndefined(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodUndefined
  }, processCreateParams(params)));
};
var ZodNull = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.null) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.null,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodNull.create = (params) => {
  return new ZodNull(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodNull
  }, processCreateParams(params)));
};
var ZodAny = class extends ZodType {
  constructor() {
    super(...arguments);
    this._any = true;
  }
  _parse(input) {
    return OK(input.data);
  }
};
ZodAny.create = (params) => {
  return new ZodAny(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodAny
  }, processCreateParams(params)));
};
var ZodUnknown = class extends ZodType {
  constructor() {
    super(...arguments);
    this._unknown = true;
  }
  _parse(input) {
    return OK(input.data);
  }
};
ZodUnknown.create = (params) => {
  return new ZodUnknown(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodUnknown
  }, processCreateParams(params)));
};
var ZodNever = class extends ZodType {
  _parse(input) {
    const ctx = this._getOrReturnCtx(input);
    addIssueToContext(ctx, {
      code: ZodIssueCode.invalid_type,
      expected: ZodParsedType.never,
      received: ctx.parsedType
    });
    return INVALID;
  }
};
ZodNever.create = (params) => {
  return new ZodNever(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodNever
  }, processCreateParams(params)));
};
var ZodVoid = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.undefined) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.void,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodVoid.create = (params) => {
  return new ZodVoid(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodVoid
  }, processCreateParams(params)));
};
var ZodArray = class extends ZodType {
  _parse(input) {
    const { ctx, status } = this._processInputParams(input);
    const def = this._def;
    if (ctx.parsedType !== ZodParsedType.array) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.array,
        received: ctx.parsedType
      });
      return INVALID;
    }
    if (def.exactLength !== null) {
      const tooBig = ctx.data.length > def.exactLength.value;
      const tooSmall = ctx.data.length < def.exactLength.value;
      if (tooBig || tooSmall) {
        addIssueToContext(ctx, {
          code: tooBig ? ZodIssueCode.too_big : ZodIssueCode.too_small,
          minimum: tooSmall ? def.exactLength.value : void 0,
          maximum: tooBig ? def.exactLength.value : void 0,
          type: "array",
          inclusive: true,
          exact: true,
          message: def.exactLength.message
        });
        status.dirty();
      }
    }
    if (def.minLength !== null) {
      if (ctx.data.length < def.minLength.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_small,
          minimum: def.minLength.value,
          type: "array",
          inclusive: true,
          exact: false,
          message: def.minLength.message
        });
        status.dirty();
      }
    }
    if (def.maxLength !== null) {
      if (ctx.data.length > def.maxLength.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_big,
          maximum: def.maxLength.value,
          type: "array",
          inclusive: true,
          exact: false,
          message: def.maxLength.message
        });
        status.dirty();
      }
    }
    if (ctx.common.async) {
      return Promise.all([...ctx.data].map((item, i) => {
        return def.type._parseAsync(new ParseInputLazyPath(ctx, item, ctx.path, i));
      })).then((result2) => {
        return ParseStatus.mergeArray(status, result2);
      });
    }
    const result = [...ctx.data].map((item, i) => {
      return def.type._parseSync(new ParseInputLazyPath(ctx, item, ctx.path, i));
    });
    return ParseStatus.mergeArray(status, result);
  }
  get element() {
    return this._def.type;
  }
  min(minLength, message) {
    return new ZodArray(__spreadProps(__spreadValues({}, this._def), {
      minLength: { value: minLength, message: errorUtil.toString(message) }
    }));
  }
  max(maxLength, message) {
    return new ZodArray(__spreadProps(__spreadValues({}, this._def), {
      maxLength: { value: maxLength, message: errorUtil.toString(message) }
    }));
  }
  length(len, message) {
    return new ZodArray(__spreadProps(__spreadValues({}, this._def), {
      exactLength: { value: len, message: errorUtil.toString(message) }
    }));
  }
  nonempty(message) {
    return this.min(1, message);
  }
};
ZodArray.create = (schema, params) => {
  return new ZodArray(__spreadValues({
    type: schema,
    minLength: null,
    maxLength: null,
    exactLength: null,
    typeName: ZodFirstPartyTypeKind.ZodArray
  }, processCreateParams(params)));
};
function deepPartialify(schema) {
  if (schema instanceof ZodObject) {
    const newShape = {};
    for (const key in schema.shape) {
      const fieldSchema = schema.shape[key];
      newShape[key] = ZodOptional.create(deepPartialify(fieldSchema));
    }
    return new ZodObject(__spreadProps(__spreadValues({}, schema._def), {
      shape: () => newShape
    }));
  } else if (schema instanceof ZodArray) {
    return new ZodArray(__spreadProps(__spreadValues({}, schema._def), {
      type: deepPartialify(schema.element)
    }));
  } else if (schema instanceof ZodOptional) {
    return ZodOptional.create(deepPartialify(schema.unwrap()));
  } else if (schema instanceof ZodNullable) {
    return ZodNullable.create(deepPartialify(schema.unwrap()));
  } else if (schema instanceof ZodTuple) {
    return ZodTuple.create(schema.items.map((item) => deepPartialify(item)));
  } else {
    return schema;
  }
}
var ZodObject = class extends ZodType {
  constructor() {
    super(...arguments);
    this._cached = null;
    this.nonstrict = this.passthrough;
    this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const shape = this._def.shape();
    const keys = util.objectKeys(shape);
    return this._cached = { shape, keys };
  }
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.object) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    const { status, ctx } = this._processInputParams(input);
    const { shape, keys: shapeKeys } = this._getCached();
    const extraKeys = [];
    if (!(this._def.catchall instanceof ZodNever && this._def.unknownKeys === "strip")) {
      for (const key in ctx.data) {
        if (!shapeKeys.includes(key)) {
          extraKeys.push(key);
        }
      }
    }
    const pairs = [];
    for (const key of shapeKeys) {
      const keyValidator = shape[key];
      const value = ctx.data[key];
      pairs.push({
        key: { status: "valid", value: key },
        value: keyValidator._parse(new ParseInputLazyPath(ctx, value, ctx.path, key)),
        alwaysSet: key in ctx.data
      });
    }
    if (this._def.catchall instanceof ZodNever) {
      const unknownKeys = this._def.unknownKeys;
      if (unknownKeys === "passthrough") {
        for (const key of extraKeys) {
          pairs.push({
            key: { status: "valid", value: key },
            value: { status: "valid", value: ctx.data[key] }
          });
        }
      } else if (unknownKeys === "strict") {
        if (extraKeys.length > 0) {
          addIssueToContext(ctx, {
            code: ZodIssueCode.unrecognized_keys,
            keys: extraKeys
          });
          status.dirty();
        }
      } else if (unknownKeys === "strip")
        ;
      else {
        throw new Error(`Internal ZodObject error: invalid unknownKeys value.`);
      }
    } else {
      const catchall = this._def.catchall;
      for (const key of extraKeys) {
        const value = ctx.data[key];
        pairs.push({
          key: { status: "valid", value: key },
          value: catchall._parse(
            new ParseInputLazyPath(ctx, value, ctx.path, key)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: key in ctx.data
        });
      }
    }
    if (ctx.common.async) {
      return Promise.resolve().then(() => __async(this, null, function* () {
        const syncPairs = [];
        for (const pair of pairs) {
          const key = yield pair.key;
          syncPairs.push({
            key,
            value: yield pair.value,
            alwaysSet: pair.alwaysSet
          });
        }
        return syncPairs;
      })).then((syncPairs) => {
        return ParseStatus.mergeObjectSync(status, syncPairs);
      });
    } else {
      return ParseStatus.mergeObjectSync(status, pairs);
    }
  }
  get shape() {
    return this._def.shape();
  }
  strict(message) {
    errorUtil.errToObj;
    return new ZodObject(__spreadValues(__spreadProps(__spreadValues({}, this._def), {
      unknownKeys: "strict"
    }), message !== void 0 ? {
      errorMap: (issue, ctx) => {
        var _a, _b, _c, _d;
        const defaultError = (_c = (_b = (_a = this._def).errorMap) === null || _b === void 0 ? void 0 : _b.call(_a, issue, ctx).message) !== null && _c !== void 0 ? _c : ctx.defaultError;
        if (issue.code === "unrecognized_keys")
          return {
            message: (_d = errorUtil.errToObj(message).message) !== null && _d !== void 0 ? _d : defaultError
          };
        return {
          message: defaultError
        };
      }
    } : {}));
  }
  strip() {
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      unknownKeys: "strip"
    }));
  }
  passthrough() {
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      unknownKeys: "passthrough"
    }));
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(augmentation) {
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => __spreadValues(__spreadValues({}, this._def.shape()), augmentation)
    }));
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(merging) {
    const merged = new ZodObject({
      unknownKeys: merging._def.unknownKeys,
      catchall: merging._def.catchall,
      shape: () => __spreadValues(__spreadValues({}, this._def.shape()), merging._def.shape()),
      typeName: ZodFirstPartyTypeKind.ZodObject
    });
    return merged;
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(key, schema) {
    return this.augment({ [key]: schema });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(index) {
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      catchall: index
    }));
  }
  pick(mask) {
    const shape = {};
    util.objectKeys(mask).forEach((key) => {
      if (mask[key] && this.shape[key]) {
        shape[key] = this.shape[key];
      }
    });
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => shape
    }));
  }
  omit(mask) {
    const shape = {};
    util.objectKeys(this.shape).forEach((key) => {
      if (!mask[key]) {
        shape[key] = this.shape[key];
      }
    });
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => shape
    }));
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return deepPartialify(this);
  }
  partial(mask) {
    const newShape = {};
    util.objectKeys(this.shape).forEach((key) => {
      const fieldSchema = this.shape[key];
      if (mask && !mask[key]) {
        newShape[key] = fieldSchema;
      } else {
        newShape[key] = fieldSchema.optional();
      }
    });
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => newShape
    }));
  }
  required(mask) {
    const newShape = {};
    util.objectKeys(this.shape).forEach((key) => {
      if (mask && !mask[key]) {
        newShape[key] = this.shape[key];
      } else {
        const fieldSchema = this.shape[key];
        let newField = fieldSchema;
        while (newField instanceof ZodOptional) {
          newField = newField._def.innerType;
        }
        newShape[key] = newField;
      }
    });
    return new ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => newShape
    }));
  }
  keyof() {
    return createZodEnum(util.objectKeys(this.shape));
  }
};
ZodObject.create = (shape, params) => {
  return new ZodObject(__spreadValues({
    shape: () => shape,
    unknownKeys: "strip",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject
  }, processCreateParams(params)));
};
ZodObject.strictCreate = (shape, params) => {
  return new ZodObject(__spreadValues({
    shape: () => shape,
    unknownKeys: "strict",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject
  }, processCreateParams(params)));
};
ZodObject.lazycreate = (shape, params) => {
  return new ZodObject(__spreadValues({
    shape,
    unknownKeys: "strip",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject
  }, processCreateParams(params)));
};
var ZodUnion = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const options = this._def.options;
    function handleResults(results) {
      for (const result of results) {
        if (result.result.status === "valid") {
          return result.result;
        }
      }
      for (const result of results) {
        if (result.result.status === "dirty") {
          ctx.common.issues.push(...result.ctx.common.issues);
          return result.result;
        }
      }
      const unionErrors = results.map((result) => new ZodError(result.ctx.common.issues));
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union,
        unionErrors
      });
      return INVALID;
    }
    if (ctx.common.async) {
      return Promise.all(options.map((option) => __async(this, null, function* () {
        const childCtx = __spreadProps(__spreadValues({}, ctx), {
          common: __spreadProps(__spreadValues({}, ctx.common), {
            issues: []
          }),
          parent: null
        });
        return {
          result: yield option._parseAsync({
            data: ctx.data,
            path: ctx.path,
            parent: childCtx
          }),
          ctx: childCtx
        };
      }))).then(handleResults);
    } else {
      let dirty = void 0;
      const issues = [];
      for (const option of options) {
        const childCtx = __spreadProps(__spreadValues({}, ctx), {
          common: __spreadProps(__spreadValues({}, ctx.common), {
            issues: []
          }),
          parent: null
        });
        const result = option._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: childCtx
        });
        if (result.status === "valid") {
          return result;
        } else if (result.status === "dirty" && !dirty) {
          dirty = { result, ctx: childCtx };
        }
        if (childCtx.common.issues.length) {
          issues.push(childCtx.common.issues);
        }
      }
      if (dirty) {
        ctx.common.issues.push(...dirty.ctx.common.issues);
        return dirty.result;
      }
      const unionErrors = issues.map((issues2) => new ZodError(issues2));
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union,
        unionErrors
      });
      return INVALID;
    }
  }
  get options() {
    return this._def.options;
  }
};
ZodUnion.create = (types, params) => {
  return new ZodUnion(__spreadValues({
    options: types,
    typeName: ZodFirstPartyTypeKind.ZodUnion
  }, processCreateParams(params)));
};
var getDiscriminator = (type) => {
  if (type instanceof ZodLazy) {
    return getDiscriminator(type.schema);
  } else if (type instanceof ZodEffects) {
    return getDiscriminator(type.innerType());
  } else if (type instanceof ZodLiteral) {
    return [type.value];
  } else if (type instanceof ZodEnum) {
    return type.options;
  } else if (type instanceof ZodNativeEnum) {
    return Object.keys(type.enum);
  } else if (type instanceof ZodDefault) {
    return getDiscriminator(type._def.innerType);
  } else if (type instanceof ZodUndefined) {
    return [void 0];
  } else if (type instanceof ZodNull) {
    return [null];
  } else {
    return null;
  }
};
var ZodDiscriminatedUnion = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.object) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const discriminator = this.discriminator;
    const discriminatorValue = ctx.data[discriminator];
    const option = this.optionsMap.get(discriminatorValue);
    if (!option) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union_discriminator,
        options: Array.from(this.optionsMap.keys()),
        path: [discriminator]
      });
      return INVALID;
    }
    if (ctx.common.async) {
      return option._parseAsync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
    } else {
      return option._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
    }
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(discriminator, options, params) {
    const optionsMap = /* @__PURE__ */ new Map();
    for (const type of options) {
      const discriminatorValues = getDiscriminator(type.shape[discriminator]);
      if (!discriminatorValues) {
        throw new Error(`A discriminator value for key \`${discriminator}\` could not be extracted from all schema options`);
      }
      for (const value of discriminatorValues) {
        if (optionsMap.has(value)) {
          throw new Error(`Discriminator property ${String(discriminator)} has duplicate value ${String(value)}`);
        }
        optionsMap.set(value, type);
      }
    }
    return new ZodDiscriminatedUnion(__spreadValues({
      typeName: ZodFirstPartyTypeKind.ZodDiscriminatedUnion,
      discriminator,
      options,
      optionsMap
    }, processCreateParams(params)));
  }
};
function mergeValues(a, b) {
  const aType = getParsedType(a);
  const bType = getParsedType(b);
  if (a === b) {
    return { valid: true, data: a };
  } else if (aType === ZodParsedType.object && bType === ZodParsedType.object) {
    const bKeys = util.objectKeys(b);
    const sharedKeys = util.objectKeys(a).filter((key) => bKeys.indexOf(key) !== -1);
    const newObj = __spreadValues(__spreadValues({}, a), b);
    for (const key of sharedKeys) {
      const sharedValue = mergeValues(a[key], b[key]);
      if (!sharedValue.valid) {
        return { valid: false };
      }
      newObj[key] = sharedValue.data;
    }
    return { valid: true, data: newObj };
  } else if (aType === ZodParsedType.array && bType === ZodParsedType.array) {
    if (a.length !== b.length) {
      return { valid: false };
    }
    const newArray = [];
    for (let index = 0; index < a.length; index++) {
      const itemA = a[index];
      const itemB = b[index];
      const sharedValue = mergeValues(itemA, itemB);
      if (!sharedValue.valid) {
        return { valid: false };
      }
      newArray.push(sharedValue.data);
    }
    return { valid: true, data: newArray };
  } else if (aType === ZodParsedType.date && bType === ZodParsedType.date && +a === +b) {
    return { valid: true, data: a };
  } else {
    return { valid: false };
  }
}
var ZodIntersection = class extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    const handleParsed = (parsedLeft, parsedRight) => {
      if (isAborted(parsedLeft) || isAborted(parsedRight)) {
        return INVALID;
      }
      const merged = mergeValues(parsedLeft.value, parsedRight.value);
      if (!merged.valid) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.invalid_intersection_types
        });
        return INVALID;
      }
      if (isDirty(parsedLeft) || isDirty(parsedRight)) {
        status.dirty();
      }
      return { status: status.value, value: merged.data };
    };
    if (ctx.common.async) {
      return Promise.all([
        this._def.left._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        }),
        this._def.right._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        })
      ]).then(([left, right]) => handleParsed(left, right));
    } else {
      return handleParsed(this._def.left._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      }), this._def.right._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      }));
    }
  }
};
ZodIntersection.create = (left, right, params) => {
  return new ZodIntersection(__spreadValues({
    left,
    right,
    typeName: ZodFirstPartyTypeKind.ZodIntersection
  }, processCreateParams(params)));
};
var ZodTuple = class extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.array) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.array,
        received: ctx.parsedType
      });
      return INVALID;
    }
    if (ctx.data.length < this._def.items.length) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.too_small,
        minimum: this._def.items.length,
        inclusive: true,
        exact: false,
        type: "array"
      });
      return INVALID;
    }
    const rest = this._def.rest;
    if (!rest && ctx.data.length > this._def.items.length) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.too_big,
        maximum: this._def.items.length,
        inclusive: true,
        exact: false,
        type: "array"
      });
      status.dirty();
    }
    const items = [...ctx.data].map((item, itemIndex) => {
      const schema = this._def.items[itemIndex] || this._def.rest;
      if (!schema)
        return null;
      return schema._parse(new ParseInputLazyPath(ctx, item, ctx.path, itemIndex));
    }).filter((x) => !!x);
    if (ctx.common.async) {
      return Promise.all(items).then((results) => {
        return ParseStatus.mergeArray(status, results);
      });
    } else {
      return ParseStatus.mergeArray(status, items);
    }
  }
  get items() {
    return this._def.items;
  }
  rest(rest) {
    return new ZodTuple(__spreadProps(__spreadValues({}, this._def), {
      rest
    }));
  }
};
ZodTuple.create = (schemas, params) => {
  if (!Array.isArray(schemas)) {
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  }
  return new ZodTuple(__spreadValues({
    items: schemas,
    typeName: ZodFirstPartyTypeKind.ZodTuple,
    rest: null
  }, processCreateParams(params)));
};
var ZodRecord = class extends ZodType {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.object) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const pairs = [];
    const keyType = this._def.keyType;
    const valueType = this._def.valueType;
    for (const key in ctx.data) {
      pairs.push({
        key: keyType._parse(new ParseInputLazyPath(ctx, key, ctx.path, key)),
        value: valueType._parse(new ParseInputLazyPath(ctx, ctx.data[key], ctx.path, key))
      });
    }
    if (ctx.common.async) {
      return ParseStatus.mergeObjectAsync(status, pairs);
    } else {
      return ParseStatus.mergeObjectSync(status, pairs);
    }
  }
  get element() {
    return this._def.valueType;
  }
  static create(first, second, third) {
    if (second instanceof ZodType) {
      return new ZodRecord(__spreadValues({
        keyType: first,
        valueType: second,
        typeName: ZodFirstPartyTypeKind.ZodRecord
      }, processCreateParams(third)));
    }
    return new ZodRecord(__spreadValues({
      keyType: ZodString.create(),
      valueType: first,
      typeName: ZodFirstPartyTypeKind.ZodRecord
    }, processCreateParams(second)));
  }
};
var ZodMap = class extends ZodType {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.map) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.map,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const keyType = this._def.keyType;
    const valueType = this._def.valueType;
    const pairs = [...ctx.data.entries()].map(([key, value], index) => {
      return {
        key: keyType._parse(new ParseInputLazyPath(ctx, key, ctx.path, [index, "key"])),
        value: valueType._parse(new ParseInputLazyPath(ctx, value, ctx.path, [index, "value"]))
      };
    });
    if (ctx.common.async) {
      const finalMap = /* @__PURE__ */ new Map();
      return Promise.resolve().then(() => __async(this, null, function* () {
        for (const pair of pairs) {
          const key = yield pair.key;
          const value = yield pair.value;
          if (key.status === "aborted" || value.status === "aborted") {
            return INVALID;
          }
          if (key.status === "dirty" || value.status === "dirty") {
            status.dirty();
          }
          finalMap.set(key.value, value.value);
        }
        return { status: status.value, value: finalMap };
      }));
    } else {
      const finalMap = /* @__PURE__ */ new Map();
      for (const pair of pairs) {
        const key = pair.key;
        const value = pair.value;
        if (key.status === "aborted" || value.status === "aborted") {
          return INVALID;
        }
        if (key.status === "dirty" || value.status === "dirty") {
          status.dirty();
        }
        finalMap.set(key.value, value.value);
      }
      return { status: status.value, value: finalMap };
    }
  }
};
ZodMap.create = (keyType, valueType, params) => {
  return new ZodMap(__spreadValues({
    valueType,
    keyType,
    typeName: ZodFirstPartyTypeKind.ZodMap
  }, processCreateParams(params)));
};
var ZodSet = class extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.set) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.set,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const def = this._def;
    if (def.minSize !== null) {
      if (ctx.data.size < def.minSize.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_small,
          minimum: def.minSize.value,
          type: "set",
          inclusive: true,
          exact: false,
          message: def.minSize.message
        });
        status.dirty();
      }
    }
    if (def.maxSize !== null) {
      if (ctx.data.size > def.maxSize.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_big,
          maximum: def.maxSize.value,
          type: "set",
          inclusive: true,
          exact: false,
          message: def.maxSize.message
        });
        status.dirty();
      }
    }
    const valueType = this._def.valueType;
    function finalizeSet(elements2) {
      const parsedSet = /* @__PURE__ */ new Set();
      for (const element of elements2) {
        if (element.status === "aborted")
          return INVALID;
        if (element.status === "dirty")
          status.dirty();
        parsedSet.add(element.value);
      }
      return { status: status.value, value: parsedSet };
    }
    const elements = [...ctx.data.values()].map((item, i) => valueType._parse(new ParseInputLazyPath(ctx, item, ctx.path, i)));
    if (ctx.common.async) {
      return Promise.all(elements).then((elements2) => finalizeSet(elements2));
    } else {
      return finalizeSet(elements);
    }
  }
  min(minSize, message) {
    return new ZodSet(__spreadProps(__spreadValues({}, this._def), {
      minSize: { value: minSize, message: errorUtil.toString(message) }
    }));
  }
  max(maxSize, message) {
    return new ZodSet(__spreadProps(__spreadValues({}, this._def), {
      maxSize: { value: maxSize, message: errorUtil.toString(message) }
    }));
  }
  size(size, message) {
    return this.min(size, message).max(size, message);
  }
  nonempty(message) {
    return this.min(1, message);
  }
};
ZodSet.create = (valueType, params) => {
  return new ZodSet(__spreadValues({
    valueType,
    minSize: null,
    maxSize: null,
    typeName: ZodFirstPartyTypeKind.ZodSet
  }, processCreateParams(params)));
};
var ZodFunction = class extends ZodType {
  constructor() {
    super(...arguments);
    this.validate = this.implement;
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.function) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.function,
        received: ctx.parsedType
      });
      return INVALID;
    }
    function makeArgsIssue(args, error) {
      return makeIssue({
        data: args,
        path: ctx.path,
        errorMaps: [
          ctx.common.contextualErrorMap,
          ctx.schemaErrorMap,
          getErrorMap(),
          errorMap
        ].filter((x) => !!x),
        issueData: {
          code: ZodIssueCode.invalid_arguments,
          argumentsError: error
        }
      });
    }
    function makeReturnsIssue(returns, error) {
      return makeIssue({
        data: returns,
        path: ctx.path,
        errorMaps: [
          ctx.common.contextualErrorMap,
          ctx.schemaErrorMap,
          getErrorMap(),
          errorMap
        ].filter((x) => !!x),
        issueData: {
          code: ZodIssueCode.invalid_return_type,
          returnTypeError: error
        }
      });
    }
    const params = { errorMap: ctx.common.contextualErrorMap };
    const fn = ctx.data;
    if (this._def.returns instanceof ZodPromise) {
      const me = this;
      return OK(function(...args) {
        return __async(this, null, function* () {
          const error = new ZodError([]);
          const parsedArgs = yield me._def.args.parseAsync(args, params).catch((e) => {
            error.addIssue(makeArgsIssue(args, e));
            throw error;
          });
          const result = yield Reflect.apply(fn, this, parsedArgs);
          const parsedReturns = yield me._def.returns._def.type.parseAsync(result, params).catch((e) => {
            error.addIssue(makeReturnsIssue(result, e));
            throw error;
          });
          return parsedReturns;
        });
      });
    } else {
      const me = this;
      return OK(function(...args) {
        const parsedArgs = me._def.args.safeParse(args, params);
        if (!parsedArgs.success) {
          throw new ZodError([makeArgsIssue(args, parsedArgs.error)]);
        }
        const result = Reflect.apply(fn, this, parsedArgs.data);
        const parsedReturns = me._def.returns.safeParse(result, params);
        if (!parsedReturns.success) {
          throw new ZodError([makeReturnsIssue(result, parsedReturns.error)]);
        }
        return parsedReturns.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...items) {
    return new ZodFunction(__spreadProps(__spreadValues({}, this._def), {
      args: ZodTuple.create(items).rest(ZodUnknown.create())
    }));
  }
  returns(returnType) {
    return new ZodFunction(__spreadProps(__spreadValues({}, this._def), {
      returns: returnType
    }));
  }
  implement(func) {
    const validatedFunc = this.parse(func);
    return validatedFunc;
  }
  strictImplement(func) {
    const validatedFunc = this.parse(func);
    return validatedFunc;
  }
  static create(args, returns, params) {
    return new ZodFunction(__spreadValues({
      args: args ? args : ZodTuple.create([]).rest(ZodUnknown.create()),
      returns: returns || ZodUnknown.create(),
      typeName: ZodFirstPartyTypeKind.ZodFunction
    }, processCreateParams(params)));
  }
};
var ZodLazy = class extends ZodType {
  get schema() {
    return this._def.getter();
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const lazySchema = this._def.getter();
    return lazySchema._parse({ data: ctx.data, path: ctx.path, parent: ctx });
  }
};
ZodLazy.create = (getter, params) => {
  return new ZodLazy(__spreadValues({
    getter,
    typeName: ZodFirstPartyTypeKind.ZodLazy
  }, processCreateParams(params)));
};
var ZodLiteral = class extends ZodType {
  _parse(input) {
    if (input.data !== this._def.value) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_literal,
        expected: this._def.value
      });
      return INVALID;
    }
    return { status: "valid", value: input.data };
  }
  get value() {
    return this._def.value;
  }
};
ZodLiteral.create = (value, params) => {
  return new ZodLiteral(__spreadValues({
    value,
    typeName: ZodFirstPartyTypeKind.ZodLiteral
  }, processCreateParams(params)));
};
function createZodEnum(values, params) {
  return new ZodEnum(__spreadValues({
    values,
    typeName: ZodFirstPartyTypeKind.ZodEnum
  }, processCreateParams(params)));
}
var ZodEnum = class extends ZodType {
  _parse(input) {
    if (typeof input.data !== "string") {
      const ctx = this._getOrReturnCtx(input);
      const expectedValues = this._def.values;
      addIssueToContext(ctx, {
        expected: util.joinValues(expectedValues),
        received: ctx.parsedType,
        code: ZodIssueCode.invalid_type
      });
      return INVALID;
    }
    if (this._def.values.indexOf(input.data) === -1) {
      const ctx = this._getOrReturnCtx(input);
      const expectedValues = this._def.values;
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_enum_value,
        options: expectedValues
      });
      return INVALID;
    }
    return OK(input.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  get Values() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  get Enum() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  extract(values) {
    return ZodEnum.create(values);
  }
  exclude(values) {
    return ZodEnum.create(this.options.filter((opt) => !values.includes(opt)));
  }
};
ZodEnum.create = createZodEnum;
var ZodNativeEnum = class extends ZodType {
  _parse(input) {
    const nativeEnumValues = util.getValidEnumValues(this._def.values);
    const ctx = this._getOrReturnCtx(input);
    if (ctx.parsedType !== ZodParsedType.string && ctx.parsedType !== ZodParsedType.number) {
      const expectedValues = util.objectValues(nativeEnumValues);
      addIssueToContext(ctx, {
        expected: util.joinValues(expectedValues),
        received: ctx.parsedType,
        code: ZodIssueCode.invalid_type
      });
      return INVALID;
    }
    if (nativeEnumValues.indexOf(input.data) === -1) {
      const expectedValues = util.objectValues(nativeEnumValues);
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_enum_value,
        options: expectedValues
      });
      return INVALID;
    }
    return OK(input.data);
  }
  get enum() {
    return this._def.values;
  }
};
ZodNativeEnum.create = (values, params) => {
  return new ZodNativeEnum(__spreadValues({
    values,
    typeName: ZodFirstPartyTypeKind.ZodNativeEnum
  }, processCreateParams(params)));
};
var ZodPromise = class extends ZodType {
  unwrap() {
    return this._def.type;
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.promise && ctx.common.async === false) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.promise,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const promisified = ctx.parsedType === ZodParsedType.promise ? ctx.data : Promise.resolve(ctx.data);
    return OK(promisified.then((data) => {
      return this._def.type.parseAsync(data, {
        path: ctx.path,
        errorMap: ctx.common.contextualErrorMap
      });
    }));
  }
};
ZodPromise.create = (schema, params) => {
  return new ZodPromise(__spreadValues({
    type: schema,
    typeName: ZodFirstPartyTypeKind.ZodPromise
  }, processCreateParams(params)));
};
var ZodEffects = class extends ZodType {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === ZodFirstPartyTypeKind.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    const effect = this._def.effect || null;
    const checkCtx = {
      addIssue: (arg) => {
        addIssueToContext(ctx, arg);
        if (arg.fatal) {
          status.abort();
        } else {
          status.dirty();
        }
      },
      get path() {
        return ctx.path;
      }
    };
    checkCtx.addIssue = checkCtx.addIssue.bind(checkCtx);
    if (effect.type === "preprocess") {
      const processed = effect.transform(ctx.data, checkCtx);
      if (ctx.common.issues.length) {
        return {
          status: "dirty",
          value: ctx.data
        };
      }
      if (ctx.common.async) {
        return Promise.resolve(processed).then((processed2) => {
          return this._def.schema._parseAsync({
            data: processed2,
            path: ctx.path,
            parent: ctx
          });
        });
      } else {
        return this._def.schema._parseSync({
          data: processed,
          path: ctx.path,
          parent: ctx
        });
      }
    }
    if (effect.type === "refinement") {
      const executeRefinement = (acc) => {
        const result = effect.refinement(acc, checkCtx);
        if (ctx.common.async) {
          return Promise.resolve(result);
        }
        if (result instanceof Promise) {
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        }
        return acc;
      };
      if (ctx.common.async === false) {
        const inner = this._def.schema._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (inner.status === "aborted")
          return INVALID;
        if (inner.status === "dirty")
          status.dirty();
        executeRefinement(inner.value);
        return { status: status.value, value: inner.value };
      } else {
        return this._def.schema._parseAsync({ data: ctx.data, path: ctx.path, parent: ctx }).then((inner) => {
          if (inner.status === "aborted")
            return INVALID;
          if (inner.status === "dirty")
            status.dirty();
          return executeRefinement(inner.value).then(() => {
            return { status: status.value, value: inner.value };
          });
        });
      }
    }
    if (effect.type === "transform") {
      if (ctx.common.async === false) {
        const base = this._def.schema._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (!isValid(base))
          return base;
        const result = effect.transform(base.value, checkCtx);
        if (result instanceof Promise) {
          throw new Error(`Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.`);
        }
        return { status: status.value, value: result };
      } else {
        return this._def.schema._parseAsync({ data: ctx.data, path: ctx.path, parent: ctx }).then((base) => {
          if (!isValid(base))
            return base;
          return Promise.resolve(effect.transform(base.value, checkCtx)).then((result) => ({ status: status.value, value: result }));
        });
      }
    }
    util.assertNever(effect);
  }
};
ZodEffects.create = (schema, effect, params) => {
  return new ZodEffects(__spreadValues({
    schema,
    typeName: ZodFirstPartyTypeKind.ZodEffects,
    effect
  }, processCreateParams(params)));
};
ZodEffects.createWithPreprocess = (preprocess, schema, params) => {
  return new ZodEffects(__spreadValues({
    schema,
    effect: { type: "preprocess", transform: preprocess },
    typeName: ZodFirstPartyTypeKind.ZodEffects
  }, processCreateParams(params)));
};
var ZodOptional = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType === ZodParsedType.undefined) {
      return OK(void 0);
    }
    return this._def.innerType._parse(input);
  }
  unwrap() {
    return this._def.innerType;
  }
};
ZodOptional.create = (type, params) => {
  return new ZodOptional(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodOptional
  }, processCreateParams(params)));
};
var ZodNullable = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType === ZodParsedType.null) {
      return OK(null);
    }
    return this._def.innerType._parse(input);
  }
  unwrap() {
    return this._def.innerType;
  }
};
ZodNullable.create = (type, params) => {
  return new ZodNullable(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodNullable
  }, processCreateParams(params)));
};
var ZodDefault = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    let data = ctx.data;
    if (ctx.parsedType === ZodParsedType.undefined) {
      data = this._def.defaultValue();
    }
    return this._def.innerType._parse({
      data,
      path: ctx.path,
      parent: ctx
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
};
ZodDefault.create = (type, params) => {
  return new ZodDefault(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodDefault,
    defaultValue: typeof params.default === "function" ? params.default : () => params.default
  }, processCreateParams(params)));
};
var ZodCatch = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const newCtx = __spreadProps(__spreadValues({}, ctx), {
      common: __spreadProps(__spreadValues({}, ctx.common), {
        issues: []
      })
    });
    const result = this._def.innerType._parse({
      data: newCtx.data,
      path: newCtx.path,
      parent: __spreadValues({}, newCtx)
    });
    if (isAsync(result)) {
      return result.then((result2) => {
        return {
          status: "valid",
          value: result2.status === "valid" ? result2.value : this._def.catchValue({
            get error() {
              return new ZodError(newCtx.common.issues);
            },
            input: newCtx.data
          })
        };
      });
    } else {
      return {
        status: "valid",
        value: result.status === "valid" ? result.value : this._def.catchValue({
          get error() {
            return new ZodError(newCtx.common.issues);
          },
          input: newCtx.data
        })
      };
    }
  }
  removeCatch() {
    return this._def.innerType;
  }
};
ZodCatch.create = (type, params) => {
  return new ZodCatch(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodCatch,
    catchValue: typeof params.catch === "function" ? params.catch : () => params.catch
  }, processCreateParams(params)));
};
var ZodNaN = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.nan) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.nan,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return { status: "valid", value: input.data };
  }
};
ZodNaN.create = (params) => {
  return new ZodNaN(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodNaN
  }, processCreateParams(params)));
};
var BRAND = Symbol("zod_brand");
var ZodBranded = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const data = ctx.data;
    return this._def.type._parse({
      data,
      path: ctx.path,
      parent: ctx
    });
  }
  unwrap() {
    return this._def.type;
  }
};
var ZodPipeline = class extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.common.async) {
      const handleAsync = () => __async(this, null, function* () {
        const inResult = yield this._def.in._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (inResult.status === "aborted")
          return INVALID;
        if (inResult.status === "dirty") {
          status.dirty();
          return DIRTY(inResult.value);
        } else {
          return this._def.out._parseAsync({
            data: inResult.value,
            path: ctx.path,
            parent: ctx
          });
        }
      });
      return handleAsync();
    } else {
      const inResult = this._def.in._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
      if (inResult.status === "aborted")
        return INVALID;
      if (inResult.status === "dirty") {
        status.dirty();
        return {
          status: "dirty",
          value: inResult.value
        };
      } else {
        return this._def.out._parseSync({
          data: inResult.value,
          path: ctx.path,
          parent: ctx
        });
      }
    }
  }
  static create(a, b) {
    return new ZodPipeline({
      in: a,
      out: b,
      typeName: ZodFirstPartyTypeKind.ZodPipeline
    });
  }
};
var ZodReadonly = class extends ZodType {
  _parse(input) {
    const result = this._def.innerType._parse(input);
    if (isValid(result)) {
      result.value = Object.freeze(result.value);
    }
    return result;
  }
};
ZodReadonly.create = (type, params) => {
  return new ZodReadonly(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodReadonly
  }, processCreateParams(params)));
};
var custom = (check, params = {}, fatal) => {
  if (check)
    return ZodAny.create().superRefine((data, ctx) => {
      var _a, _b;
      if (!check(data)) {
        const p = typeof params === "function" ? params(data) : typeof params === "string" ? { message: params } : params;
        const _fatal = (_b = (_a = p.fatal) !== null && _a !== void 0 ? _a : fatal) !== null && _b !== void 0 ? _b : true;
        const p2 = typeof p === "string" ? { message: p } : p;
        ctx.addIssue(__spreadProps(__spreadValues({ code: "custom" }, p2), { fatal: _fatal }));
      }
    });
  return ZodAny.create();
};
var late = {
  object: ZodObject.lazycreate
};
var ZodFirstPartyTypeKind;
(function(ZodFirstPartyTypeKind2) {
  ZodFirstPartyTypeKind2["ZodString"] = "ZodString";
  ZodFirstPartyTypeKind2["ZodNumber"] = "ZodNumber";
  ZodFirstPartyTypeKind2["ZodNaN"] = "ZodNaN";
  ZodFirstPartyTypeKind2["ZodBigInt"] = "ZodBigInt";
  ZodFirstPartyTypeKind2["ZodBoolean"] = "ZodBoolean";
  ZodFirstPartyTypeKind2["ZodDate"] = "ZodDate";
  ZodFirstPartyTypeKind2["ZodSymbol"] = "ZodSymbol";
  ZodFirstPartyTypeKind2["ZodUndefined"] = "ZodUndefined";
  ZodFirstPartyTypeKind2["ZodNull"] = "ZodNull";
  ZodFirstPartyTypeKind2["ZodAny"] = "ZodAny";
  ZodFirstPartyTypeKind2["ZodUnknown"] = "ZodUnknown";
  ZodFirstPartyTypeKind2["ZodNever"] = "ZodNever";
  ZodFirstPartyTypeKind2["ZodVoid"] = "ZodVoid";
  ZodFirstPartyTypeKind2["ZodArray"] = "ZodArray";
  ZodFirstPartyTypeKind2["ZodObject"] = "ZodObject";
  ZodFirstPartyTypeKind2["ZodUnion"] = "ZodUnion";
  ZodFirstPartyTypeKind2["ZodDiscriminatedUnion"] = "ZodDiscriminatedUnion";
  ZodFirstPartyTypeKind2["ZodIntersection"] = "ZodIntersection";
  ZodFirstPartyTypeKind2["ZodTuple"] = "ZodTuple";
  ZodFirstPartyTypeKind2["ZodRecord"] = "ZodRecord";
  ZodFirstPartyTypeKind2["ZodMap"] = "ZodMap";
  ZodFirstPartyTypeKind2["ZodSet"] = "ZodSet";
  ZodFirstPartyTypeKind2["ZodFunction"] = "ZodFunction";
  ZodFirstPartyTypeKind2["ZodLazy"] = "ZodLazy";
  ZodFirstPartyTypeKind2["ZodLiteral"] = "ZodLiteral";
  ZodFirstPartyTypeKind2["ZodEnum"] = "ZodEnum";
  ZodFirstPartyTypeKind2["ZodEffects"] = "ZodEffects";
  ZodFirstPartyTypeKind2["ZodNativeEnum"] = "ZodNativeEnum";
  ZodFirstPartyTypeKind2["ZodOptional"] = "ZodOptional";
  ZodFirstPartyTypeKind2["ZodNullable"] = "ZodNullable";
  ZodFirstPartyTypeKind2["ZodDefault"] = "ZodDefault";
  ZodFirstPartyTypeKind2["ZodCatch"] = "ZodCatch";
  ZodFirstPartyTypeKind2["ZodPromise"] = "ZodPromise";
  ZodFirstPartyTypeKind2["ZodBranded"] = "ZodBranded";
  ZodFirstPartyTypeKind2["ZodPipeline"] = "ZodPipeline";
  ZodFirstPartyTypeKind2["ZodReadonly"] = "ZodReadonly";
})(ZodFirstPartyTypeKind || (ZodFirstPartyTypeKind = {}));
var instanceOfType = (cls, params = {
  message: `Input not instance of ${cls.name}`
}) => custom((data) => data instanceof cls, params);
var stringType = ZodString.create;
var numberType = ZodNumber.create;
var nanType = ZodNaN.create;
var bigIntType = ZodBigInt.create;
var booleanType = ZodBoolean.create;
var dateType = ZodDate.create;
var symbolType = ZodSymbol.create;
var undefinedType = ZodUndefined.create;
var nullType = ZodNull.create;
var anyType = ZodAny.create;
var unknownType = ZodUnknown.create;
var neverType = ZodNever.create;
var voidType = ZodVoid.create;
var arrayType = ZodArray.create;
var objectType = ZodObject.create;
var strictObjectType = ZodObject.strictCreate;
var unionType = ZodUnion.create;
var discriminatedUnionType = ZodDiscriminatedUnion.create;
var intersectionType = ZodIntersection.create;
var tupleType = ZodTuple.create;
var recordType = ZodRecord.create;
var mapType = ZodMap.create;
var setType = ZodSet.create;
var functionType = ZodFunction.create;
var lazyType = ZodLazy.create;
var literalType = ZodLiteral.create;
var enumType = ZodEnum.create;
var nativeEnumType = ZodNativeEnum.create;
var promiseType = ZodPromise.create;
var effectsType = ZodEffects.create;
var optionalType = ZodOptional.create;
var nullableType = ZodNullable.create;
var preprocessType = ZodEffects.createWithPreprocess;
var pipelineType = ZodPipeline.create;
var ostring = () => stringType().optional();
var onumber = () => numberType().optional();
var oboolean = () => booleanType().optional();
var coerce = {
  string: (arg) => ZodString.create(__spreadProps(__spreadValues({}, arg), { coerce: true })),
  number: (arg) => ZodNumber.create(__spreadProps(__spreadValues({}, arg), { coerce: true })),
  boolean: (arg) => ZodBoolean.create(__spreadProps(__spreadValues({}, arg), {
    coerce: true
  })),
  bigint: (arg) => ZodBigInt.create(__spreadProps(__spreadValues({}, arg), { coerce: true })),
  date: (arg) => ZodDate.create(__spreadProps(__spreadValues({}, arg), { coerce: true }))
};
var NEVER = INVALID;
var z = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: errorMap,
  setErrorMap,
  getErrorMap,
  makeIssue,
  EMPTY_PATH,
  addIssueToContext,
  ParseStatus,
  INVALID,
  DIRTY,
  OK,
  isAborted,
  isDirty,
  isValid,
  isAsync,
  get util() {
    return util;
  },
  get objectUtil() {
    return objectUtil;
  },
  ZodParsedType,
  getParsedType,
  ZodType,
  ZodString,
  ZodNumber,
  ZodBigInt,
  ZodBoolean,
  ZodDate,
  ZodSymbol,
  ZodUndefined,
  ZodNull,
  ZodAny,
  ZodUnknown,
  ZodNever,
  ZodVoid,
  ZodArray,
  ZodObject,
  ZodUnion,
  ZodDiscriminatedUnion,
  ZodIntersection,
  ZodTuple,
  ZodRecord,
  ZodMap,
  ZodSet,
  ZodFunction,
  ZodLazy,
  ZodLiteral,
  ZodEnum,
  ZodNativeEnum,
  ZodPromise,
  ZodEffects,
  ZodTransformer: ZodEffects,
  ZodOptional,
  ZodNullable,
  ZodDefault,
  ZodCatch,
  ZodNaN,
  BRAND,
  ZodBranded,
  ZodPipeline,
  ZodReadonly,
  custom,
  Schema: ZodType,
  ZodSchema: ZodType,
  late,
  get ZodFirstPartyTypeKind() {
    return ZodFirstPartyTypeKind;
  },
  coerce,
  any: anyType,
  array: arrayType,
  bigint: bigIntType,
  boolean: booleanType,
  date: dateType,
  discriminatedUnion: discriminatedUnionType,
  effect: effectsType,
  "enum": enumType,
  "function": functionType,
  "instanceof": instanceOfType,
  intersection: intersectionType,
  lazy: lazyType,
  literal: literalType,
  map: mapType,
  nan: nanType,
  nativeEnum: nativeEnumType,
  never: neverType,
  "null": nullType,
  nullable: nullableType,
  number: numberType,
  object: objectType,
  oboolean,
  onumber,
  optional: optionalType,
  ostring,
  pipeline: pipelineType,
  preprocess: preprocessType,
  promise: promiseType,
  record: recordType,
  set: setType,
  strictObject: strictObjectType,
  string: stringType,
  symbol: symbolType,
  transformer: effectsType,
  tuple: tupleType,
  "undefined": undefinedType,
  union: unionType,
  unknown: unknownType,
  "void": voidType,
  NEVER,
  ZodIssueCode,
  quotelessJson,
  ZodError
});

// src/v4/api/locations.models.ts
var deliveryCoverageResponse = z.object({
  /** Can the location deliver to this address? */
  can_deliver: z.boolean(),
  /** Error message to show if delivery is unavailable. */
  message: z.nullable(z.string())
});
var locationOperatingHours = z.object({
  /** ISO8601 datestamp when the hours were created. */
  created_at: z.string(),
  /** Day that these hours apply to. */
  day_of_week: z.string(),
  /** ISO8601 datestamp when the location closes. */
  end: z.string(),
  /** ID for this set of operating hours. */
  id: z.string(),
  /** ISO8601 datestamp when the location opens. */
  start: z.string(),
  /** ID of the location these hours apply to. */
  store_location_id: z.number(),
  /**
   * The type of operating hours, such as delivery, pickup, or curbside.
   * @see https://github.com/koala-labs/koala-ordering-api/blob/production/core/Ordering/Models/LocationHour.php#L25-L30
   * @TODO make this an enum?
   */
  type: z.string(),
  /** ISO8601 datestamp when the hours were last updated. */
  updated_at: z.string()
});
var locationCachedData = z.object({
  /** The ordering provider's ID for this location. Maps to `location_id`. */
  id: z.string(),
  /** Location zip code. */
  zip: z.string(),
  /** Location city. */
  city: z.string(),
  /** @TODO improve this type. */
  meta: z.unknown(),
  /** @TODO what is this value? */
  tags: z.string().array(),
  /** Location name. */
  label: z.string(),
  /** Location state or province. */
  state: z.string(),
  /** Country code that the location is in. Eg: `USA`. */
  country: z.string(),
  /** Location latitude. */
  latitude: z.number(),
  /** Location longitude. */
  longitude: z.number(),
  /** Can this location be ordered from? */
  is_available: z.boolean(),
  /** Location phone number. */
  phone_number: z.string().nullable(),
  /** Street address of the location. */
  street_address: z.string(),
  /** UTC offset representing the location's time zone. */
  utc_offset: z.number(),
  /** Accepted credit card types. @TODO expand this type. */
  card_types: z.object({ label: z.string() }).passthrough().array()
});
var locationPositionAttribute = z.object({
  id: z.number(),
  label: z.string(),
  description: z.string(),
  type: z.string(),
  accessor: z.string(),
  required: z.boolean()
});
var location = z.object({
  /** Misc. custom location info. Eg: a Grubhub link. */
  attributes: z.optional(
    z.object({
      id: z.optional(z.number()),
      label: z.unknown(),
      value: z.unknown(),
      position: z.optional(locationPositionAttribute)
    }).passthrough().array()
  ),
  /** Max length of special instructions added during checkout? (if supported) */
  basket_instructions_max_length: z.nullable(z.number()),
  /** Brands can set this ID in the ordering provider to match PoS ID, etc. */
  brand_id: z.optional(z.nullable(z.string())),
  /** Accepted credit card types. @TODO expand this type. */
  card_types: z.object({ label: z.string() }).array(),
  /** ISO8601 datestamp when this location was created. */
  created_at: z.string().optional(),
  /** @TODO reduce our reliance on this property. */
  cached_data: z.optional(locationCachedData.passthrough()),
  /** Location city. */
  city: z.string(),
  /** Country code that the location is in. Eg: `USA`. */
  country: z.string(),
  /** The cross street value added to addresses */
  cross_street: z.nullable(z.string()),
  /** ISO8601 datestamp when this location was deleted. */
  deleted_at: z.optional(z.nullable(z.string())),
  /** Hours that this location will deliver. */
  delivery_hours: z.optional(locationOperatingHours.passthrough().array()),
  /** Minimum order amount that is eligible for delivery. */
  delivery_minimum: z.nullable(z.number()),
  /** @TODO improve this type. */
  disclaimers: z.object({ name: z.string(), description: z.string() }).passthrough().array(),
  /** The location's distance in miles from the user's selected address. */
  distance_miles: z.optional(z.number()),
  /** Koala Location ID. */
  id: z.number(),
  /** @TODO where do these images display? */
  image_url_1_by_1: z.optional(z.nullable(z.string())),
  /** Does this location only support delivery conveyance? */
  is_delivery_only: z.optional(z.boolean()),
  /** Label to use when adding a recipient. Eg: "Name" or "Customer". */
  item_recipient_label: z.nullable(z.string()),
  /** Location name. */
  label: z.string(),
  /** Location latitude. */
  latitude: z.number(),
  /** The ordering provider's ID for this location. */
  location_id: z.optional(z.string()),
  /** Location longitude. */
  longitude: z.number(),
  /**
   * Hours that Online ordering available.
   * (for now `business_hours` will be the same as the `operating_hours` for Olo and Square locations.)
   */
  operating_hours: z.optional(locationOperatingHours.passthrough().array()),
  /** Hours that this location is open. (Chowly locations will have only `business_hours`)*/
  business_hours: z.optional(locationOperatingHours.passthrough().array()),
  /** Does this location support ordering ahead? */
  order_ahead: z.boolean(),
  /** If ordering ahead is supported, how many days can you order ahead? */
  order_ahead_days: z.number(),
  /** Does this location support ordering ASAP? */
  order_asap: z.boolean(),
  /** ID of the organization that this location belongs to. */
  organization_id: z.number(),
  /** Location phone number. */
  phone_number: z.string().nullable(),
  /** Is the location enabled in the ordering provider? */
  provider_enabled: z.optional(z.boolean()),
  /** Location URL slug. */
  slug: z.string().nullable(),
  /** Maximum number of characters for special order instructions. */
  special_instructions_max_length: z.number(),
  /** The state's foreign key to the US States database table. */
  state_id: z.optional(z.number()),
  /**
   * Current location status.
   * @see https://github.com/koala-labs/koala-ordering-api/blob/production/app/Status.php#L44-L53
   * @TODO make this into an enum?
   */
  status_id: z.optional(z.number()),
  /** Street address of the location. */
  street_address: z.string(),
  /** Can special instructions be added to an order during checkout? */
  supports_basket_instructions: z.boolean(),
  /** Does this location support curbside pickup? */
  supports_curbside: z.boolean(),
  /** Does this location support delivery conveyance? */
  supports_delivery: z.boolean(),
  /** Does this location support dine-in conveyance? */
  supports_dine_in: z.boolean(),
  /** Does this location support drive-in conveyance? */
  supports_drive_in: z.boolean(),
  /** Does this location support table numbers? */
  supports_dine_in_table_number: z.boolean(),
  /** Does this location support drive-thru conveyance? */
  supports_drive_thru: z.boolean(),
  /** Can individual items be marked for people in the customer's party? */
  supports_item_recipients: z.boolean(),
  /** Does this location only support delivery conveyance? */
  supports_pick_up: z.boolean(),
  /** Can payment be split between multiple methods? */
  supports_split_payment: z.boolean(),
  /** Does this location support tipping during checkout? */
  supports_tip: z.boolean(),
  /** Does this location support dine-in for an online order? */
  supports_to_stay: z.boolean(),
  /** Can you request utensils from this location during checkout? */
  supports_utensils: z.boolean(),
  /** Flag indicates if promo code form should be supported during checkout */
  supports_promo_code: z.boolean(),
  /**
   * ISO8601 datestamp when this location's menu was last synced.
   * @TODO should we be using `synced_at` or `last_synced_at`?
   */
  synced_at: z.optional(z.string()),
  /** ISO8601 datestamp when this location was last updated. */
  updated_at: z.optional(z.string()),
  /** Location zip code. */
  zip_code: z.optional(z.string())
});

// src/v4/api/menu.models.ts
var CALS_IDENTIFIER = "Cals";
var menuProductOption = z.lazy(
  () => z.object({
    /** Allergens associated with this option. */
    allergens: z.optional(allergenItem.passthrough().array()),
    /** Visual separator for the calorie range. Eg: `-`.  */
    calories_separator: z.string().nullable(),
    /** The number of calories for this option. */
    calories: z.number().nullable(),
    /** Option cost. */
    cost: z.number(),
    /** Option description. */
    description: z.optional(z.nullable(z.string())),
    /** Can this option be selected? */
    enabled: z.optional(z.boolean()),
    /** The Option's ID across all locations. */
    global_option_id: z.string(),
    /** Option ID unique to a single location. */
    id: z.string(),
    /** Display images for this option. */
    images: z.nullable(menuProductImages.passthrough()),
    /** Is the option currently available for purchase? */
    is_available: z.boolean(),
    /** Is this the default option for its group? */
    is_default: z.boolean(),
    /**
     * If a product is inverted, then options are pre-selected and the
     * product is customized by removing options.
     */
    is_inverted: z.optional(z.boolean()),
    /** Warning labels for this option. */
    labels: menuProductLabel.passthrough().array(),
    /** Max calories for this option. */
    max_calories: z.nullable(z.number()),
    /** Alternative name for inverted products. Eg: `Remove {x}` */
    name_inverted: z.optional(z.nullable(z.string())),
    /** Option name. */
    name: z.string(),
    /** Any sub-groups that this option contains. */
    option_groups: menuProductOptionGroup.passthrough().array(),
    /** How many of this option has the user selected? */
    quantity: z.optional(z.number()),
    /** Is this option selected? */
    selected: z.optional(z.boolean()),
    is_max_selections_reached: z.optional(z.boolean()),
    contains_adv_nested_modifiers: z.optional(z.boolean())
  })
);
var allergenItem = z.object({
  /** Allergen ID. */
  id: z.number(),
  /** Name. Eg: "Tree Nut Allergy" */
  label: z.string(),
  /** @TODO what does this property mean? */
  is_removal: z.boolean()
}).passthrough();
var existingOption = z.object({
  /** Option ID. */
  id: z.string(),
  /** Option quantity. */
  quantity: z.number(),
  cost: z.optional(z.number()),
  /** The Option's ID across all locations. */
  global_option_id: z.optional(z.string()),
  /** Option name. */
  name: z.optional(z.string())
});
var tagItem = z.object({
  /** @TODO what is this value? */
  background_color: z.optional(z.string()),
  /** @TODO what is this value? */
  icon_image_url: z.optional(z.nullable(z.string())),
  /** Category ID. */
  id: z.number(),
  /** Category name. */
  label: z.string(),
  /** ID of the organization this belongs to. */
  organization_id: z.optional(z.number()),
  /** @TODO what is this value? */
  text_color: z.optional(z.string()),
  /** Marketing tags are visual additions, but filter tags change how the menu is displayed. */
  type: z.union([z.literal("marketing"), z.literal("filter")]),
  /** This property is responsible for suppressing the label value
   * from the marketing tag rendered on the menu and leads to surfacing only the image.
   */
  hide_label: z.boolean()
}).passthrough();
var menuProductImages = z.object({
  image_url_1_by_1: z.nullable(z.string())
});
var menuProductLabel = z.object({
  /** Full legal warning text. */
  description: z.string(),
  /** Alt text for the label image. */
  image_description: z.string(),
  /** Warning label image URL. */
  image_url: z.string(),
  /** Name of the warning. Eg: `Sodium - NY`. */
  name: z.string()
});
var menuProductOptionGroup = z.object({
  /** If quantity selection is enabled, how much should it increment? */
  choice_quantity_increment: z.number(),
  /* Text description. */
  description: z.string(),
  /** @TODO how does this differ from `description`? */
  explanation_text: z.nullable(z.string()),
  /** The Group's ID across all locations. */
  global_id: z.string(),
  /** Group ID unique to a single location. */
  id: z.string(),
  /** Is this group hidden? */
  is_hidden: z.boolean(),
  /**
   * Total combined quantity of options that can be selected in this group.
   * Eg: Dipping sauces. Make 30 selections from up to 2 options.
   */
  max_aggregate_quantity: z.nullable(z.number()),
  /** The maximum selectable quantity for any one option. */
  max_choice_quantity: z.nullable(z.number()),
  /** The maximum number of selections that can be made from this group. */
  max_selections: z.nullable(z.number()),
  /** Minimum combined quantity of options that can be selected in this group.  */
  min_aggregate_quantity: z.nullable(z.number()),
  /** Minimum valid option quantity. */
  min_choice_quantity: z.nullable(z.number()),
  /** The minimum required selections from this group. */
  min_selections: z.nullable(z.number()),
  /** The customization options in this group. */
  options: menuProductOption.array(),
  /** Global ID of the parent product or option. */
  parent_global_id: z.optional(z.nullable(z.string())),
  /** Is an option from this group required for the product to be valid? */
  requires_user_selection: z.boolean(),
  /** Can multiples of an option be selected? */
  supports_quantity_selection: z.boolean(),
  /** Is the current selection state valid? */
  valid: z.optional(z.boolean()),
  /** When sum of all option quantities are equal to greater than max_aggregate_quantity this property is set to true and the opposite */
  is_max_aggregate_quantity_reached: z.optional(z.boolean()),
  /** Contains current sum of all option quantities of the current option group*/
  total_aggregate_quantity: z.optional(z.number()),
  /** If the group is options of advanced nested modifiers */
  is_adv_nested_modifier: z.optional(z.boolean())
});
var menuProductResponse = z.object({
  /** Allergens associated with this product. */
  allergens: allergenItem.passthrough().array(),
  /** Visual separator for the calorie range. Eg: `-`.  */
  calories_separator: z.string().nullable(),
  /** Calories for the base product. */
  calories: z.nullable(z.number()),
  /** Cost for the base product. */
  cost: z.number(),
  /** Text description. */
  description: z.string(),
  /** The master category that this product belongs to. */
  filter_tags: z.nullable(tagItem.passthrough().array()),
  /** Total calories for the product plus all customizations. */
  final_calories: z.optional(z.number()),
  /** Total cost for the product plus all customizations. */
  final_cost: z.optional(z.number()),
  /** The Product's ID across all locations. */
  global_id: z.string(),
  /** Product ID unique to a single location. */
  id: z.string(),
  /** Main product image. */
  image: z.nullable(z.string()),
  /** Full set of product images in multiple aspect ratios. */
  images: menuProductImages.passthrough(),
  /** Warning labels for this product. */
  labels: menuProductLabel.passthrough().array(),
  /** A list of tags associated with this product. */
  marketing_tags: z.nullable(tagItem.passthrough().array()),
  /** Max calories for customizable options. */
  max_calories: z.nullable(z.number()),
  /** Maximum quantity that can be ordered. */
  max_quantity: z.nullable(z.number()),
  /** @TODO what is this value? */
  meta_data: z.nullable(z.record(z.unknown())),
  /** Product name. */
  name: z.string(),
  /** @TODO what is this value? */
  options: z.optional(existingOption.passthrough().array()),
  /** Customizable product options. */
  option_groups: menuProductOptionGroup.passthrough().array(),
  /** Visible option groups added by the Customizer. @TODO remove from this model. */
  surfaceable_option_groups: z.optional(
    menuProductOptionGroup.passthrough().array()
  ),
  /** Are all selected customizations valid? */
  valid_customization: z.optional(z.boolean())
});
var categoryResponse = z.object({
  /** Text description of the category's products. */
  description: z.string(),
  /** The Product's ID across all locations. */
  global_id: z.nullable(z.string()),
  /** @TODO what is this value? */
  icon: z.null(),
  /**
   * Category ID.
   * @TODO determine why this could be either a string or a number.
   */
  id: z.union([z.string(), z.number()]),
  /** Should this category be hidden from users? */
  is_hidden: z.boolean(),
  /** Category name. Eg: `Sandwiches`. */
  name: z.string(),
  /** Products in this category. */
  products: menuProductResponse.passthrough().array(),
  /** Category name. Eg: `Sandwiches`. @TODO duplicate of `name`? */
  type: z.string().nullable()
});
var menuResponse = z.object({
  categories: categoryResponse.passthrough().array()
});
var menuProduct = menuProductResponse.extend({
  /** Min and max calories for customizable products. Eg: `300-700 cals`. */
  calorie_range: z.string(),
  /** Calorie units label. Eg: `Cals`. */
  calsIdentifier: z.string(),
  /** Can this product be customized? */
  customizable: z.boolean(),
  /** Options supported by this product. */
  options: existingOption.passthrough().array(),
  /** Formatted number of calories. Eg: `700 cals`. */
  pretty_calories: z.string(),
  /** Formatted price. Eg: `$12.99`. */
  pretty_price: z.nullable(z.string()),
  /** Warning labels for this product. */
  warningLabels: z.optional(menuProductLabel.passthrough().array())
});
var category = categoryResponse.extend({
  products: menuProduct.passthrough().array()
});
var menu = menuResponse.extend({
  categories: category.passthrough().array()
});
var menuProductWithCategory = menuProduct.extend({
  category
});

// src/v4/api/payment.models.ts
var PAYMENT_TYPES = /* @__PURE__ */ ((PAYMENT_TYPES2) => {
  PAYMENT_TYPES2["CREDIT_CARD"] = "credit_card";
  PAYMENT_TYPES2["CREDIT_CARD_TOKEN"] = "credit_card_token";
  PAYMENT_TYPES2["CASH"] = "cash";
  PAYMENT_TYPES2["STORED_VALUE"] = "stored_value";
  PAYMENT_TYPES2["BILLING_ACCOUNT"] = "billing_account";
  PAYMENT_TYPES2["DIGITAL_WALLET"] = "digital_wallet";
  return PAYMENT_TYPES2;
})(PAYMENT_TYPES || {});
var PAYMENT_METHODS = z.enum([
  "cash",
  "prepaid",
  "billing_account",
  "digital_wallet",
  "credit_card",
  "credit_card_token",
  "stored_value"
]);
var payment = z.object({
  customer: z.object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    // Matches a 10-digit string.
    contact_number: z.string().regex(/^\d{10}$/)
  }),
  amount: z.number(),
  tip: z.optional(z.number())
});
var billingAccount = payment.extend({
  type: z.literal(PAYMENT_METHODS.enum.billing_account),
  saved_account_id: z.string()
});
var cash = payment.extend({
  type: z.literal(PAYMENT_METHODS.enum.cash)
});
var creditCard = payment.extend({
  type: z.literal(PAYMENT_METHODS.enum.credit_card),
  id: z.string(),
  alias_cc: z.string(),
  alias_cvv: z.string(),
  card_brand: z.string(),
  last_four: z.string(),
  expiry_month: z.number(),
  expiry_year: z.number(),
  zip_code: z.string(),
  country: z.string(),
  save: z.boolean()
});
var creditCardToken = payment.extend({
  type: z.literal(PAYMENT_METHODS.enum.credit_card_token),
  card_token: z.string(),
  card_brand: z.string(),
  last_four: z.string(),
  expiry_month: z.number(),
  expiry_year: z.number(),
  zip_code: z.string(),
  country: z.string(),
  save: z.boolean()
});
var digitalWallet = payment.extend({
  type: z.literal(PAYMENT_METHODS.enum.digital_wallet),
  digital_wallet_id: z.number(),
  card_token: z.string(),
  card_brand: z.string(),
  // Matches a 4-digit string.
  last_four: z.string().regex(/^\d{4}$/),
  expiry_month: z.number(),
  expiry_year: z.number(),
  zip_code: z.string(),
  country: z.string(),
  save: z.boolean()
});
var giftCard = payment.extend({
  type: z.literal(PAYMENT_METHODS.enum.stored_value),
  stored_value_requirements: z.object({
    /** Could be something like "pin" or "card_number". */
    name: z.nullable(z.string()),
    /** Value for corresponding name */
    value: z.nullable(z.string())
  }).array()
});
var supportedPaymentMethods = z.discriminatedUnion("type", [
  billingAccount,
  cash,
  creditCard,
  creditCardToken,
  digitalWallet,
  giftCard
]);
var customerDetails = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  contact_number: z.string(),
  /** Should the receipt be emailed to the user? */
  email_receipt: z.boolean().optional(),
  opt_in: z.boolean().optional()
});
var creditCardBase = z.object({
  /** Tokenized card number that the API can use to submit a charge. */
  alias_cc: z.nullable(z.string()).optional(),
  /** Tokenized card CVV. (the 3/4 digit number) */
  alias_cvv: z.nullable(z.string()).optional(),
  /** Credit card type. Eg: "VIS" */
  payment_method: z.nullable(z.string()).optional(),
  /** Year that the card expires. */
  expiry_year: z.nullable(z.number()).optional(),
  /** Month that the card expires. */
  expiry_month: z.nullable(z.number()).optional(),
  /** Payment country code. Eg: "US" */
  country: z.nullable(z.string()).optional(),
  /** Last 4 digits of the credit card. Eg: "x-4242" */
  last_four: z.nullable(z.string()).optional()
});
var tokenizedCreditCard = creditCardBase.extend({
  /** Masked card number. Eg: "411111xxxxxx1111". */
  masked_card: z.string().optional()
});
var storedValueRequirement = z.object({
  /** Could be something like "pin" or "card_number". */
  name: z.nullable(z.string()),
  /** Value for corresponding name */
  value: z.nullable(z.string())
});
var paymentDetails = tokenizedCreditCard.extend({
  id: z.nullable(z.string()).optional(),
  /** Id that should be set from paymentTypesResponse.supported_digital_wallets,
   * depending on what digital wallet type is available,
   * used for authorizing payment.
   */
  digital_wallet_id: z.number().optional(),
  /** Token that is getting exchanged for card data, used for authorizing payment. */
  card_token: z.nullable(z.string()).optional(),
  /** Total payment, excluding tip. */
  amount: z.number().optional(),
  /** Credit Card brand. */
  card_brand: z.string().optional(),
  /** Details about the customer who submitted the order. */
  customer: customerDetails.passthrough().optional(),
  /** @TODO what is this value? */
  payment_location_id: z.string().optional(),
  /** Is this payment method saved? */
  save: z.boolean().optional(),
  /** @TODO what is this value? */
  /** @TODO is it really number? */
  saved_account_id: z.nullable(z.string()).optional(),
  /** @TODO what is this value? */
  stored_value_number: z.nullable(z.number()).optional(),
  /** @TODO what is this value? */
  stored_value_pin: z.nullable(z.number()).optional(),
  /**
   * Collection of StoredValueRequirement
   * Required if type === PAYMENT_TYPE.billing_account
   */
  stored_value_requirements: storedValueRequirement.passthrough().array().optional(),
  /** Tip amount. */
  tip: z.number().optional(),
  /** Payment type, such as cash, or card. */
  type: z.nativeEnum(PAYMENT_TYPES),
  /** Billing zip code. */
  zip_code: z.string().optional()
});

// src/v4/api/basket.models.ts
var storedAddress = z.object({
  /** Desired delivery day. @TODO how is this formatted? */
  day_wanted: z.optional(z.string()),
  /** Desired delivery time. @TODO how is this formatted? */
  time_wanted: z.nullable(z.string()),
  street_address: z.string(),
  street_address_2: z.string(),
  city: z.string(),
  state: z.optional(z.nullable(z.string())),
  zip_code: z.string(),
  /** Special delivery instructions for this address, such as a door code. */
  special_instructions: z.string(),
  /** Address ID. */
  id: z.number(),
  /** Is this the user's default address? */
  default: z.boolean()
});
var deliveryAddress = z.object({
  /** Desired delivery time. @TODO how is this formatted? */
  time_wanted: z.nullable(z.string()),
  time_wanted_mode: z.enum(["asap", "advance"]),
  street_address: z.string(),
  city: z.string(),
  zip_code: z.string(),
  /** Desired delivery day. @TODO how is this formatted? */
  day_wanted: z.optional(z.string()),
  street_address_2: z.optional(z.string()),
  state: z.optional(z.nullable(z.string())),
  /**
   * @TODO remove alongside standardizeAddressZipCode after we figure out
   * which parts of the ordering app send `zip` instead of `zip_code`.
   */
  zip: z.optional(z.string()),
  /** Special delivery instructions for this address, such as a door code. */
  special_instructions: z.optional(z.string()),
  /** Address ID. */
  id: z.optional(z.nullable(z.number())),
  /** @TODO what is this value? */
  stale_id: z.optional(z.number()),
  /** Is this the user's default address? */
  default: z.optional(z.boolean())
});
var basketConveyanceDetails = z.object({
  /** Car color. */
  color: z.nullable(z.string()),
  /** Street address for delivery orders. */
  delivery_address: z.nullable(deliveryAddress.passthrough()),
  /** Car manufacturer. */
  make: z.nullable(z.string()),
  /** Car model. */
  model: z.nullable(z.string()),
  /** Parking spot where the customer is parked. */
  parking_spot_number: z.nullable(z.string()),
  /** For in-store orders, the customer's table number. */
  table_tent_number: z.nullable(z.string()),
  /** @TODO what is this value? */
  type: z.string()
});
var basketFee = z.object({
  amount: z.number(),
  description: z.nullable(z.string()),
  note: z.nullable(z.string())
});
var basketItemOption = z.object({
  /** Option ID. */
  id: z.string(),
  /** Option ID as present in Remote Basket. */
  option_id: z.optional(z.string()),
  /** Quantity added to the item. */
  quantity: z.number(),
  /** Cost associated with this option. */
  cost: z.optional(z.number()),
  /** Option name. */
  name: z.optional(z.string()),
  /** Allergens associated with this option. */
  allergens: z.optional(allergenItem.passthrough().array()),
  /** The Option's ID across all locations. */
  global_option_id: z.optional(z.string())
});
var basketItem = z.object({
  product: z.object({
    /** Product ID unique to a single location. */
    id: z.string(),
    /** The Product's ID across all locations. */
    global_id: z.string(),
    /** Product name. */
    name: z.string(),
    /** Unformatted item cost. */
    cost: z.number(),
    /** Local to the application value. ID of the cross-sell, if this item was added as a cross-sell. */
    cross_sell_id: z.string().nullable().optional(),
    /** Local to the application value. ID of the category this product is in. */
    category_id: z.string().nullable().optional(),
    /** Local to the application value. Label of the category this product is in. */
    category_label: z.string().nullable().optional(),
    /** Product's filter tag */
    filter_tags: z.optional(z.nullable(tagItem.passthrough().array()))
  }),
  /** Customized options for this item. */
  options: basketItemOption.passthrough().array(),
  /** Item quantity that was ordered. */
  quantity: z.number(),
  /** Any special ordering instructions. */
  special_instructions: z.string(),
  /** The person that this item is for. */
  recipient: z.string(),
  /** Basket Item ID. */
  id: z.optional(z.string())
});
var curbsideDetails = z.object({
  /** Car color. */
  color: z.string(),
  /** Car manufacturer. */
  make: z.string(),
  /** Car model. */
  model: z.string()
});
var dineInDetails = z.object({
  /** For in-store orders, the customer's table number. */
  table_tent_number: z.string()
});
var driveInDetails = z.object({
  parking_spot_number: z.string()
});
var basket = z.object({
  /** Basket ID. */
  id: z.string(),
  /** How the basket will be given to the customer. */
  conveyance_type: basketConveyanceDetails.passthrough(),
  /** @TODO how does this differ from `special_instructions`? */
  basket_instructions: z.nullable(z.string()),
  /** Any special ordering instructions. */
  special_instructions: z.nullable(z.string()),
  /** Tip amount. */
  tip: z.number(),
  /** Unformatted subtotal amount, excluding tax & tip. */
  subtotal: z.number(),
  /** Unformatted tax amount. */
  sales_tax: z.number(),
  fees: basketFee.passthrough().array(),
  /** Unformatted order total cost, including tax & tip. */
  total: z.number(),
  /** Esimated fulfillment time for the order, in minutes. */
  time_estimate_minutes: z.number(),
  /** Does this location support ordering ASAP? */
  order_asap: z.boolean(),
  /** Price discount from promotions. */
  promo_discount: z.number(),
  /** Does the location support tipping? */
  supports_tip: z.boolean(),
  /** Should utensils be included in this order? */
  utensils: z.boolean(),
  /** The order's delivery fee, if applicable. */
  delivery_fee: z.number(),
  /** Location that the basket is ordered from */
  location: location.passthrough(),
  /** Items in the user's basket. */
  basket_items: basketItem.passthrough().array(),
  /** ISO8601 datestamp when the customer would like the order. */
  wanted_at: z.nullable(z.string())
}).passthrough();
var digitalWallet2 = z.object({
  id: z.number(),
  name: z.union([z.literal("google_pay"), z.literal("apple_pay")])
});
var paymentTypesResponse = z.object({
  /** @TODO improve this type. */
  stored_value_requirements: z.unknown().array(),
  supports_billing_account: z.boolean(),
  supports_cash: z.boolean(),
  supports_credit_card: z.boolean(),
  supports_prepaid: z.boolean(),
  supports_split_payment: z.boolean(),
  supports_stored_value: z.boolean(),
  supports_credit_card_token: z.nullable(z.boolean()).optional(),
  supported_digital_wallets: z.array(digitalWallet2).default([])
}).passthrough();
var paymentProviders = z.union([
  z.literal("stripe"),
  z.literal("olo_pay"),
  z.literal("square")
]);
var paymentEnvironments = z.union([
  z.literal("sandbox"),
  z.literal("production")
]);
var creditCardTokenSupport = z.discriminatedUnion("supported", [
  z.object({
    supported: z.literal(true),
    provider: paymentProviders,
    environment: paymentEnvironments,
    key: z.string()
  }),
  z.object({
    supported: z.literal(false),
    provider: z.null(),
    environment: z.null(),
    key: z.null()
  })
]);
var creditCardSupport = z.discriminatedUnion("supported", [
  z.object({
    supported: z.literal(true),
    proxy: z.literal("pci_proxy"),
    environment: paymentEnvironments,
    key: z.string()
  }),
  z.object({
    supported: z.literal(false),
    proxy: z.null(),
    environment: z.null(),
    key: z.null()
  })
]);
var digitalWalletSupport = z.discriminatedUnion("supported", [
  z.object({
    provider: paymentProviders,
    supported: z.literal(true),
    environment: paymentEnvironments,
    /** in case of OloPay implementation we don't use key as it is built into OloPay library */
    key: z.string().nullable(),
    supported_digital_wallets: z.array(digitalWallet2)
  }),
  z.object({
    provider: z.null(),
    supported: z.literal(false),
    environment: z.null(),
    key: z.null(),
    supported_digital_wallets: z.array(digitalWallet2).default([])
  })
]);
var giftCardRequirement = z.object({
  /** Formatted label label. Eg: "Gift Card PIN" */
  label: z.string(),
  /** Field input type. Eg: "password" */
  type: z.string(),
  /** Field name. Eg: "pin" */
  name: z.string(),
  value: z.nullable(z.string())
});
var giftCardSupport = z.object({
  supported: z.boolean(),
  requirements: giftCardRequirement.array()
});
var supportedPaymentTypes = z.object({
  billing_account: z.object({ supported: z.boolean() }),
  cash: z.object({ supported: z.boolean() }),
  credit_card_token: creditCardTokenSupport,
  credit_card: creditCardSupport,
  digital_wallet: digitalWalletSupport,
  prepaid: z.object({ supported: z.boolean() }),
  stored_value: giftCardSupport
}).passthrough();
var storedValueBalanceResponse = z.object({
  /** Remaining balance. */
  balance: z.number(),
  /** Gift card number. */
  card_number: z.string()
}).passthrough();
var submitBasketData = z.object({
  /** Payment details for the basket. */
  payment: z.lazy(() => paymentDetails.passthrough().array()),
  /** Customer details submitted alongside the basket. */
  customer: z.lazy(() => customerDetails.passthrough()),
  allergies: z.string().array()
});
var basketOrder = z.object({
  /** Menu items to order. */
  basket_items: basketItem.passthrough().array(),
  /**
   * ID of the location to order from.
   * @TODO should this be `location_id` or a full `location record`?
   */
  location: z.object({ id: z.number() }),
  /** Any special ordering instructions. */
  special_instructions: z.string(),
  referral_tracking_code: z.optional(z.string())
});
var checkoutBasketDeliveryAddress = deliveryAddress.extend({
  time_wanted_mode: z.union([
    z.literal("advance"),
    z.literal("asap"),
    z.null()
  ])
});
var checkoutBasketConveyanceDetails = z.object({
  /** Car color. */
  color: z.nullable(z.string()),
  /** Street address for delivery orders. */
  delivery_address: checkoutBasketDeliveryAddress.passthrough(),
  /** Car manufacturer. */
  make: z.nullable(z.string()),
  /** Car model. */
  model: z.nullable(z.string()),
  /** Parking spot where the customer is parked. */
  parking_spot_number: z.nullable(z.string()),
  /** For in-store orders, the customer's table number. */
  table_tent_number: z.nullable(z.string()),
  /** @TODO what is this value? */
  type: z.string()
});
var checkoutBasket = z.object({
  /** Basket ID. */
  id: z.string(),
  /** @TODO how does this differ from `special_instructions`? */
  basket_instructions: z.string(),
  /** Any special ordering instructions. */
  special_instructions: z.string(),
  /** Fees associated with this order. */
  fees: basketFee.passthrough().array(),
  /** Tip amount. */
  tip: z.number(),
  /** Does the location support tipping? */
  supports_tip: z.boolean(),
  /** The order's delivery fee, if applicable. */
  delivery_fee: z.number(),
  /** Unformatted subtotal amount, excluding tax & tip. */
  subtotal: z.number(),
  /** Unformatted tax amount. */
  sales_tax: z.number(),
  /** Unformatted order total cost, including tax & tip. */
  total: z.number(),
  /** Esimated fulfillment time for the order, in minutes. */
  time_estimate_minutes: z.number(),
  /** Price discount from promotions. */
  promo_discount: z.number(),
  /** ISO8601 datestamp when the customer would like the order.
   * Can be null if the order is ASAP.
   */
  wanted_at: z.string().nullable(),
  /** @TODO what is this value? */
  version: z.unknown(),
  /** Does this location support ordering ASAP? */
  order_asap: z.boolean(),
  /** Should utensils be included in this order? */
  utensils: z.boolean(),
  /** ISO8601 datestamp when the food will be ready. */
  earliest_ready_time_at_location: z.string(),
  /** Location that the basket is ordered from */
  location: location.passthrough(),
  /** Purchased items. */
  basket_items: basketItem.passthrough().array(),
  /**
   * As of 4/27/22, this appears unused though null is returned from the API.
   * @TODO remove?
   */
  promo_codes: z.unknown(),
  /** How the basket will be given to the customer. */
  conveyance_type: checkoutBasketConveyanceDetails.passthrough()
});
var availableWantedAtTimes = z.string().array();
var customerDetailsV2 = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  contact_number: z.string(),
  email_receipt: z.boolean(),
  opt_in: z.boolean()
});
var submissionDetails = z.object({
  payment: supportedPaymentMethods.array(),
  customer: customerDetailsV2.passthrough(),
  allergies: z.string().array()
}).passthrough();

// src/v4/api/basket.service.ts
import { stringify as stringify2 } from "qs";

// src/v4/core/utils.ts
import { stringify as _stringify } from "qs";
var token = z.object({
  token_type: z.literal("Bearer"),
  expires_in: z.number(),
  access_token: z.string(),
  scopes: z.string().array(),
  refresh_token: z.string().optional(),
  refresh_token_expires_in: z.number().optional()
});
function tokenLockName(origin) {
  return encodeURIComponent(`koala-lock-${origin}`);
}
function buildOrderingApiUrl(env) {
  return isProd(env) ? "https://api.koala.io" : `https://${env}.api.koala.io`;
}
function safeParse({
  data,
  schema,
  uri
}) {
  const parsedResponse = schema.safeParse(data);
  if (parsedResponse.success) {
    return parsedResponse.data;
  }
  if (process.env.NODE_ENV === "test") {
    throw parsedResponse.error;
  }
  if (process.env.NODE_ENV !== "production") {
  }
  return data;
}
function isProd(env) {
  return env === "production";
}
function listSchema(schema) {
  return z.object({
    data: schema.array(),
    meta: z.object({
      pagination: z.object({
        count: z.number(),
        current_page: z.number(),
        per_page: z.number(),
        total: z.number(),
        total_pages: z.number()
      })
    })
  });
}
function stringify(...args) {
  return _stringify(args[0], __spreadProps(__spreadValues({}, args[1]), { encodeValuesOnly: true }));
}
function getLock(origin) {
  return window.localStorage.getItem(tokenLockName(origin));
}
function setLock(origin) {
  const lockTime = String((/* @__PURE__ */ new Date()).getTime());
  window.localStorage.setItem(tokenLockName(origin), lockTime);
}
function removeLock(origin) {
  window.localStorage.removeItem(tokenLockName(origin));
}
function lockIsStale(origin) {
  const lock = window.localStorage.getItem(tokenLockName(origin));
  if (!lock) {
    return false;
  }
  return parseInt(lock) <= (/* @__PURE__ */ new Date()).getTime() - 1e4;
}
function replayRequest(req, token2) {
  var _a, _b, _c, _d, _e, _f;
  const originalRequest = req.auth(`Bearer ${token2.access_token}`).fetch().unauthorized((err) => {
    throw err;
  });
  if (((_b = (_a = req == null ? void 0 : req._options) == null ? void 0 : _a.headers) == null ? void 0 : _b.Accept) === "application/zlib") {
    return originalRequest.arrayBuffer();
  }
  if (((_d = (_c = req == null ? void 0 : req._options) == null ? void 0 : _c.headers) == null ? void 0 : _d.status) === 204 || ((_f = (_e = req == null ? void 0 : req._options) == null ? void 0 : _e.headers) == null ? void 0 : _f.status) === 202) {
    return originalRequest.res();
  }
  return originalRequest.json();
}

// src/v4/api/loyalty.models.ts
var loyaltyState = z.object({
  /** The user's available reward points balance. */
  points: z.number(),
  /** @TODO what is this value? */
  net_points: z.number(),
  /** Id of the user's membership level. */
  membership_level_id: z.nullable(z.number()),
  /** Formatted name of the user's membership level. */
  membership_level_name: z.nullable(z.string()),
  /** @TODO what is this value? */
  banked_currency: z.number(),
  /** @TODO what is this value? */
  banked_currency_expiring: z.number(),
  /** @TODO what is this value? */
  banked_currency_expiration_date: z.nullable(z.string()),
  /** @TODO what is this value? */
  points_threshold: z.number(),
  /** @TODO what is this value? */
  conversion_rate: z.number(),
  /** @TODO improve typing. */
  rewards: z.undefined()
});
var loyaltyUser = z.object({
  /** The user's birthday. date_format:Y-m-d */
  birthday: z.nullable(z.string()),
  /** ISO8601 datestamp when the user was created. */
  created_at: z.string(),
  /** ISO8601 datestamp when the user was deleted. */
  deleted_at: z.nullable(z.string()),
  /** User's email address. */
  email: z.string(),
  /** ID of the loyalty provider the user is registered with. */
  external_provider_id: z.number(),
  /** User ID with the loyalty provider. */
  external_user_id: z.string(),
  /** User's first name. */
  first_name: z.string(),
  /** Koala User ID. */
  id: z.number(),
  /** User's unique identifier. Likely their email address. */
  identifier: z.optional(z.string()),
  /** User's last name. */
  last_name: z.string(),
  /** User's full name. */
  name: z.string(),
  /** ID of the organization this user belongs to. */
  organization_id: z.number(),
  /** User's phone number. */
  phone: z.string(),
  /** User's QR code for earning points in-store. */
  qrcode: z.nullable(z.string()),
  /** Has the user opted-in to recieving marketing emails? */
  opt_in: z.optional(z.boolean()),
  /** Signup referral code that the user can distribute. */
  referral_code: z.nullable(z.string()),
  /** ISO8601 datestamp when the user was last updated. */
  updated_at: z.string(),
  /** The user's username, if it differs from email. */
  username: z.nullable(z.string()),
  /** User's zip code */
  zip_code: z.optional(z.nullable(z.string())),
  /** ID for in-store earning and redemption */
  external_user_fk: z.nullable(z.string())
});
var messageCTA = z.object({
  type: z.string(),
  /** CTA link URL. */
  url: z.string(),
  /** CTA label text. */
  label: z.string(),
  /** Higher priority CTAs will appear first. */
  priority: z.string()
});
var messageHeroContent = z.object({
  /** Alt text for the content. */
  alt_text: z.string(),
  /** If the content is a video, this image shows behind the play button. */
  initial_frame: z.string(),
  /** The hero content can be either an image or a video. */
  type: z.string(),
  /** Content URL. */
  url: z.string()
});
var messageBackgroundContent = z.object({
  /** Alt text for the content. */
  alt_text: z.string(),
  /** If the content is a video, this image shows behind the play button. */
  initial_frame: z.string(),
  /** The background content can be either an image or a video. */
  type: z.string(),
  /** Content URL. */
  url: z.string()
});
var messageItem = z.object({
  /** Message ID. */
  id: z.string(),
  /** Message title. */
  title: z.string(),
  /** Message subtitle. */
  subtitle: z.string(),
  /** Message body text. */
  body: z.string(),
  /** Message type. Eg: `user_specific`. */
  type: z.string(),
  /** Importance level of the message. */
  rank: z.number(),
  /** ISO8601 datestamp when the message was marked as read. */
  read_at: z.nullable(z.string()),
  /**
   * ISO8601 datestamp when the message was should be shown on the client.
   * This allows brands to write messages and schedule their delivery for later.
   */
  launch_time: z.string(),
  /** Calls to Action to display at the bottom of the message. */
  message_ctas: messageCTA.passthrough().array(),
  /** Image or video shown above the message. */
  hero_content: messageHeroContent.passthrough(),
  /** Image or video shown behind the message. */
  background_content: messageBackgroundContent.passthrough()
});
var redeemableItem = z.object({
  /** Item ID. */
  id: z.number(),
  /** Item title. */
  label: z.string(),
  /** Text description of the item. */
  description: z.string(),
  /** The number of points required to redeem. */
  points_required: z.number(),
  /** @TODO what is this value? */
  meta_data: z.string(),
  /** The item's display image. */
  image: z.object({ name: z.string(), url: z.string() }).passthrough(),
  /** Flag that indicates that item can be donated or not */
  supports_donation: z.boolean()
});
var redemptionItem = z.object({
  /** Text description of the item. */
  description: z.string(),
  /** ISO8601 datestamp when the tracking_code would expire. */
  expiring_at: z.string(),
  /** Item ID. @TODO how does this differ from the `redeemable_id`?  */
  id: z.number(),
  /** The item's display image. */
  image: z.object({ name: z.string(), url: z.string() }).passthrough(),
  /** Item title. */
  label: z.string(),
  /** ID of the item being redeemed. */
  redeemable_id: z.number(),
  /** @TODO what is this value? */
  starting_at: z.string(),
  /** @TODO what is this value? */
  status: z.string(),
  /** Code entered by the end-user at checkout if they redeem in-store. */
  tracking_code: z.string()
});
var rewardHistoryItem = z.object({
  /** ISO8601 datestamp when the event took place. */
  date: z.string(),
  /** Description of the event. */
  description: z.string(),
  /** Point value, positive or negative. */
  value: z.string()
});
var rewardItem = z.object({
  /** Whether the item can be claimed in-store. */
  available_offline: z.boolean(),
  /** Whether the item can be claimed on an online order. */
  available_online: z.boolean(),
  /** Text description of the item. */
  copy: z.string(),
  /** Text description of the item. @TODO duplicate of `copy`? */
  description: z.string(),
  /** ISO8601 datestamp when the reward expires. */
  expires_at: z.string(),
  /** Item ID. */
  id: z.string(),
  /** The item's display image. */
  image: z.object({ name: z.string(), url: z.string() }).passthrough(),
  /** Item title. */
  label: z.string(),
  /** @TODO what is this value? */
  locations: z.unknown(),
  /** @TODO what is this value? */
  quantity_applied: z.number(),
  /** @TODO what is this value? */
  quantity_available: z.number(),
  /** ISO8601 datestamp when the reward becomes available. */
  starts_at: z.nullable(z.string()),
  /** Item type. Eg: `gift`. */
  type: z.string(),
  /** @TODO what is this value? */
  value: z.number(),
  /** @TODO what is this value? */
  reference: z.string(),
  /** if reward has redemption item created based on its id then there will be redemption attached to it */
  redemption: redemptionItem.passthrough().optional(),
  /** Flag that indicates that item can be donated or not */
  supports_donation: z.boolean()
}).passthrough();
var socialCause = z.object({
  /** The ID of the social cause associated with the campaign */
  id: z.number(),
  /** The name of the campaign */
  name: z.string().nullable(),
  /** A brief description of the campaign */
  description: z.string().nullable(),
  /** The URL of the campaign's image */
  image_url: z.string().nullable(),
  /** Miscellaneous information about the campaign */
  miscellaneous: z.string().nullable(),
  /** The city where the campaign is located */
  city: z.string().nullable(),
  /** The state where the campaign is located */
  state: z.string().nullable(),
  /** The street address where the campaign is located */
  street_address: z.string().nullable(),
  /** The ZIP code of the campaign's location */
  zip_code: z.string().nullable(),
  /** The phone number associated with the campaign */
  phone: z.string().nullable(),
  /** The email address associated with the campaign */
  email: z.string().nullable(),
  /** The full address of the campaign */
  address: z.string().nullable()
});
var donationTypes = z.union([
  z.literal("currency"),
  z.literal("reward"),
  z.literal("redeemable")
]);
var donation = z.object({
  /** The type of donation */
  type: donationTypes,
  /** The item being donated
   * if currency - $ amount that needs to be donated
   * if reward - reward_id of the reward that needs to be donated.
   * if redeemable - redeemable_id of the redeemable that needs to be donated.
   */
  item: z.string(),
  /** The number of occurrences of the donation */
  occurrences: z.number().nullable().optional()
});

// src/api/basket.models.v2.ts
var dateTime = z.string().datetime({ offset: true });
var basketLocation = location.omit({ cached_data: true, zip_code: true }).passthrough();
var basketProduct = menuProduct.omit({
  images: true,
  calorie_range: true,
  calsIdentifier: true,
  pretty_price: true,
  pretty_calories: true,
  customizable: true,
  filter_tags: true
}).partial({ options: true }).passthrough();
var CONVEYANCE_MODES = z.enum([
  "curbside",
  "delivery",
  "dine_in",
  "drive_in",
  "drive_thru",
  "pick_up"
]);
var deliveryAddress2 = z.object({
  street_address: z.string(),
  street_address_2: z.optional(z.nullable(z.string())),
  city: z.string(),
  state: z.nullable(z.string()),
  zip_code: z.string(),
  phone_number: z.string(),
  special_instructions: z.string()
});
var storedAddress2 = deliveryAddress2.extend({
  id: z.number(),
  default: z.boolean()
});
var address = z.union([deliveryAddress2, storedAddress2]);
var curbside = z.object({
  type: z.literal(CONVEYANCE_MODES.enum.curbside),
  make: z.string(),
  model: z.string(),
  color: z.string()
});
var delivery = z.object({
  type: z.literal(CONVEYANCE_MODES.enum.delivery),
  delivery_address: deliveryAddress2
});
var dineIn = z.object({
  type: z.literal(CONVEYANCE_MODES.enum.dine_in),
  table_tent_number: z.nullable(z.string())
});
var driveIn = z.object({
  type: z.literal(CONVEYANCE_MODES.enum.drive_in),
  parking_spot_number: z.string()
});
var driveThru = z.object({
  type: z.literal(CONVEYANCE_MODES.enum.drive_thru)
});
var pickup = z.object({
  type: z.literal(CONVEYANCE_MODES.enum.pick_up)
});
var conveyanceType = z.discriminatedUnion("type", [
  curbside,
  delivery,
  dineIn,
  driveIn,
  driveThru,
  pickup
]);
var fee = z.object({
  amount: z.number(),
  description: z.nullable(z.string()),
  note: z.nullable(z.string())
});
var optionChoice = z.object({
  id: z.string(),
  cost: z.number(),
  name: z.string(),
  option_id: z.string(),
  quantity: z.number(),
  allergens: z.nullable(allergenItem.array())
});
var basketItem2 = z.object({
  id: z.string(),
  quantity: z.number(),
  base_cost: z.number(),
  total_cost: z.number(),
  special_instructions: z.string(),
  recipient: z.string(),
  category_id: z.string().nullable(),
  options: optionChoice.array(),
  product: basketProduct
});
var basketV2 = z.object({
  id: z.string(),
  basket_instructions: z.nullable(z.string()),
  basket_items: basketItem2.array(),
  conveyance_type: conveyanceType,
  delivery_fee: z.number(),
  earliest_ready_time_at_location: z.nullable(dateTime),
  fees: fee.array(),
  location: basketLocation,
  order_asap: z.boolean(),
  promo_discount: z.number(),
  sales_tax: z.number(),
  special_instructions: z.nullable(z.string()),
  subtotal: z.number(),
  supports_tip: z.boolean(),
  time_estimate_minutes: z.number(),
  tip: z.number(),
  total: z.number(),
  utensils: z.boolean(),
  wanted_at: z.nullable(dateTime)
});
var handoffTimes = dateTime.array();
var customerDetailsV22 = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  contact_number: z.string(),
  email_receipt: z.boolean(),
  opt_in: z.boolean()
});
var paymentEnvironments2 = z.union([
  z.literal("sandbox"),
  z.literal("production")
]);
var paymentProviders2 = z.union([
  z.literal("stripe"),
  z.literal("olo_pay"),
  z.literal("square")
]);
var creditCardTokenSupport2 = z.discriminatedUnion("supported", [
  z.object({
    supported: z.literal(true),
    provider: paymentProviders2,
    environment: paymentEnvironments2,
    key: z.string()
  }),
  z.object({
    supported: z.literal(false),
    provider: z.null(),
    environment: z.null(),
    key: z.null()
  })
]);
var creditCardSupport2 = z.discriminatedUnion("supported", [
  z.object({
    supported: z.literal(true),
    proxy: z.literal("pci_proxy"),
    environment: paymentEnvironments2,
    key: z.string()
  }),
  z.object({
    supported: z.literal(false),
    proxy: z.null(),
    environment: z.null(),
    key: z.null()
  })
]);
var digitalWallet3 = z.object({
  id: z.number(),
  name: z.union([z.literal("google_pay"), z.literal("apple_pay")])
});
var digitalWalletSupport2 = z.discriminatedUnion("supported", [
  z.object({
    provider: paymentProviders2,
    supported: z.literal(true),
    environment: paymentEnvironments2,
    /** in case of OloPay implementation we don't use key as it is built into OloPay library */
    key: z.string().nullable(),
    supported_digital_wallets: z.array(digitalWallet3)
  }),
  z.object({
    provider: z.null(),
    supported: z.literal(false),
    environment: z.null(),
    key: z.null(),
    supported_digital_wallets: z.array(digitalWallet3).default([])
  })
]);
var giftCardRequirement2 = z.object({
  /** Formatted label label. Eg: "Gift Card PIN" */
  label: z.string(),
  /** Field input type. Eg: "password" */
  type: z.string(),
  /** Field name. Eg: "pin" */
  name: z.string(),
  value: z.nullable(z.string())
});
var giftCardSupport2 = z.object({
  supported: z.boolean(),
  requirements: giftCardRequirement2.array()
});
var supportedPaymentTypes2 = z.object({
  billing_account: z.object({ supported: z.boolean() }),
  cash: z.object({ supported: z.boolean() }),
  credit_card_token: creditCardTokenSupport2,
  credit_card: creditCardSupport2,
  digital_wallet: digitalWalletSupport2,
  prepaid: z.object({ supported: z.boolean() }),
  stored_value: giftCardSupport2
});
var submissionDetails2 = z.object({
  payment: supportedPaymentMethods.array(),
  customer: customerDetailsV22.passthrough(),
  allergies: z.string().array()
});

// src/v4/api/ordering.models.ts
var ARRIVAL_STATUS = /* @__PURE__ */ ((ARRIVAL_STATUS2) => {
  ARRIVAL_STATUS2["NOT_SUPPORTED"] = "not_supported";
  ARRIVAL_STATUS2["ORDER_PLACED"] = "order_placed";
  ARRIVAL_STATUS2["ARRIVED"] = "arrived";
  ARRIVAL_STATUS2["PICKED_UP"] = "picked_up";
  return ARRIVAL_STATUS2;
})(ARRIVAL_STATUS || {});
var CONVEYANCE_TYPES = /* @__PURE__ */ ((CONVEYANCE_TYPES2) => {
  CONVEYANCE_TYPES2["PICKUP"] = "Pickup";
  CONVEYANCE_TYPES2["DELIVERY"] = "Delivery";
  CONVEYANCE_TYPES2["CURBSIDE"] = "Curbside";
  CONVEYANCE_TYPES2["DINEIN"] = "Dine-In";
  CONVEYANCE_TYPES2["DRIVETHRU"] = "Drive-Thru";
  CONVEYANCE_TYPES2["DRIVEIN"] = "Drive-In";
  return CONVEYANCE_TYPES2;
})(CONVEYANCE_TYPES || {});
var CONVEYANCE_TYPE_KEYS = /* @__PURE__ */ ((CONVEYANCE_TYPE_KEYS2) => {
  CONVEYANCE_TYPE_KEYS2[CONVEYANCE_TYPE_KEYS2["PICKUP"] = 0] = "PICKUP";
  CONVEYANCE_TYPE_KEYS2[CONVEYANCE_TYPE_KEYS2["DELIVERY"] = 1] = "DELIVERY";
  CONVEYANCE_TYPE_KEYS2[CONVEYANCE_TYPE_KEYS2["CURBSIDE"] = 2] = "CURBSIDE";
  CONVEYANCE_TYPE_KEYS2[CONVEYANCE_TYPE_KEYS2["DINEIN"] = 3] = "DINEIN";
  CONVEYANCE_TYPE_KEYS2[CONVEYANCE_TYPE_KEYS2["DRIVETHRU"] = 4] = "DRIVETHRU";
  CONVEYANCE_TYPE_KEYS2[CONVEYANCE_TYPE_KEYS2["DRIVEIN"] = 5] = "DRIVEIN";
  return CONVEYANCE_TYPE_KEYS2;
})(CONVEYANCE_TYPE_KEYS || {});
var DELIVERY_PROVIDER_STATUS = /* @__PURE__ */ ((DELIVERY_PROVIDER_STATUS2) => {
  DELIVERY_PROVIDER_STATUS2["PENDING"] = "Pending";
  DELIVERY_PROVIDER_STATUS2["PICKUP_IN_PROGRESS"] = "PickupInProgress";
  DELIVERY_PROVIDER_STATUS2["DELIVERY_IN_PROGRESS"] = "DeliveryInProgress";
  DELIVERY_PROVIDER_STATUS2["DELIVERED"] = "Delivered";
  DELIVERY_PROVIDER_STATUS2["CANCELLED"] = "Canceled";
  DELIVERY_PROVIDER_STATUS2["RETURNED"] = "Returned";
  return DELIVERY_PROVIDER_STATUS2;
})(DELIVERY_PROVIDER_STATUS || {});
var DELIVER_PROVIDER_TERMINATING_STATUS = [
  "Delivered" /* DELIVERED */,
  "Canceled" /* CANCELLED */,
  "Returned" /* RETURNED */
];
var customerDetails2 = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  contact_number: z.string(),
  /** Should the receipt be emailed to the user? */
  email_receipt: z.boolean().optional(),
  opt_in: z.boolean().optional()
});
var creditCardBase2 = z.object({
  /** Tokenized card number that the API can use to submit a charge. */
  alias_cc: z.nullable(z.string()).optional(),
  /** Tokenized card CVV. (the 3/4 digit number) */
  alias_cvv: z.nullable(z.string()).optional(),
  /** Credit card type. Eg: "VIS" */
  payment_method: z.nullable(z.string()).optional(),
  /** Year that the card expires. */
  expiry_year: z.nullable(z.number()).optional(),
  /** Month that the card expires. */
  expiry_month: z.nullable(z.number()).optional(),
  /** Payment country code. Eg: "US" */
  country: z.nullable(z.string()).optional(),
  /** Last 4 digits of the credit card. Eg: "x-4242" */
  last_four: z.nullable(z.string()).optional()
});
var tokenizedCreditCard2 = creditCardBase2.extend({
  /** Masked card number. Eg: "411111xxxxxx1111". */
  masked_card: z.string().optional()
});
var storedValueRequirement2 = z.object({
  /** Could be something like "pin" or "card_number". */
  name: z.nullable(z.string()),
  /** Value for corresponding name */
  value: z.nullable(z.string())
});
var paymentDetails2 = tokenizedCreditCard2.extend({
  id: z.nullable(z.string()).optional(),
  /** Id that should be set from paymentTypesResponse.supported_digital_wallets,
   * depending on what digital wallet type is available,
   * used for authorizing payment.
   */
  digital_wallet_id: z.number().optional(),
  /** Token that is getting exchanged for card data, used for authorizing payment. */
  card_token: z.nullable(z.string()).optional(),
  /** Total payment, excluding tip. */
  amount: z.number().optional(),
  /** Credit Card brand. */
  card_brand: z.string().optional(),
  /** Details about the customer who submitted the order. */
  customer: customerDetails2.passthrough().optional(),
  /** @TODO what is this value? */
  payment_location_id: z.string().optional(),
  /** Is this payment method saved? */
  save: z.boolean().optional(),
  /** @TODO what is this value? */
  /** @TODO is it really number? */
  saved_account_id: z.nullable(z.string()).optional(),
  /** @TODO what is this value? */
  stored_value_number: z.nullable(z.number()).optional(),
  /** @TODO what is this value? */
  stored_value_pin: z.nullable(z.number()).optional(),
  /**
   * Collection of StoredValueRequirement
   * Required if type === PAYMENT_TYPE.billing_account
   */
  stored_value_requirements: storedValueRequirement2.passthrough().array().optional(),
  /** Tip amount. */
  tip: z.number().optional(),
  /** Payment type, such as cash, or card. */
  type: z.nativeEnum(PAYMENT_TYPES),
  /** Billing zip code. */
  zip_code: z.string().optional()
});
var order = z.object({
  /** Order ID. */
  id: z.string(),
  /** Unformatted subtotal amount, excluding tax & tip. */
  subtotal: z.optional(z.number()),
  /** Unformatted tax amount. */
  sales_tax: z.optional(z.number()),
  /** Unformatted order total cost, including tax & tip. */
  total: z.number(),
  /** ISO8601 datestamp when the order will be ready. */
  ready_at: z.string(),
  /** ISO8601 datestamp when the order was submitted. */
  ordered_at: z.optional(z.string()),
  /** @TODO how does this differ from `id`? */
  order_number: z.string(),
  /** Unformatted discount amount. */
  promo_discount: z.optional(z.number())
});
var orderDetails = z.object({
  /** Current arrival status of a delivery order. */
  arrival_status: z.nullable(z.nativeEnum(ARRIVAL_STATUS)),
  /** @TODO what is this value? */
  balance: z.number(),
  /** ID of the basket submitted by this order. */
  basket_id: z.string(),
  /** Items in the order. */
  basket_items: z.lazy(() => basketItem.passthrough().array()),
  /** @TODO what is this value? */
  client_id: z.nullable(z.string()),
  /** ISO8601 datestamp when the order was submitted. */
  created_at: z.string(),
  /** ISO8601 datestamp when the delivery status was updated by the ordering provider. */
  delivery_status_updated_at: z.nullable(z.string()),
  /** Current status of a delivery order. */
  delivery_status: z.nullable(z.nativeEnum(DELIVERY_PROVIDER_STATUS)),
  /** Order ID. */
  id: z.string(),
  /** IP address that submitted the order. */
  ip: z.nullable(z.string()),
  /** Has the order been fulfilled. */
  is_complete: z.boolean(),
  /** Was this basket saved in a user's list of favorite order? */
  is_favorite: z.nullable(z.boolean()),
  /** Does this order include loyalty rewards? */
  is_loyalty_claimed: z.nullable(z.number()),
  /** The store location that the order was made at. */
  location: location.passthrough(),
  /** @TODO how does this differ from `id`? */
  order_id: z.string(),
  /** @TODO how does this differ from `id`? */
  order_number: z.nullable(z.string()),
  /** ID of the organization this order was made to. */
  organization_id: z.number(),
  /** The brand's payment driver. */
  payment_driver_id: z.nullable(z.number()),
  /** ISO8601 datestamp when the order will be ready. */
  ready_at: z.string(),
  /** @TODO how does this differ from `id`? */
  request_id: z.nullable(z.string()),
  /** @TODO what is this value? */
  status_id: z.number(),
  /** ID of the location that this order was made to. */
  store_location_id: z.number(),
  /** The location that this order was made to. */
  store_location: z.nullable(location.passthrough()),
  /** Unformatted order total cost, including tax & tip. */
  total: z.number(),
  /** ISO8601 datestamp when the order was last modified. */
  updated_at: z.string(),
  /** The customer's loyalty user ID, if applicable. */
  user_id: z.nullable(z.string()),
  order_data: z.object({
    /** Details about the customer who submitted the order. */
    customer: customerDetails2.passthrough(),
    order: z.object({
      /** Current arrival status of a delivery order. */
      arrival_status: z.nullable(z.nativeEnum(ARRIVAL_STATUS)),
      /** Order ID. */
      id: z.string(),
      /** @TODO improve this type. */
      order_items: z.unknown().array(),
      /** Order Number. @TODO how does this differ from `id`? */
      order_number: z.string(),
      /** ISO8601 datestamp when the order was submitted. */
      ordered_at: z.string(),
      /** @TODO is this the credit card brand? Or Credit Card vs. Gift Card? */
      payment_card_type: z.optional(z.string()),
      /** @TODO what is this value? */
      payment_card: z.optional(z.string()),
      /** Unformatted discount amount. */
      promo_discount: z.optional(z.number()),
      /** ISO8601 datestamp when the order will be ready. */
      ready_at: z.optional(z.string()),
      /** Unformatted tax amount. */
      sales_tax: z.number(),
      /** Unformatted subtotal amount, excluding tax & tip. */
      subtotal: z.number(),
      /** Unformatted order total, including tax & tip.  */
      total: z.number()
    }).passthrough(),
    /**
     * The user's ordered basket of items.
     */
    basket: basketV2.passthrough(),
    /** How the order was paid for. */
    payment: paymentDetails2.passthrough().array()
  }).passthrough()
});
var savedCard = z.object({
  /** ID of the saved card. */
  id: z.string(),
  /** @TODO is this credit/debit? How does it differ from `card_type`? */
  type: z.string(),
  /** Card type. @TODO is this amex/mastercard/visa? */
  card_type: z.nullable(z.string()),
  /** Last 4 digits. @TODO rename this for consistency. */
  suffix: z.nullable(z.string()),
  /** User-provided description. */
  description: z.string(),
  /** @TODO what is this value? */
  is_removable: z.boolean(),
  /** Card expiry. @TODO how is this formatted? */
  expires: z.nullable(z.string())
}).passthrough();
var deliveryStatus = z.object({
  /** @TODO how does `order_id` differ from `order_number`? */
  order_id: z.string(),
  order_number: z.string(),
  /** Dispatch service that will deliver the order. */
  delivery_service: z.string(),
  /** Driver information. */
  driver_name: z.optional(z.string()),
  driver_number: z.optional(z.string()),
  /** Current status of the delivery order. */
  status: z.nativeEnum(DELIVERY_PROVIDER_STATUS),
  /** Estimated pickup time. */
  estimated_pick_up_time: z.optional(z.string()),
  /** Estimated delivery time. */
  estimated_drop_off_time: z.optional(z.string()),
  /** Time that the order was picked up by the driver. */
  pick_up_time: z.optional(z.string()),
  /** Time that the order was delivered by the driver. */
  drop_off_time: z.optional(z.string()),
  /** ISO8601 timestamp when the order was cancelled. */
  cancelled_at_time: z.optional(z.string()),
  /** Reason why the order was cancelled. */
  cancelled_reason: z.optional(z.string()),
  /** Current latitude of the delivery driver. */
  lat: z.number(),
  /** Current longitude of the delivery driver. */
  long: z.number()
}).passthrough();
var arrivalResponse = z.object({
  /** Order ID. */
  id: z.optional(z.string()),
  /** Order total. */
  total: z.optional(z.number()),
  /** @TODO what is this value? */
  arrival_status: z.nullable(z.string())
});

// src/v4/api/basket.service.ts
var basketResponse = z.object({ data: basket });
function createBasket(_0, _1, _2) {
  return __async(this, arguments, function* (order2, locationId, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets`;
    const data = yield client.post(order2, uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function addBasketItem(_0, _1) {
  return __async(this, arguments, function* ({ locationId, basketId, item }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}`;
    const data = yield client.put(item, uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function removeBasketItem(_0, _1) {
  return __async(this, arguments, function* ({ locationId, basketId, itemId }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/items/${itemId}`;
    const data = yield client.delete(uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function updateBasketItem(_0, _1) {
  return __async(this, arguments, function* ({ locationId, basketId, itemId, item }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/items/${itemId}`;
    const data = yield client.put(item, uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function validateBasket(_0, _1) {
  return __async(this, arguments, function* ({ locationId, basketId }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/validate`;
    const data = yield client.post({}, uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function getPaymentTypes(_0, _1) {
  return __async(this, arguments, function* ({ locationId, basketId }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/available-payment-types`;
    const data = yield client.post({}, uri).json();
    const paymentTypes = safeParse({
      data,
      schema: z.object({ data: paymentTypesResponse }),
      uri
    });
    return paymentTypes.data;
  });
}
function getSupportedPaymentTypes(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/supported-payment-types`;
    const data = yield client.get(uri).json();
    const paymentTypes = safeParse({
      data,
      schema: z.object({ data: supportedPaymentTypes }),
      uri
    });
    return paymentTypes.data;
  });
}
function getStoredValue(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, cardNumber, pin, captcha }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/stored-value-balance`;
    const data = yield client.headers(__spreadValues({}, captcha && { "X-Captcha-Response": captcha })).post({ card_number: cardNumber, pin }, uri).json();
    const balance = safeParse({
      data,
      schema: z.object({ data: storedValueBalanceResponse }),
      uri
    });
    return balance.data;
  });
}
function addPromo(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, promoCode }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/promo`;
    const data = yield client.post({ promo_code: promoCode }, uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function removePromo(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, promoCode }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/promo/${promoCode}`;
    const data = yield client.delete(uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function getBasketRewards(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/rewards/available`;
    const data = yield client.get(uri).json();
    const rewards = safeParse({
      data,
      schema: z.object({ data: rewardItem.array() }),
      uri
    });
    return rewards.data;
  });
}
function getAppliedRewards(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/rewards`;
    const data = yield client.get(uri).json();
    const rewards = safeParse({
      data,
      schema: z.object({ data: rewardItem.array() }),
      uri
    });
    return rewards.data;
  });
}
function addRewards(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, rewardCodes, validate = false }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/rewards`;
    const data = yield client.query({ validate }).post({ rewards: rewardCodes }, uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function removeReward(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, rewardCode }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/rewards/${rewardCode}`;
    const data = yield client.delete(uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function getPickupTime(_0, _1) {
  return __async(this, arguments, function* ({ locationId, wantedAtType, dayWanted }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/wanted-time`;
    const data = yield client.query({ day: dayWanted, type: wantedAtType }).get(uri).json();
    const times = safeParse({
      data,
      schema: z.object({ data: availableWantedAtTimes }),
      uri
    });
    return times.data;
  });
}
function getPickupTimeByBasket(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, hoursType, dayWanted }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/wanted-time`;
    const data = yield client.query({ day: dayWanted, type: hoursType }).get(uri).json();
    const times = safeParse({
      data,
      schema: z.object({ data: availableWantedAtTimes }),
      uri
    });
    return times.data;
  });
}
function setPickupTime(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, wanted_at }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/wanted-time`;
    const data = yield client.post({ wanted_at }, uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function removePickupTime(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/wanted-time`;
    const data = yield client.delete(uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function addTip(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, tipAmount }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/tip-amount`;
    const data = yield client.post({ amount: tipAmount }, uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function setUtensils(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, preference }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/utensils`;
    const data = yield client.post({ preference }, uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function setInstructions(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, basket_instructions }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/instructions`;
    const data = yield client.post({ basket_instructions }, uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function getSavedCards(_0, _1) {
  return __async(this, arguments, function* (basketId, { client }) {
    const uri = `/v1/ordering/me/stored-cards`;
    const data = yield client.query(stringify2({ meta: { basket_id: basketId } })).get(uri).json();
    const cards = safeParse({
      data,
      schema: z.object({ data: savedCard.array() }),
      uri
    });
    return cards.data;
  });
}
function removeSavedCard(_0, _1) {
  return __async(this, arguments, function* (cardId, { client }) {
    const uri = `/v1/ordering/me/stored-cards/${cardId}`;
    const data = yield client.delete(uri).res();
    return safeParse({ data, schema: z.unknown(), uri });
  });
}
function getMostRecentCard(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    const uri = `/v1/ordering/me/stored-cards`;
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({ data: savedCard.array() }),
      uri
    });
  });
}
function submitOrder(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, orderData, captcha }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/submit`;
    const parsedOrderData = safeParse({
      data: orderData,
      uri,
      schema: submissionDetails
    });
    const data = yield client.headers(__spreadValues({}, captcha && { "X-Captcha-Response": captcha })).post(parsedOrderData, uri).json();
    return safeParse({ data: data.data, schema: z.unknown(), uri });
  });
}
function getAllergens(_0, _1) {
  return __async(this, arguments, function* ({ perPage = 10 }, { client }) {
    const uri = "/v1/allergies";
    const data = yield client.query({ per_page: perPage }).get(uri).json();
    const allergens = safeParse({
      data,
      schema: z.object({ data: allergenItem.array() }),
      uri
    });
    return allergens.data;
  });
}
function getTags(_0, _1) {
  return __async(this, arguments, function* ({ type, perPage = 50 }, { client }) {
    const uri = "/v1/tags";
    const data = yield client.query(stringify2({ per_page: perPage, filters: { type } })).get(uri).json();
    const tags = safeParse({
      data,
      schema: z.object({ data: tagItem.array() }),
      uri
    });
    return tags.data;
  });
}
function setConveyance(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, type, data }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/conveyance`;
    const options = type === "delivery" ? { delivery_address: data } : data;
    const res = yield client.post({ options, type }, uri).json();
    const newBasket = safeParse({ data: res, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function createBasketFromOrderId(_0, _1) {
  return __async(this, arguments, function* ({ orderId, locationId, skipUnavailable = false }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets`;
    const data = yield client.query({ skip_unavailable: skipUnavailable }).post({ from_order_id: orderId }, uri).json();
    const newBasket = safeParse({ data, schema: basketResponse, uri });
    return newBasket.data;
  });
}
function getOrderSummary(_0, _1) {
  return __async(this, arguments, function* (orderId, { client }) {
    const uri = "/v1/ordering/orders/summary";
    const data = yield client.query({ order_id: orderId }).get(uri).json();
    const summary = safeParse({
      data,
      schema: z.object({ data: orderDetails.passthrough() }),
      uri
    });
    return summary.data;
  });
}
function updateArrivalStatus(_0, _1) {
  return __async(this, arguments, function* ({ orderId, externalOrder = false }, { client }) {
    const uri = "/v1/ordering/orders/customer-arrival";
    yield client.query(externalOrder ? { order_id: orderId } : { id: orderId }).get(uri).json();
  });
}
function getOrderDeliveryStatus(_0, _1) {
  return __async(this, arguments, function* (orderNumber, { client }) {
    const uri = "/v1/ordering/orders/delivery-status";
    const data = yield client.query({ order_number: orderNumber }).get(uri).json();
    const status = safeParse({
      data,
      schema: z.object({ data: deliveryStatus }),
      uri
    });
    return status.data;
  });
}
function getTokenizedCreditCard(_0, _1) {
  return __async(this, arguments, function* ({ basketId, locationId, transactionId }, { client }) {
    const uri = `/v1/ordering/store-locations/${locationId}/baskets/${basketId}/card-tokenization`;
    const data = yield client.post({ transaction_id: transactionId }, uri).json();
    const tokenizedCard = safeParse({
      data,
      schema: z.object({ data: tokenizedCreditCard2 }),
      uri
    });
    return tokenizedCard.data;
  });
}

// src/v4/api/organization.models.ts
var LOYALTY_PROGRAM_TYPES = /* @__PURE__ */ ((LOYALTY_PROGRAM_TYPES2) => {
  LOYALTY_PROGRAM_TYPES2["POINTS_CONVERT_REDEEMABLES"] = "points_convert_redeemables";
  LOYALTY_PROGRAM_TYPES2["POINTS_CONVERT_CURRENCY"] = "points_convert_currency";
  LOYALTY_PROGRAM_TYPES2["POINTS_CONVERT_REWARDS"] = "points_convert_rewards";
  LOYALTY_PROGRAM_TYPES2["SP_POINTS_CONVERT_REWARDS"] = "sp_points_convert_rewards";
  return LOYALTY_PROGRAM_TYPES2;
})(LOYALTY_PROGRAM_TYPES || {});
var LOYALTY_FEATURES = /* @__PURE__ */ ((LOYALTY_FEATURES2) => {
  LOYALTY_FEATURES2["GET_AVAILABLE_REWARDS"] = "getAvailableRewards";
  LOYALTY_FEATURES2["APPLY_REWARDS"] = "applyRewards";
  LOYALTY_FEATURES2["GET_APPLIED_REWARDS"] = "getAppliedRewards";
  LOYALTY_FEATURES2["DESTROY_REWARD"] = "destroyReward";
  LOYALTY_FEATURES2["CLAIM_REWARDS"] = "claimRewards";
  LOYALTY_FEATURES2["CLAIM_REWARDS_BY_RECEIPT"] = "claimRewardsByReceipt";
  LOYALTY_FEATURES2["INDEX_MY_REWARDS"] = "indexMyRewards";
  LOYALTY_FEATURES2["INDEX_REDEEMABLES"] = "indexRedeemables";
  LOYALTY_FEATURES2["INDEX_REDEMPTIONS"] = "indexRedemptions";
  LOYALTY_FEATURES2["CREATE_REDEMPTION_USING_REDEEMABLE"] = "createRedemptionUsingRedeemable";
  LOYALTY_FEATURES2["CREATE_REDEMPTION_USING_REWARD"] = "createRedemptionUsingReward";
  LOYALTY_FEATURES2["SHOW_LOYALTY_HISTORY"] = "showLoyaltyHistory";
  LOYALTY_FEATURES2["INDEX_LOYALTY_MESSAGES"] = "indexLoyaltyMessages";
  LOYALTY_FEATURES2["DESTROY_LOYALTY_MESSAGE"] = "destroyLoyaltyMessage";
  LOYALTY_FEATURES2["MARK_READ_LOYALTY_MESSAGE"] = "markReadLoyaltyMessage";
  LOYALTY_FEATURES2["SHOW_LOYALTY_STATE"] = "showLoyaltyState";
  LOYALTY_FEATURES2["SUPPORTS_QR"] = "supportsQR";
  LOYALTY_FEATURES2["SUPPORTS_REFERRAL_CODE"] = "supportsReferralCode";
  LOYALTY_FEATURES2["SUPPORTS_PUSH_NOTIFICATIONS"] = "supportsPushNotifications";
  LOYALTY_FEATURES2["SUPPORTS_SOCIAL_CAUSES"] = "supportsSocialCauses";
  return LOYALTY_FEATURES2;
})(LOYALTY_FEATURES || {});
var orderingTokenConfig = z.object({
  /** ID of the ordering engine. */
  driver_id: z.number(),
  /** Name of the ordering engine. Eg: `olo_full_xml`. */
  label: z.string()
});
var orgTokenConfig = z.object({
  /** ISO8601 datestamp when the brand was created. */
  created_at: z.string(),
  /** ISO8601 datestamp when the brand was deleted. */
  deleted_at: z.nullable(z.string()),
  /** External auth service used by this brand. */
  external_provider_id: z.number(),
  /** Brand ID. */
  id: z.number(),
  /** Brand name. Eg: `Koala Labs`. */
  label: z.string(),
  /** Brand Accessor/URL slug. Eg: `koala-labs`. */
  slug: z.string(),
  /** ISO8601 datestamp when the brand synced with the ordering provider. */
  last_synced_at: z.string(),
  /** ID of the loyalty engine used by this brand. */
  loyalty_driver_id: z.nullable(z.number()),
  /** URL of general nutrition information for this brand. */
  nutrition_url: z.nullable(z.string()),
  /** ID of the ordering engine used by this brand. */
  ordering_driver_id: z.number(),
  /** ISO8601 datestamp when the brand was last updated. */
  updated_at: z.string(),
  /** ID of the upsells engine used by this brand. */
  upsell_driver_id: z.number()
});
var paymentTokenConfig = z.object({
  /** ID of the payment engine. */
  driver_id: z.number(),
  /** Name of the payment engine. Eg: `pci_proxy`. */
  label: z.string()
});
var externalAuthConfig = z.object({
  /** ID of the auth provider. */
  driver_id: z.number(),
  /** Auth provider name, such as "Olo". */
  label: z.string(),
  /** Captcha configuration, if supported by the auth provider. */
  captcha: z.optional(
    z.object({ actions: z.string().array(), site_key: z.string() })
  ),
  /** Supported account features. */
  features: z.object({
    deleteStoredAddresses: z.boolean(),
    listStoredAddresses: z.boolean(),
    requiresEmailVerification: z.boolean(),
    /** Supported social login providers. Eg: "facebook" or "apple". */
    supportForLoginWithSocial: z.string().array(),
    supportsAccountDeletion: z.boolean(),
    supportsSingleFavoriteLocations: z.boolean(),
    supportsStoredAddresses: z.boolean(),
    supportsStoredCards: z.boolean(),
    updateStoredAddresses: z.boolean()
  }),
  /** Required fields for user registration. */
  registration_fields: z.object({ user: z.string().array() }),
  /** Non-modifiable account fields. Once set, they can't be changed. */
  readonly_fields: z.object({ user: z.string().array() }),
  /** User-modifiable account fields. */
  updatable_fields: z.object({ user: z.string().array() })
});
var loyaltyConfig = z.object({
  /** ID of the loyalty provider. */
  driver_id: z.number(),
  /** Loyalty provider name, such as "punchh". */
  label: z.string(),
  /** Features supported by the loyalty provider. */
  features: z.optional(z.record(z.nativeEnum(LOYALTY_FEATURES), z.boolean())),
  /** The loyalty program type—determines how rewards are earned/redeemed. */
  program_type: z.nativeEnum(LOYALTY_PROGRAM_TYPES)
});
var locationsConfig = z.object({
  features: z.object({
    isSingleLocation: z.object({
      /** This field is used when isSingleLocation is enabled otherwise it is null */
      storeLocationId: z.number().nullable(),
      /** Enables the feature */
      enabled: z.boolean()
    })
  })
});
var guestProfileConfig = z.object({
  features: z.object({
    /**
     * This flag indicates if user should be auto-enrolled all into marketing emails.
     * This will maximize the opportunity for data collection for the restaurant operators.
     * If flag is true - we pass opt_in: true with order submission data
     */
    hasImplicitOptIn: z.boolean()
  })
});
var tokenConfig = z.object({
  external_auth: z.optional(externalAuthConfig.passthrough()),
  loyalty: z.optional(loyaltyConfig.passthrough()),
  ordering: orderingTokenConfig.passthrough(),
  organization: orgTokenConfig.passthrough(),
  payment: z.optional(paymentTokenConfig.passthrough()),
  locations: locationsConfig.passthrough(),
  guest_profile: guestProfileConfig.passthrough()
});

// src/v4/api/organization.service.ts
function getOrgConfig(_0) {
  return __async(this, arguments, function* ({ client }) {
    const uri = "/v1/config";
    const data = yield client.get(uri).json();
    const tokenConfigRes = safeParse({
      data,
      schema: z.object({ data: tokenConfig }),
      uri
    });
    return tokenConfigRes.data;
  });
}

// src/v4/api/configurations.service.ts
function getWebConfig(_0) {
  return __async(this, arguments, function* ({ client }) {
    var _a, _b;
    const uri = "/configurations/schema/v1/config/label/web-config";
    const orgConfig = yield getOrgConfig({ client });
    const res = yield client.headers({ "X-Organization-Id": (_b = (_a = orgConfig == null ? void 0 : orgConfig.organization) == null ? void 0 : _a.id) == null ? void 0 : _b.toString() }).get(uri).json();
    return res.data.data;
  });
}
function getStrings(_0) {
  return __async(this, arguments, function* ({ client }) {
    const uri = "/configurations/schema/v1/config/label/web-strings";
    const orgConfig = yield getOrgConfig({ client });
    const res = yield client.headers({ "X-Organization-Id": orgConfig.organization.id.toString() }).get(uri).json();
    return res.data.data;
  });
}
function getFooter(_0) {
  return __async(this, arguments, function* ({ client }) {
    const uri = "/configurations/schema/v1/config/label/web-footer";
    const orgConfig = yield getOrgConfig({ client });
    const res = yield client.headers({ "X-Organization-Id": orgConfig.organization.id.toString() }).get(uri).json();
    return res.data.data;
  });
}

// src/v4/api/communication.model.ts
var contactResponse = z.object({
  // add response values here
});

// src/v4/api/communication.service.ts
function contact(_0, _1) {
  return __async(this, arguments, function* (input, { client }) {
    const uri = "/v1/forms/contact";
    const data = yield client.post(input, uri).res();
    return safeParse({
      data,
      schema: contactResponse.passthrough(),
      uri
    });
  });
}

// src/v4/api/geoip.models.ts
var geoData = z.object({
  area: z.number(),
  city: z.string(),
  country: z.string(),
  eu: z.boolean(),
  ll: z.number().array(),
  metro: z.number(),
  region: z.string(),
  timezone: z.string()
});
var geoIp = z.object({
  requestIp: z.string(),
  geoData: z.nullable(geoData.passthrough())
});

// src/v4/api/geoip.service.ts
function geoIpLookup(_0) {
  return __async(this, arguments, function* ({ client }) {
    const uri = "/v1/geoip";
    const data = yield client.get(uri).json();
    return safeParse({ data, schema: geoIp, uri });
  });
}

// src/utils/locations.utils.ts
function standardizeAddressZipCode(address2) {
  if (typeof address2.zip_code === "string" && address2.zip_code !== "") {
    return __spreadProps(__spreadValues({}, address2), {
      zip: void 0
    });
  }
  if (typeof address2.zip === "string" && address2.zip !== "") {
    return __spreadProps(__spreadValues({}, address2), {
      zip: void 0,
      zip_code: address2.zip
    });
  }
  return address2;
}

// src/v4/api/locations.service.ts
function listLocations(_0, _1) {
  return __async(this, arguments, function* (query, { client }) {
    const uri = "/v1/ordering/store-locations";
    const data = yield client.query(stringify(query)).get(uri).json();
    return safeParse({
      data,
      schema: listSchema(location.passthrough()),
      uri
    });
  });
}
function getLocation(_0, _1) {
  return __async(this, arguments, function* ({ id, meta, ifModifiedSince }, { client }) {
    const uri = "/v1/ordering/store-locations";
    const data = yield client.query(stringify(meta)).headers(ifModifiedSince ? { "If-Modified-Since": ifModifiedSince } : {}).get(`${uri}/${id}`).json();
    return safeParse({
      data,
      schema: z.object({
        data: location.passthrough()
      }),
      uri
    });
  });
}
function getLocationByBrandId(_0, _1) {
  return __async(this, arguments, function* ({ brand_id, meta }, { client }) {
    const uri = "/v1/ordering/store-locations/brand";
    const data = yield client.query(stringify(meta)).get(`${uri}/${brand_id}`).json();
    return safeParse({
      data,
      schema: z.object({
        data: location.passthrough()
      }),
      uri
    });
  });
}
function getDeliveryCoverage(_0, _1) {
  return __async(this, arguments, function* (address2, { client }, shouldUseRadarMaps = false) {
    const baseUri = "/v1/ordering/store-locations/search-delivery-coverage";
    const uri = shouldUseRadarMaps ? `${baseUri}?radar=true` : baseUri;
    const data = yield client.post(standardizeAddressZipCode(address2), uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: location.passthrough()
      }),
      uri
    });
  });
}
function checkDeliveryCoverage(_0, _1, _2) {
  return __async(this, arguments, function* (locationId, address2, { client }) {
    const uri = "/v1/ordering/store-locations";
    const data = yield client.post(
      standardizeAddressZipCode(address2),
      `${uri}/${locationId}/check-delivery-coverage`
    ).json();
    return safeParse({
      data,
      schema: z.object({
        data: deliveryCoverageResponse.passthrough()
      }),
      uri
    });
  });
}

// src/v4/api/loyalty.service.ts
function createRedemption(_0, _1, _2) {
  return __async(this, arguments, function* (redeemableId, rewardType, { client }) {
    const uri = "/v1/ordering/loyalty/redemptions";
    const data = yield client.post({ [rewardType]: redeemableId }, uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: redemptionItem.passthrough()
      }),
      uri
    });
  });
}
function createRedemptionUsingBankCurrency(_0, _1) {
  return __async(this, arguments, function* (bankCurrency, { client }) {
    const uri = "/v1/ordering/loyalty/redemptions";
    const data = yield client.post({ banked_currency: bankCurrency }, uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: redemptionItem.passthrough()
      }),
      uri
    });
  });
}
function defaultStoredAddress(_0, _1) {
  return __async(this, arguments, function* (addressId, { client }) {
    const uri = `/v1/ordering/me/addresses/${addressId}`;
    const data = yield client.put({}, uri).res();
    return safeParse({
      data,
      schema: deliveryAddress.passthrough().array(),
      uri
    });
  });
}
function deleteStoredAddress(_0, _1) {
  return __async(this, arguments, function* (addressId, { client }) {
    const uri = `/v1/ordering/me/addresses/${addressId}`;
    const data = yield client.delete(uri).res();
    return safeParse({
      data,
      schema: deliveryAddress.passthrough().array(),
      uri
    });
  });
}
function fetchLoyaltyState(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    const uri = "/v1/ordering/loyalty/state";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: loyaltyState.passthrough()
      }),
      uri
    });
  });
}
function forgotPassword(_0, _1) {
  return __async(this, arguments, function* (email, { client }) {
    const uri = "/v1/ordering/loyalty/forgot-password";
    yield client.post({ email }, uri).res();
  });
}
function getRewards(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    const uri = "/v1/ordering/me/rewards";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: rewardItem.array()
      }),
      uri
    });
  });
}
function getStoredAddresses(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    const uri = "/v1/ordering/me/addresses";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: storedAddress.passthrough().array()
      }),
      uri
    });
  });
}
function getUser(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    const uri = "/v1/ordering/loyalty/me";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: loyaltyUser.passthrough()
      }),
      uri
    });
  });
}
function register(_0, _1) {
  return __async(this, arguments, function* (userDetails, { client }) {
    const uri = "/v1/ordering/loyalty/register";
    const data = yield client.post(userDetails, uri).json();
    return safeParse({
      data,
      schema: loyaltyUser.passthrough(),
      uri
    });
  });
}
function resetPassword(_0, _1) {
  return __async(this, arguments, function* (passwordResetInfo, { client }) {
    const uri = "/v1/ordering/loyalty/reset-password";
    yield client.post(passwordResetInfo, uri).res();
  });
}
function updateMe(_0, _1) {
  return __async(this, arguments, function* (userDetails, { client }) {
    const uri = "/v1/ordering/loyalty/me";
    const data = yield client.put(userDetails, uri).json();
    return safeParse({
      data,
      schema: loyaltyUser.passthrough(),
      uri
    });
  });
}
function fetchRedeemables(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    const uri = "/v1/ordering/loyalty/redeemables";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: redeemableItem.passthrough().array()
      }),
      uri
    });
  });
}
function fetchRedemptions(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    const uri = "/v1/ordering/loyalty/redemptions";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: redemptionItem.passthrough().array()
      }),
      uri
    });
  });
}
function fetchRewardsHistory(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    const uri = "/v1/ordering/loyalty/history";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: rewardHistoryItem.passthrough().array()
      }),
      uri
    });
  });
}
function fetchMessages(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    const uri = "/v1/ordering/loyalty/messages";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: messageItem.passthrough().array()
      }),
      uri
    });
  });
}
function markMessageAsRead(_0, _1) {
  return __async(this, arguments, function* (messageId, { client }) {
    const uri = `/v1/ordering/loyalty/messages/${messageId}`;
    yield client.put({}, uri).res();
  });
}
function deleteMessage(_0, _1) {
  return __async(this, arguments, function* (messageId, { client }) {
    const uri = `/v1/ordering/loyalty/messages/${messageId}`;
    yield client.delete(uri).res();
  });
}
function redeemReceiptBarcode(_0, _1) {
  return __async(this, arguments, function* (barcode, { client }) {
    const uri = "/v1/ordering/orders/claim-rewards";
    const data = yield client.post({ barcode }, uri).json();
    return safeParse({
      data,
      schema: loyaltyState.passthrough(),
      uri
    });
  });
}
function fetchSocialCauses(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    const uri = "/v1/ordering/loyalty/social-causes";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: socialCause.passthrough().array()
      }),
      uri
    });
  });
}
function donateToSocialCause(_0, _1, _2) {
  return __async(this, arguments, function* (id, donation2, { client }) {
    const uri = `/v1/ordering/loyalty/social-causes/${id}`;
    yield client.put(donation2, uri).json();
  });
}
function saveFavoriteLocation(_0, _1) {
  return __async(this, arguments, function* (locationId, { client }) {
    const uri = "/v1/ordering/me/favorites/store-locations";
    yield client.post({}, `${uri}/${locationId}`).res();
  });
}
function deleteFavoriteLocation(_0, _1) {
  return __async(this, arguments, function* (locationId, { client }) {
    const uri = "/v1/ordering/me/favorites/store-locations";
    yield client.delete(`${uri}/${locationId}`).res();
  });
}
function getFavoriteLocations(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    const uri = "/v1/ordering/me/favorites/store-locations";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: location.passthrough().array()
      }),
      uri
    });
  });
}

// src/utils/menu.helpers.ts
var import_merge = __toESM(require_merge());
import Fuse from "fuse.js";
function prettyPrice(product) {
  const optionsCost = calculateOptionsPrice(product.option_groups, 0);
  const prettyPrice2 = product.cost + optionsCost;
  return prettyPrice2 === 0 ? null : `$${(prettyPrice2 / 100).toFixed(2)}`;
}
function calculateOptionsPrice(option_groups, optionsPrice) {
  option_groups.map((group) => {
    if (group.min_selections && group.min_selections > 0) {
      optionsPrice += group.options.filter((option) => option.is_default).reduce((total, defaultOption) => {
        total += defaultOption.cost;
        if (defaultOption == null ? void 0 : defaultOption.option_groups.length) {
          total = calculateOptionsPrice(defaultOption.option_groups, total);
        }
        return total;
      }, 0);
    }
  });
  return optionsPrice;
}
function deriveProductWarningLabels(product) {
  var _a;
  let labels = {};
  if (((_a = product.labels) == null ? void 0 : _a.length) > 0) {
    product.labels.forEach((label) => {
      labels[label.name] = label;
    });
  }
  const recursivelyFilterLabels = (option_groups, labels2) => {
    option_groups.forEach((group) => {
      group.options.forEach((option) => {
        var _a2, _b;
        if (((_a2 = option.labels) == null ? void 0 : _a2.length) > 0) {
          option.labels.forEach((label) => {
            labels2[label.name] = label;
          });
        }
        if (((_b = option.option_groups) == null ? void 0 : _b.length) > 0) {
          recursivelyFilterLabels(option.option_groups, labels2);
        }
      });
    });
    return labels2;
  };
  labels = recursivelyFilterLabels(product.option_groups, labels);
  return Object.values(labels);
}
function deriveCalorieRange(product) {
  if (product.calories !== null && product.calories_separator !== null && product.max_calories !== null) {
    return `${product.calories}${product.calories_separator}${product.max_calories} ${CALS_IDENTIFIER}`;
  }
  return "";
}
function prettyCalories(product) {
  const calorieRange = deriveCalorieRange(product);
  if (calorieRange) {
    return calorieRange;
  }
  if (typeof product.calories === "number") {
    return `${product.calories} ${CALS_IDENTIFIER}`;
  }
  return "";
}
function isCustomizable(product) {
  var _a;
  return ((_a = product.option_groups) == null ? void 0 : _a.length) >= 1;
}
function augmentMenuProduct(product) {
  return __spreadProps(__spreadValues({}, product), {
    calorie_range: deriveCalorieRange(product),
    calsIdentifier: CALS_IDENTIFIER,
    customizable: isCustomizable(product),
    pretty_calories: prettyCalories(product),
    pretty_price: prettyPrice(product),
    warningLabels: deriveProductWarningLabels(product)
  });
}

// src/utils/service.helpers.ts
import { inflate } from "pako";
function decompressBinaryContent(compressedData) {
  try {
    const uncompressed = inflate(compressedData, { to: "string" });
    return JSON.parse(uncompressed);
  } catch (e) {
    console.error("Failed to decompress binary content.");
    throw e;
  }
}

// src/v4/api/menu.service.ts
function getMenu(_0, _1) {
  return __async(this, arguments, function* ({ id, ifModifiedSince, compressed = true, wantedAt }, { client }) {
    const base = "/v1/ordering/store-locations";
    const query = wantedAt ? `?wanted-at=${encodeURIComponent(wantedAt)}` : "";
    const uri = `${base}/${id}/menu${query}`;
    const res = client.headers(__spreadProps(__spreadValues({}, ifModifiedSince && { "If-Modified-Since": ifModifiedSince }), {
      Accept: compressed ? "application/zlib" : "application/json"
    })).get(uri);
    const data = compressed ? yield res.arrayBuffer() : yield res.json();
    const isCompressed = (_, isCompressed2) => isCompressed2;
    if (isCompressed(data, compressed)) {
      const menuByteArray = new Uint8Array(data);
      const decompressedMenu = decompressBinaryContent(menuByteArray);
      const parsedMenu2 = safeParse({
        data: decompressedMenu.data,
        schema: menuResponse.passthrough(),
        uri
      });
      const computedCategories2 = parsedMenu2.categories.map((category2) => __spreadProps(__spreadValues({}, category2), {
        products: category2.products.map((product) => augmentMenuProduct(product))
      }));
      return [computedCategories2, Array.from(menuByteArray)];
    }
    const parsedMenu = safeParse({
      data,
      schema: z.object({
        data: menuResponse.passthrough()
      }),
      uri: base
    });
    const computedCategories = parsedMenu.data.categories.map((category2) => __spreadProps(__spreadValues({}, category2), {
      products: category2.products.map((product) => augmentMenuProduct(product))
    }));
    return [computedCategories, []];
  });
}

// src/v4/api/ordering-profile.service.ts
function pastOrders(_0) {
  return __async(this, arguments, function* ({ client }) {
    const uri = "/v1/ordering/me/orders?include[]=store_location&sort[created_at]=desc";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: order.passthrough().array()
      }),
      uri
    });
  });
}
function favoriteOrders(_0) {
  return __async(this, arguments, function* ({ client }) {
    const uri = "/v1/ordering/me/favorites/orders?include[]=store_location&sort[created_at]=desc";
    const data = yield client.get(uri).json();
    return safeParse({
      data,
      schema: z.object({
        data: order.passthrough().array()
      }),
      uri
    });
  });
}
function favoritesSave(_0, _1) {
  return __async(this, arguments, function* (orderId, { client }) {
    const uri = "/v1/ordering/me/favorites/orders";
    yield client.post({}, `${uri}/${orderId}`).res();
  });
}
function favoritesDelete(_0, _1) {
  return __async(this, arguments, function* (orderId, { client }) {
    const uri = "/v1/ordering/me/favorites/orders";
    yield client.delete(`${uri}/${orderId}`).res();
  });
}
function getOrderDetails(_0, _1) {
  return __async(this, arguments, function* (order_id, { client }) {
    const uri = "/v1/ordering/orders";
    const data = yield client.get(`${uri}/${order_id}?include[]=store_location`).json();
    return {
      data: safeParse({
        data,
        schema: orderDetails.passthrough().array(),
        uri
      })
    };
  });
}

// src/v4/api/recommendations.models.ts
var UpsellsStep = /* @__PURE__ */ ((UpsellsStep2) => {
  UpsellsStep2["add"] = "add_product";
  UpsellsStep2["checkout"] = "checkout";
  return UpsellsStep2;
})(UpsellsStep || {});
var crossSellsOffer = z.object({
  /** The type of offer. `addon` or `replace`. */
  type: z.union([z.literal("addon"), z.literal("replace")]),
  /** ID of the BasketItem that will be replaced if the offer is `replace`. */
  target: z.nullable(z.string()),
  /** Recommended menu items. */
  products: menuProduct.passthrough().array()
});
var crossSells = z.object({
  data: z.object({
    /** ID for the set of upsells presented. */
    id: z.string(),
    /** Origin of the cross-sells list. Most likely `recommendations`. */
    upsells_rule: z.string().optional(),
    /** List of product recommendations. */
    upsells: crossSellsOffer.passthrough().array()
  })
});
var popularProductsOffer = z.object({
  /** The type of offer (popular products always return `popular`) */
  type: z.literal("popular"),
  /** Popular product score, we can use this to sort the items */
  score: z.number(),
  /** Recommended menu items. */
  products: menuProductResponse.passthrough().array()
});
var popularProducts = z.object({
  /** ID for the set of popular products presented. */
  id: z.string(),
  /** Popular menu items. */
  products: popularProductsOffer.passthrough().array()
});

// src/v4/api/recommendations.service.ts
function getCrossSells(_0, _1) {
  return __async(this, arguments, function* (options, { client }) {
    const uri = "/v1/ordering/upsells";
    const query = options.wanted_at ? `?wanted-at=${encodeURIComponent(options.wanted_at)}` : "";
    const data = yield client.post(options, `${uri}${query}`).json();
    return safeParse({
      data,
      schema: crossSells.passthrough(),
      uri
    });
  });
}
function getPopularProducts(_0, _1) {
  return __async(this, arguments, function* (options, { client }) {
    var _a;
    const uri = "/v1/ordering/recommendations/products/popular";
    const query = options.wantedAt ? `?wanted-at=${encodeURIComponent(options.wantedAt)}` : "";
    const res = yield client.post(
      {
        store_location_id: options.locationId,
        max: (_a = options == null ? void 0 : options.maxItems) != null ? _a : 5
      },
      `${uri}${query}`
    ).json();
    const parsedRes = safeParse({
      data: res,
      schema: z.object({ data: popularProducts.passthrough() }),
      uri
    });
    return parsedRes.data;
  });
}

// src/v4/core/client.ts
import wretch2 from "wretch";
import QueryStringAddon from "wretch/addons/queryString";

// src/v4/core/auth.ts
import wretch from "wretch";
function tokenName(origin) {
  return encodeURIComponent(`koala-token-${origin}`);
}
function getPersistedToken({ origin }) {
  if (typeof window !== "undefined") {
    const tokenString = window.localStorage.getItem(tokenName(origin));
    if (tokenString) {
      try {
        const persistedToken = token.parse(JSON.parse(tokenString));
        return persistedToken;
      } catch (e) {
        console.error("Failed to parse persisted token", e);
      }
    }
  }
  return null;
}
function resetToken(_0) {
  return __async(this, arguments, function* ({
    credentials,
    env,
    origin,
    headers = {}
  }) {
    const token2 = yield fetchClientToken({ credentials, env, origin, headers });
    persistToken({ origin, token: token2 });
  });
}
function persistToken({ origin, token: token2 }) {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(tokenName(origin), JSON.stringify(token2));
  }
}
function fetchClientToken(_0) {
  return __async(this, arguments, function* ({
    credentials,
    env,
    origin,
    headers = {}
  }) {
    return wretch(`${buildOrderingApiUrl(env)}/oauth/access_token`).headers(__spreadProps(__spreadValues({}, headers), { "x-request-origin": origin })).post({
      client_id: credentials.clientId,
      client_secret: credentials.clientSecret,
      grant_type: "ordering_app_credentials",
      scope: "group:ordering_app"
    }).json();
  });
}
function fetchLoyaltyToken(_0) {
  return __async(this, arguments, function* ({
    credentials,
    env,
    origin,
    payload,
    headers = {}
  }) {
    return wretch(`${buildOrderingApiUrl(env)}/oauth/access_token`).headers(__spreadProps(__spreadValues({}, headers), { "x-request-origin": origin })).post({
      client_id: credentials.clientId,
      client_secret: credentials.clientSecret,
      grant_type: "external_provider",
      scope: "group:external_provider",
      payload
    }).json();
  });
}
function refreshLoyaltyToken({
  credentials,
  env,
  origin,
  refreshToken
}) {
  return wretch(`${buildOrderingApiUrl(env)}/oauth/access_token`).headers({ "x-request-origin": origin }).post({
    client_id: credentials.clientId,
    client_secret: credentials.clientSecret,
    grant_type: "refresh_token",
    scope: "group:external_provider",
    refresh_token: refreshToken
  });
}
function validateLoyaltyToken(origin) {
  const currentToken = getPersistedToken({ origin });
  if (currentToken && currentToken.refresh_token) {
    return true;
  }
  return false;
}
function logout(_0) {
  return __async(this, arguments, function* ({
    client
  }) {
    return yield client.post({}, "/oauth/logout").json();
  });
}

// src/v4/core/client.ts
function httpClient({
  credentials,
  env,
  origin,
  headers = {}
}) {
  return wretch2(buildOrderingApiUrl(env)).defer((w) => {
    const token2 = getPersistedToken({ origin });
    return token2 ? w.auth(`Bearer ${token2.access_token}`) : w;
  }).headers(__spreadProps(__spreadValues({}, headers), { "X-Channel-Id": "web" })).catcher(401, (_, req) => __async(this, null, function* () {
    try {
      let token2 = getPersistedToken({ origin });
      if (token2 == null ? void 0 : token2.refresh_token) {
        if (typeof window !== "undefined" && !getLock(origin)) {
          setLock(origin);
          yield refreshLoyaltyToken({
            credentials,
            env,
            origin,
            refreshToken: token2.refresh_token
          }).unauthorized((err) => __async(this, null, function* () {
            token2 = yield fetchClientToken({
              credentials,
              env,
              origin
            });
            persistToken({ origin, token: token2 });
            throw err;
          })).json((json) => {
            token2 = json;
            persistToken({ origin, token: token2 });
          }).finally(
            () => (
              // finally, remove the lock in any case
              removeLock(origin)
            )
          );
        }
      } else {
        token2 = yield fetchClientToken({ credentials, env, origin });
        persistToken({ origin, token: token2 });
      }
      yield new Promise((resolve) => {
        if (typeof window !== "undefined" && getLock(origin)) {
          const interval = setInterval(function() {
            const lock = getLock(origin);
            if (lockIsStale(origin)) {
              removeLock(origin);
              window.location.reload();
            }
            if (!lock) {
              resolve();
              clearInterval(interval);
            }
          }, 50);
        } else {
          resolve();
        }
      });
      return replayRequest(req, token2);
    } catch (err) {
      return req.json({ message: err.message });
    }
  })).addon(QueryStringAddon);
}
export {
  ARRIVAL_STATUS,
  CALS_IDENTIFIER,
  CONVEYANCE_TYPES,
  CONVEYANCE_TYPE_KEYS,
  DELIVERY_PROVIDER_STATUS,
  DELIVER_PROVIDER_TERMINATING_STATUS,
  LOYALTY_FEATURES,
  LOYALTY_PROGRAM_TYPES,
  UpsellsStep,
  addBasketItem,
  addPromo,
  addRewards,
  addTip,
  allergenItem,
  arrivalResponse,
  availableWantedAtTimes,
  basket,
  basketConveyanceDetails,
  basketFee,
  basketItem,
  basketItemOption,
  basketOrder,
  category,
  categoryResponse,
  checkDeliveryCoverage,
  checkoutBasket,
  checkoutBasketConveyanceDetails,
  checkoutBasketDeliveryAddress,
  contact,
  contactResponse,
  createBasket,
  createBasketFromOrderId,
  createRedemption,
  createRedemptionUsingBankCurrency,
  creditCardBase2 as creditCardBase,
  crossSells,
  curbsideDetails,
  customerDetails2 as customerDetails,
  customerDetailsV2,
  defaultStoredAddress,
  deleteFavoriteLocation,
  deleteMessage,
  deleteStoredAddress,
  deliveryAddress,
  deliveryCoverageResponse,
  deliveryStatus,
  digitalWallet2 as digitalWallet,
  dineInDetails,
  donateToSocialCause,
  donation,
  donationTypes,
  driveInDetails,
  existingOption,
  externalAuthConfig,
  favoriteOrders,
  favoritesDelete,
  favoritesSave,
  fetchClientToken,
  fetchLoyaltyState,
  fetchLoyaltyToken,
  fetchMessages,
  fetchRedeemables,
  fetchRedemptions,
  fetchRewardsHistory,
  fetchSocialCauses,
  forgotPassword,
  geoData,
  geoIp,
  geoIpLookup,
  getAllergens,
  getAppliedRewards,
  getBasketRewards,
  getCrossSells,
  getDeliveryCoverage,
  getFavoriteLocations,
  getFooter,
  getLocation,
  getLocationByBrandId,
  getMenu,
  getMostRecentCard,
  getOrderDeliveryStatus,
  getOrderDetails,
  getOrderSummary,
  getOrgConfig,
  getPaymentTypes,
  getPersistedToken,
  getPickupTime,
  getPickupTimeByBasket,
  getPopularProducts,
  getRewards,
  getSavedCards,
  getStoredAddresses,
  getStoredValue,
  getStrings,
  getSupportedPaymentTypes,
  getTags,
  getTokenizedCreditCard,
  getUser,
  getWebConfig,
  httpClient,
  listLocations,
  location,
  locationCachedData,
  locationOperatingHours,
  logout,
  loyaltyState,
  loyaltyUser,
  markMessageAsRead,
  menu,
  menuProduct,
  menuProductImages,
  menuProductLabel,
  menuProductOption,
  menuProductOptionGroup,
  menuProductResponse,
  menuResponse,
  messageItem,
  order,
  orderDetails,
  orderingTokenConfig,
  orgTokenConfig,
  pastOrders,
  paymentDetails2 as paymentDetails,
  paymentTokenConfig,
  paymentTypesResponse,
  persistToken,
  popularProducts,
  redeemReceiptBarcode,
  redeemableItem,
  redemptionItem,
  register,
  removeBasketItem,
  removePickupTime,
  removePromo,
  removeReward,
  removeSavedCard,
  resetPassword,
  resetToken,
  rewardHistoryItem,
  rewardItem,
  saveFavoriteLocation,
  savedCard,
  setConveyance,
  setInstructions,
  setPickupTime,
  setUtensils,
  socialCause,
  storedAddress,
  storedValueBalanceResponse,
  storedValueRequirement2 as storedValueRequirement,
  submissionDetails,
  submitBasketData,
  submitOrder,
  supportedPaymentTypes,
  tagItem,
  tokenConfig,
  tokenizedCreditCard2 as tokenizedCreditCard,
  updateArrivalStatus,
  updateBasketItem,
  updateMe,
  validateBasket,
  validateLoyaltyToken
};

import { ROUTES } from "@/constants/routes";

export const isAndroidShell = () =>
  typeof window !== "undefined" && window.KoalaAndroidShell ? true : false;

export const getAndroidShellPushNotificationToken = () =>
  isAndroidShell()
    ? { fcm_token: window.KoalaAndroidShell.getPushNotificationsToken() }
    : {};

export const requestAndroidShellRatingsModal = () => {
  if (isAndroidShell()) {
    window.KoalaAndroidShell.showRatingsModal();
  }
};

export const getAndroidShellVersion = () =>
  isAndroidShell() ? `v${window.KoalaAndroidShell.getVersionName()}` : "v1.0.0";

export const getAndroidShellDeviceId = (): string | null => {
  if (!isAndroidShell()) return null;

  // Try to call `getKoalaDeviceId` first
  if (typeof window.KoalaAndroidShell.getKoalaDeviceId === "function") {
    try {
      return window.KoalaAndroidShell.getKoalaDeviceId();
    } catch (e) {
      console.error("Error calling getKoalaDeviceId:", e);
    }
  }

  // Fallback to `getDeviceId` if `getKoalaDeviceId` is not available
  if (typeof window.KoalaAndroidShell.getDeviceId === "function") {
    try {
      return String(window.KoalaAndroidShell.getDeviceId());
    } catch (e) {
      console.error("Error calling getDeviceId:", e);
    }
  }

  // Fallback to a random string if neither method works
  console.warn("Falling back to a random device ID.");
  return Math.random().toString(36);
};

export const setAndroidCurrentPage = (
  pathname: string,
  back: () => void,
  push: (path: string) => void
) => {
  if (
    [ROUTES.ORDER_CONFIRMATION, ROUTES.LOGIN, ROUTES.HOMEPAGE].includes(
      pathname
    )
  ) {
    push(ROUTES.ANDROID);
  } else {
    back();
  }
};

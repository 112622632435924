// Extend window interface
declare global {
  interface Window {
    dataLayer: any;
    KoalaAnalytics: any;
    // Android global exists when the web app is a web-view inside our Android Kotlin shell
    // see: https://docs.google.com/document/d/1qaIWkqI_-SRatrE7UDBloi5ip6VhUKrzrMAAsQRAuh0/edit#heading=h.ekyfwiyw5mwq
    KoalaAndroidShell: {
      getClientId: () => string;
      getClientSecret: () => string;
      getPushNotificationsToken: () => string;
      getDeviceId: () => string;
      getKoalaDeviceId: () => string;
      showRatingsModal: () => void;
      getVersionName: () => string;
      getGeolocation: () => void;
      canGoBack: (value: boolean) => void;
    };
    toggleFulfillmentModal: any;
    SqPaymentForm: any;
    /** @deprecated Universal Analytics will be deprecated in favor of Google Analytics 4 */
    ga: any;
    gtag?: Gtag.Gtag;
    ka: any;
    mapboxgl: any;
    tomtom: any;
    gm_authFailure: any;
    SecureFields: any;
    branch: any;
    fbq: any;
    ReactNativeWebView: any;
    AppleID: any;
  }

  interface Document {
    documentMode?: any;
  }
}

export enum AuthProviderLoyaltyIDs {
  PUNCHH = 2,
  PAYTRONIX = 3,
  SPENDGO = 4,
  OLO = 5,
}

export interface IAppleResponseJWT {
  aud: string;
  auth_time: number;
  c_hash: string;
  email: string;
  email_verified: string;
  exp: number;
  iat: number;
  is_private_email: string;
  iss: string;
  nonce_supported: boolean;
  sub: string;
}

// source: https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
export interface IAppleSignInAuthorizationResponse {
  authorization: {
    state: string;
    code: string;
    id_token: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}
